import { $createParagraphNode, $getSelection, $isRangeSelection, LexicalEditor } from "lexical";
import { $setBlocksType } from "@lexical/selection";
import { $createCodeNode } from "@lexical/code";
import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from "@lexical/list";
import { $createHeadingNode, $createQuoteNode, HeadingTagType } from "@lexical/rich-text";

export class LexicalFormattingUtilities {
  public static formatParagraph(editor: LexicalEditor) {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  }

  public static formatHeading(
    editor: LexicalEditor,
    blockType: string,
    headingSize: HeadingTagType,
  ) {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      });
    }
  }

  public static formatBulletList(editor: LexicalEditor, blockType: string) {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      LexicalFormattingUtilities.formatParagraph(editor);
    }
  }

  public static formatCheckList(editor: LexicalEditor, blockType: string) {
    if (blockType !== "check") {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      LexicalFormattingUtilities.formatParagraph(editor);
    }
  }

  public static formatNumberedList(editor: LexicalEditor, blockType: string) {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      LexicalFormattingUtilities.formatParagraph(editor);
    }
  }

  public static formatQuote(editor: LexicalEditor, blockType: string) {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createQuoteNode());
      });
    }
  }

  public static formatCode(editor: LexicalEditor, blockType: string) {
    if (blockType !== "code") {
      editor.update(() => {
        let selection = $getSelection();

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection = $getSelection();
            if ($isRangeSelection(selection)) {
              selection.insertRawText(textContent);
            }
          }
        }
      });
    }
  }
}
