import { Button, styled } from "@mui/material";

export const ConfirmButton = styled(Button)({});
ConfirmButton.defaultProps = { ...ConfirmButton.defaultProps, variant: "contained" };

export const SaveButton = ConfirmButton;

export const CancelButton = styled(Button)({});

export const ClearButton = styled(Button)(({ theme }) => ({
  color: "rgba(0, 0, 0, 0.5)",
}));
ClearButton.defaultProps = { ...ClearButton.defaultProps, variant: "text" };
