import { FC, ReactNode } from "react";
import { useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT } from "../../modules/common/components/app-shell-wrapper.component";

interface IOrganizationStructurePageWrapperHelperProps {
  buildPage: (structure: IDataEntryObject) => ReactNode;
}

export const OrganizationStructurePageWrapperHelper: FC<
  IOrganizationStructurePageWrapperHelperProps
> = ({ buildPage }) => {
  const { organizationId, recordingPeriodId } = useParams();

  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  return (
    <QueryWrapper
      query={organizationStructureQuery}
      loadingOverride={() => (
        <CenteredCircularProgress minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT} />
      )}
      build={(organizationStructure) => (
        <OrganizationStructureDraftWrapper
          organizationId={organizationId!}
          recordingPeriodId={recordingPeriodId!}
          organizationStructure={organizationStructure}
          build={buildPage}
        />
      )}
    />
  );
};
