import { Box, Drawer, Link, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  ISideBarNavigationGroupV2,
  NavigationItemsSideBarSectionV2,
} from "../side-bar-sections/navigation-items-side-bar-section-v2.component";
import { NetCeroLogoIconColor, NetCeroLogoTextColor } from "./netcero-logo.component";
import { NavItemUrlUtilities } from "../utilities/nav-item-url.utilities";

export const SIDE_BAR_SHRUNK_WIDTH_V2 = 64;
export const SIDE_BAR_EXPANDED_WIDTH_V2 = 235;
export const SIDE_BAR_CORNER_RADIUS_V2 = 16;
/** The duration the open/close transition takes in ms */
export const SIDE_BAR_TRANSITION_DURATION_V2 = 200;
export const SIDE_BAR_TRANSITION_DELAY = 250;

// TODO: NC-XYZ Clean up - remove organizationSelect from SideBarConfiguration
/**
 * Configuration for the Side Bar.
 * organizationSelect: Configuration for the Organization Select Side Bar Section. If organizationId is undefined, the Organization Select Side Bar Section will not be rendered.
 */
export interface ISideBarConfiguration {
  organizationSelect?: {
    organizationId: string;
    onChange: (organizationId: string) => void;
  };
  navigationItems?: {
    activePath: string;
    items: ISideBarNavigationGroupV2[];
  };
}

export interface ISideBarProps {
  open: boolean;
  configuration?: ISideBarConfiguration;
  onOpen: () => void;
  onClose: () => void;
}

export const SideBarV2: FC<ISideBarProps> = ({ open, configuration, onOpen, onClose }) => {
  const [subItemsMenuEnabled, setSubItemsMenuEnabled] = useState(false);
  const handleOnEnterSidebar = () => {
    // Wait for animation to finish
    setTimeout(
      () => setSubItemsMenuEnabled(true),
      SIDE_BAR_TRANSITION_DELAY + SIDE_BAR_TRANSITION_DURATION_V2,
    );
  };
  const handleOnExitSidebar = () => {
    setSubItemsMenuEnabled(false);
  };

  const logoLink = useMemo(() => {
    const organizationId = configuration?.organizationSelect?.organizationId ?? null;
    if (!organizationId) {
      return "/organizations";
    }

    const recordingPeriodId = configuration?.navigationItems?.activePath
      .match(/(?<=recording-periods\/)[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
      ?.shift();
    if (!recordingPeriodId) {
      return `/organizations/${organizationId}`;
    }

    return NavItemUrlUtilities.getRecordingPeriodDashboardPageUrl(
      organizationId,
      recordingPeriodId,
    );
  }, [
    configuration?.navigationItems?.activePath,
    configuration?.organizationSelect?.organizationId,
  ]);

  return (
    <Drawer
      variant="permanent"
      onMouseEnter={!open ? onOpen : undefined}
      onMouseLeave={open ? onClose : undefined}
      PaperProps={{
        sx: {
          overflowX: "unset",
          overflowY: "unset",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: SIDE_BAR_CORNER_RADIUS_V2,
          borderBottomRightRadius: SIDE_BAR_CORNER_RADIUS_V2,
          border: "none",
          p: 0,
          backgroundColor: (theme) => theme.palette.common.white,
        },
      }}
    >
      <Box
        width={`${
          (open ? SIDE_BAR_EXPANDED_WIDTH_V2 : SIDE_BAR_SHRUNK_WIDTH_V2) + SIDE_BAR_CORNER_RADIUS_V2
        }px`}
        flex={1}
        display="flex"
        flexDirection="column"
        sx={{
          transition: `width ${SIDE_BAR_TRANSITION_DURATION_V2}ms`,
          transitionDelay: `${SIDE_BAR_TRANSITION_DELAY}ms`,
          transitionTimingFunction: "ease-in-out",
        }}
        onMouseEnter={handleOnEnterSidebar}
        onMouseLeave={handleOnExitSidebar}
      >
        {/* Main Sidebar Content */}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          px={2}
          py={1}
          sx={{
            transition: `padding ${SIDE_BAR_TRANSITION_DURATION_V2}ms`,
            transitionDelay: `${SIDE_BAR_TRANSITION_DELAY}ms`,
            transitionTimingFunction: "ease-in-out",
          }}
        >
          <Link
            component={RouterLink}
            to={logoLink}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 64,
              gap: open ? 1 : 0,
              transition: `gap ${SIDE_BAR_TRANSITION_DURATION_V2}ms`,
              transitionDelay: `${SIDE_BAR_TRANSITION_DELAY}ms`,
            }}
          >
            <NetCeroLogoIconColor />
            <Box
              display="flex"
              alignItems="center"
              width={open ? "112px" : "0px"}
              overflow="hidden"
              sx={{
                opacity: open ? 1 : 0,
                transition:
                  `width ${SIDE_BAR_TRANSITION_DURATION_V2}ms, ` +
                  `opacity ${SIDE_BAR_TRANSITION_DURATION_V2}ms`,
                transitionDelay: `${SIDE_BAR_TRANSITION_DELAY}ms`,
                transitionTimingFunction: "ease-in-out",
              }}
            >
              <NetCeroLogoTextColor />
            </Box>
          </Link>

          {/* Side Bar Content */}

          {/* Navigation Items */}
          {configuration?.navigationItems?.items &&
            configuration.navigationItems.items.length > 0 && (
              <Box my={2} px={0} sx={{ overflowY: "auto", overflowX: "hidden" }}>
                <NavigationItemsSideBarSectionV2
                  activePath={configuration.navigationItems.activePath}
                  groups={configuration.navigationItems.items}
                  expanded={open}
                  subItemsMenuEnabled={subItemsMenuEnabled}
                  setSubItemsMenuEnabled={setSubItemsMenuEnabled}
                />
              </Box>
            )}
        </Box>
        {/* Branding Text */}
        <Box display="flex" justifyContent="center" sx={{ pb: 2 }}>
          <Typography
            variant="caption"
            textAlign="center"
            whiteSpace="nowrap"
            overflow="hidden"
            sx={{
              color: (theme) => theme.palette.common.black,
              opacity: open ? 0.54 : 0,
              transition: `width ${SIDE_BAR_TRANSITION_DURATION_V2}ms, opacity ${SIDE_BAR_TRANSITION_DURATION_V2}ms`,
              transitionDelay: `${SIDE_BAR_TRANSITION_DELAY}ms`,
              transitionTimingFunction: "ease-in-out",
            }}
          >
            © {new Date().getFullYear()} NetCero GmbH
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};
