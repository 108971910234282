import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import {
  IBaseDMAMaterialImpactData,
  IDataEntryObject,
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IDMAConfiguration,
  IDMAMaterialImpact,
  IDMAMaterialImpactHorizonData,
  IESRSTopic,
  IHorizonsEnum,
  IIROState,
} from "@netcero/netcero-core-api-client";
import {
  DmaConfigurationUtilities,
  MaterialImpactCalculator,
  MaterialImpactHorizonsCalculator,
} from "@netcero/netcero-dma";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormatTranslation } from "../../common/components/format-translation.component";
import {
  ArrowRightIcon,
  CopyIcon,
  DeleteIcon,
  IconSize,
} from "../../common/constants/tabler-icon.constants";
import { useUserContext } from "../../user/user.context";
import { HORIZONS_VALUES, ORDERED_HORIZONS_VALUES } from "../common/dma-horizons.constants";
import {
  IInheritFromHorizonsState,
  IInheritFromHorizonsStateItem,
} from "../common/dma-horizons.interfaces";
import {
  DEFAULT_DMA_PROBABILITY_SLIDER_VALUE,
  DEFAULT_DMA_SLIDER_VALUE,
} from "../common/dma.constants";
import { EditDialogHorizonTabContent } from "../common/edit-dialog-horizon-tab-content.component";
import { IROStateIndicator } from "../common/iro-state-indicator.component";
import { IROStateSelect } from "../common/iro-state-select.component";
import { useIroEditIsDirtyHook } from "../common/use-iro-edit-is-dirty.hook";
import { IROAreasCheckboxesComponent } from "../common/iro-areas-checkboxes.component";
import { DMAMaterialImpactHorizonEditSection } from "./dma-material-impact-horizon-edit-section.component";
import { DMABreadcrumbsComponent } from "../common/dma-breadcrumbs.component";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";
import {
  IInternalMaterialImpactData,
  IInternalMaterialImpactHorizonData,
} from "./internal-material-impact-data.types";
import { IroCommonInputs } from "../common/iro-common-inputs.component";
import { IntercomReferenceWidget } from "../../intercom-references/intercom-reference.widget";
import { EditDialogCommentsAndChangelogPaper } from "../../comments-and-audit-log/components/edit-dialog-comments-and-audit-log-paper.component";
import { useEditDialogCommentsAndAuditLogPaperState } from "../../comments-and-audit-log/hooks/edit-dialog-comments-and-audit-log-paper-state.hook";
import { EditDialogExpandCommentsAndAuditLogPaperButton } from "../../comments-and-audit-log/components/edit-dialog-expand-comments-and-audit-log-paper-button.component";
import { InfoDialogText } from "../../common/dialogs/variants/info.dialog";
import { DotMenu } from "../../common/components/dot-menu.component";
import { IroTypeIndicator } from "../common/iro-type-indicator.component";
import { useIroEditDialogMainDmaCategory } from "../hooks/iro-edit-dialog-main-dma-category.hook";
import { DmaFormatUtilities } from "../utilities/dma-format.utilities";
import { useCreateMaterialImpactMutation } from "../mutations/dma.mutations";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";

const getDefaultValuesForHorizon = (
  horizonData?: IDMAMaterialImpactHorizonData | null,
  defaultInheritFromHorizon: IHorizonsEnum | null = null,
): IInternalMaterialImpactHorizonData => {
  return {
    inheritsFromHorizon: horizonData?.inheritsFromHorizon ?? defaultInheritFromHorizon,
    extent: horizonData?.extent ?? DEFAULT_DMA_SLIDER_VALUE, // Ausmaß der Auswirkung
    scope: horizonData?.scope ?? DEFAULT_DMA_SLIDER_VALUE, // Umfang der Auswirkung
    // Special/Internal Properties
    type: !horizonData ? "actual" : horizonData.probability ? "potential" : "actual",
    probability: horizonData?.probability ?? DEFAULT_DMA_PROBABILITY_SLIDER_VALUE,
    result: !horizonData ? "negative" : horizonData.irreversibility ? "negative" : "positive",
    irreversibility: horizonData?.irreversibility ?? DEFAULT_DMA_SLIDER_VALUE,
    hasNegativeEffectOnHumanRights: horizonData?.hasNegativeEffectOnHumanRights ?? false,
    // Values for override of automatic materiality
    overrideMateriality: horizonData?.userMaterialityReason !== undefined,
    materialityUser: horizonData?.materialityUser ?? false,
    userMaterialityReason: horizonData?.userMaterialityReason ?? "",
  };
};

const getDefaultValuesForInputs = (
  materialImpact?: IDMAMaterialImpact | null,
): IInternalMaterialImpactData => {
  const defaultInheritFromHorizon = !materialImpact ? IHorizonsEnum.ShortTerm : null;

  return {
    title: materialImpact?.title ?? "",
    responsibleUserId: materialImpact?.responsibleUserId ?? null,
    description: materialImpact?.description ?? "",
    assessmentExplanation: materialImpact?.assessmentExplanation ?? "",
    horizonsComment: materialImpact?.horizonsComment ?? "",
    areas: materialImpact?.areas ?? [],
    associatedDataEntryObjects: materialImpact?.associatedDataEntryObjects ?? [],
    sourceIds: materialImpact?.sourceIds ?? [],
    horizons: {
      shortTerm: getDefaultValuesForHorizon(materialImpact?.horizons?.shortTerm, null),
      mediumTerm: getDefaultValuesForHorizon(
        materialImpact?.horizons?.mediumTerm,
        defaultInheritFromHorizon,
      ),
      longTerm: getDefaultValuesForHorizon(
        materialImpact?.horizons?.longTerm,
        defaultInheritFromHorizon,
      ),
    },
    contributingUserIds: materialImpact?.contributingUserIds ?? [],
  };
};

function convertFormHorizonToAPIPayload(
  formData: IInternalMaterialImpactHorizonData,
): IDMAMaterialImpactHorizonData {
  return {
    extent: formData.extent,
    scope: formData.scope,
    // Properties only set when type is potential
    probability: formData.type === "potential" ? formData.probability : undefined,
    // Properties only set when result is negative
    irreversibility: formData.result === "negative" ? formData.irreversibility : undefined,
    materialityUser: formData.overrideMateriality ? formData.materialityUser : undefined,
    hasNegativeEffectOnHumanRights:
      formData.result === "negative" ? formData.hasNegativeEffectOnHumanRights : undefined,
    // User Override Materiality
    userMaterialityReason: formData.overrideMateriality
      ? formData.userMaterialityReason || undefined
      : undefined,
  };
}

function createFormHorizonAPIPayload(
  horizons: IInternalMaterialImpactData["horizons"],
  horizon: IHorizonsEnum,
): IDMAMaterialImpactHorizonData {
  const inheritsFrom = horizons[horizon].inheritsFromHorizon ?? undefined;
  return {
    ...convertFormHorizonToAPIPayload(horizons[inheritsFrom ?? horizon]),
    inheritsFromHorizon: inheritsFrom,
  };
}

interface IDMAMaterialImpactEditDialogProps {
  open: boolean;
  organizationId: string;
  recordingPeriodId: string;
  materialImpact?: IDMAMaterialImpact | null;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
  dmaConfiguration: IDMAConfiguration;
  dataEntryObject: IDataEntryObject;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IBaseDMAMaterialImpactData | null) => void;
  onUpdateIROState?: (newState: IIROState) => void;
  onDelete?: () => void;
  onMoveIRO?: () => void;
  /** This can be null or undefined when the dialog is being used in readonly */
  esrsTopic: IESRSTopic | null;
  readOnly?: boolean;
}

/**
 * Dialog to create or edit a material impact
 * @param open Whether the dialog is open or not
 * @param organizationId The organization id to use for the responsible user picker
 * @param recordingPeriodId The recording period id to use for the responsible user picker
 * @param materialImpact The material impact to edit. If null or undefined, the dialog will be in create mode
 * @param dmaCategory The DMA category the material impact belongs to
 * @param dmaConfiguration The DMA configuration to use for the materiality calculation
 * @param dataEntryObject The data entry object the dma is being executed for
 * @param loading Whether to display a loading indicator or not
 * @param error Error to display
 * @param disabled Whether the dialog is disabled or not (all inputs and buttons will be disabled)
 * @param onClose Callback to be called when the dialog is closed. The callback will receive the data of the financial effect created or edited, or null if the dialog was cancelled
 * @param onUpdateIROState Callback to be called when the IRO state is updated. If not provided, the IRO state will not be displayed
 * @param onDelete Callback for click event of delete button. If not provided, the delete button will not be displayed
 * @param esrsTopic The ESRSTopic to use for the topic filter
 * @param readOnly Whether the dialog is read only or not
 */
export const DMAMaterialImpactEditDialog: FC<IDMAMaterialImpactEditDialogProps> = ({
  open,
  organizationId,
  recordingPeriodId,
  materialImpact,
  dmaCategory,
  dmaConfiguration,
  dataEntryObject,
  loading,
  error,
  disabled: parentDisabled,
  onClose,
  onUpdateIROState,
  onDelete,
  onMoveIRO,
  esrsTopic,
  readOnly,
}) => {
  const internalDisabled =
    readOnly ||
    parentDisabled ||
    materialImpact?.state === IIROState.Shared ||
    // Should already be disabled due to effectState (always Shared) but just to be sure
    (dmaCategory !== null && dmaCategory.materialState === IDMACategoryState.Verified);

  const iroStateReadOnly =
    dmaCategory !== null && dmaCategory.materialState === IDMACategoryState.Verified;

  const { t } = useTranslation("dma_material_impact_edit_dialog");
  const { wrapApiPromise } = useAppSnackbar();

  // Queries
  const duplicateMaterialImpactMutation = useCreateMaterialImpactMutation();

  // Dialog States
  const [showIncompleteForSharedNoticeDialog, setShowIncompleteForSharedNoticeDialog] =
    useState(false);

  const [showDuplicateMaterialImpactDialog, setShowDuplicateMaterialImpactDialog] = useState(false);

  const {
    showCommentsAndAuditLogSideSection,
    toggleCommentsAndAuditLogSideSection,
    closeCommentsAndAuditLogSideSection,
    dialogProps,
    dialogElement,
  } = useEditDialogCommentsAndAuditLogPaperState(open);

  const [viewedHorizon, setViewedHorizon] = useState<IHorizonsEnum>(IHorizonsEnum.ShortTerm);

  const formMethods = useForm<IInternalMaterialImpactData>({
    defaultValues: getDefaultValuesForInputs(materialImpact),
  });

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors },
    getValues,
  } = formMethods;

  // Reset Dialog when opened
  useEffect(() => {
    if (open) {
      reset(getDefaultValuesForInputs(materialImpact));
      setViewedHorizon(IHorizonsEnum.ShortTerm);
    }
  }, [open, materialImpact, reset]);

  // Calculate Severity and Materiality

  const currentHorizons = useMemo(
    () => {
      const getHorizonValues = (horizon: IHorizonsEnum) => ({
        inheritsFromHorizon: watch(`horizons.${horizon}.inheritsFromHorizon`),
        extent: watch(`horizons.${horizon}.extent`),
        scope: watch(`horizons.${horizon}.scope`),
        probability:
          watch(`horizons.${horizon}.type`) === "potential"
            ? watch(`horizons.${horizon}.probability`)
            : null,
        irreversibility:
          watch(`horizons.${horizon}.result`) === "negative"
            ? watch(`horizons.${horizon}.irreversibility`)
            : null,
        materialityUser: watch(`horizons.${horizon}.overrideMateriality`)
          ? watch(`horizons.${horizon}.materialityUser`)
          : null,
        hasNegativeEffectOnHumanRights: watch(`horizons.${horizon}.hasNegativeEffectOnHumanRights`),
      });

      return {
        shortTerm: getHorizonValues(IHorizonsEnum.ShortTerm),
        mediumTerm: getHorizonValues(IHorizonsEnum.MediumTerm),
        longTerm: getHorizonValues(IHorizonsEnum.LongTerm),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    HORIZONS_VALUES.map((horizon) => [
      watch(`horizons.${horizon}.inheritsFromHorizon`),
      watch(`horizons.${horizon}.extent`),
      watch(`horizons.${horizon}.scope`),
      watch(`horizons.${horizon}.result`),
      watch(`horizons.${horizon}.irreversibility`),
      watch(`horizons.${horizon}.type`),
      watch(`horizons.${horizon}.probability`),
      watch(`horizons.${horizon}.overrideMateriality`),
      watch(`horizons.${horizon}.materialityUser`),
      watch(`horizons.${horizon}.hasNegativeEffectOnHumanRights`),
    ]).flat(),
  );

  // isDirty

  const isDirty = useIroEditIsDirtyHook(control, currentHorizons);

  // Severity Calculation

  const [calculatedTotalMaterialityDegree, calculatedTotalMateriality] = useMemo(() => {
    const getValuesForHorizon = (horizon: IHorizonsEnum) => {
      const horizonKeyToUse = currentHorizons[horizon].inheritsFromHorizon ?? horizon;

      const horizonToUse = currentHorizons[horizonKeyToUse];

      return {
        extent: horizonToUse.extent,
        scope: horizonToUse.scope,
        irreversibility: horizonToUse.irreversibility ?? undefined,
        probability: horizonToUse.probability ?? undefined,
        hasNegativeEffectOnHumanRights: horizonToUse.hasNegativeEffectOnHumanRights,
      };
    };

    const context = DmaConfigurationUtilities.materialContextFromConfig(dmaConfiguration);

    // Calculate Materiality (either user override or automatic calculation)
    const materiality = HORIZONS_VALUES.map((horizon) => {
      if (currentHorizons[horizon].materialityUser !== null) {
        return currentHorizons[horizon].materialityUser;
      }

      return new MaterialImpactCalculator(
        getValuesForHorizon(horizon),
        context,
      ).calculateMateriality();
    }).some((isMaterial) => isMaterial);

    const materialityDegree = new MaterialImpactHorizonsCalculator(
      {
        shortTerm: getValuesForHorizon(IHorizonsEnum.ShortTerm),
        mediumTerm: getValuesForHorizon(IHorizonsEnum.MediumTerm),
        longTerm: getValuesForHorizon(IHorizonsEnum.LongTerm),
      },
      context,
    ).calculateMaterialityDegree();

    return [materialityDegree, materiality];
  }, [dmaConfiguration, currentHorizons]);

  const horizonsWithOwnValues = useMemo(() => {
    return HORIZONS_VALUES.filter((horizon) => !currentHorizons[horizon].inheritsFromHorizon);
  }, [currentHorizons]);

  const hasHorizonsComment = useMemo(
    () => horizonsWithOwnValues.length > 1,
    [horizonsWithOwnValues],
  );

  // Inheritance State

  const horizonsInheritanceState: IInheritFromHorizonsState = useMemo(
    () => {
      function getInheritanceState(horizon: IHorizonsEnum): IInheritFromHorizonsStateItem {
        const inheritsFromHorizon = watch(`horizons.${horizon}.inheritsFromHorizon`);
        return {
          inUse: HORIZONS_VALUES.map((horizon) =>
            watch(`horizons.${horizon}.inheritsFromHorizon`),
          ).some((inheritsFromHorizon) => inheritsFromHorizon === horizon),
          inheritsValues: !!inheritsFromHorizon,
        };
      }

      return {
        shortTerm: getInheritanceState(IHorizonsEnum.ShortTerm),
        mediumTerm: getInheritanceState(IHorizonsEnum.MediumTerm),
        longTerm: getInheritanceState(IHorizonsEnum.LongTerm),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    HORIZONS_VALUES.map((horizon) => watch(`horizons.${horizon}.inheritsFromHorizon`)),
  );

  // Close Handling (Data Emission)

  const generateBaseMaterialImpactData = useCallback(
    (data: IInternalMaterialImpactData) => {
      // Setup basic result
      const result: IBaseDMAMaterialImpactData = {
        title: data.title.trim(),
        responsibleUserId: data.responsibleUserId ?? undefined,
        description: data.description?.trim() || undefined,
        assessmentExplanation: data.assessmentExplanation?.trim() || undefined,
        areas: data.areas,
        horizonsComment: hasHorizonsComment ? data.horizonsComment?.trim() || undefined : undefined,
        horizons: {
          shortTerm: createFormHorizonAPIPayload(data.horizons, IHorizonsEnum.ShortTerm),
          mediumTerm: createFormHorizonAPIPayload(data.horizons, IHorizonsEnum.MediumTerm),
          longTerm: createFormHorizonAPIPayload(data.horizons, IHorizonsEnum.LongTerm),
        },
        contributingUserIds: data.contributingUserIds,
        associatedDataEntryObjects: data.associatedDataEntryObjects,
        sourceIds: data.sourceIds,
      };

      // Clear horizonsComment if all horizons have the same value (input not shown in this case)
      if (horizonsWithOwnValues.length <= 1) {
        result.horizonsComment = undefined;
      }

      return result;
    },
    [hasHorizonsComment, horizonsWithOwnValues.length],
  );

  const handleEmitData = (data: IInternalMaterialImpactData) => {
    onClose(generateBaseMaterialImpactData(data));
  };

  const handleChangeState = (newState: IIROState) => {
    // Skip if the same
    if (newState === materialImpact?.state) {
      return;
    }
    // Open incomplete Notice Dialog if not complete yet
    if (newState === IIROState.Shared && !materialImpact?.assessmentExplanation) {
      setShowIncompleteForSharedNoticeDialog(true);
      return;
    }
    // Trigger Update
    onUpdateIROState?.(newState);
  };

  const handleCloseIncompleteForSharedNoticeDialog = () => {
    setShowIncompleteForSharedNoticeDialog(false);
    setError(
      "assessmentExplanation",
      {
        message: t("error_assessmentExplanation_required", {
          ns: "dma_impact_or_effect_edit_dialog_common",
        }),
      },
      { shouldFocus: true },
    );
  };

  // Duplication Logic
  const handleDuplicateMaterialImpactDialog = () => {
    setShowDuplicateMaterialImpactDialog(true);
  };

  const handleCloseDuplicateMaterialImpactDialog = useCallback(
    async (shouldDuplicate: boolean) => {
      if (shouldDuplicate) {
        const payload = {
          ...generateBaseMaterialImpactData(getValues()),
          title: "COPY - " + getValues("title"),
        };

        await wrapApiPromise(
          duplicateMaterialImpactMutation.mutateAsync({
            organizationId,
            recordingPeriodId,
            esrsTopicId: esrsTopic?.id ?? "",
            dmaCategoryId: materialImpact?.dmaCategoryId ?? "",
            dataEntryObjectId: dataEntryObject.id,
            payload: payload,
          }),
          { type: BasicSnackbarApiActionType.DUPLICATE_MATERIAL_IMPACT },
        );
        // Only close edit Dialog when confirmed
        onClose(null);
      }

      setShowDuplicateMaterialImpactDialog(false);
    },
    [
      duplicateMaterialImpactMutation,
      dataEntryObject.id,
      esrsTopic,
      generateBaseMaterialImpactData,
      getValues,
      materialImpact,
      onClose,
      organizationId,
      recordingPeriodId,
      wrapApiPromise,
    ],
  );

  // Render Dialog

  const { user } = useUserContext();

  const isResponsibleUser =
    materialImpact?.responsibleUserId && user?.userProfile.id === materialImpact?.responsibleUserId;

  const editMode = !!materialImpact;

  const mainDmaCategory = useIroEditDialogMainDmaCategory(esrsTopic, dmaCategory);
  const areCommentsAvailable = esrsTopic && materialImpact && mainDmaCategory;

  const isCategoryVerified = useMemo(() => {
    return !!dmaCategory && dmaCategory.materialState === IDMACategoryState.Verified;
  }, [dmaCategory]);

  return (
    <FormProvider {...formMethods}>
      <InfoDialogText
        open={showIncompleteForSharedNoticeDialog}
        onClose={handleCloseIncompleteForSharedNoticeDialog}
        title={t("incomplete_for_shared_notice_dialog_title")}
        content={<FormatTranslation t={t} i18nKey="incomplete_for_shared_notice" />}
      />

      {/*Duplicate Material Impact Dialog*/}
      <ConfirmDialogTextBody
        open={showDuplicateMaterialImpactDialog}
        loading={duplicateMaterialImpactMutation.isPending}
        error={duplicateMaterialImpactMutation.error}
        title={
          <FormatTranslation
            i18nKey="duplicate_dialog_title"
            t={t}
            values={{ name: materialImpact?.title }}
          />
        }
        content={t("duplicate_dialog_content")}
        onClose={handleCloseDuplicateMaterialImpactDialog}
      />

      {areCommentsAvailable && (
        <EditDialogCommentsAndChangelogPaper
          open={showCommentsAndAuditLogSideSection}
          dialogOpen={open}
          organizationId={organizationId}
          relations={{
            entity: "material-impact",
            materialImpactId: materialImpact.id,
          }}
          linkMetadata={{
            organizationId: organizationId,
            recordingPeriodId: recordingPeriodId,
            esrsTopicId: esrsTopic.id,
            topLevelDmaCategoryId: mainDmaCategory.id,
            entity: "material-impact",
            materialImpactId: materialImpact.id,
          }}
          dialogElement={dialogElement}
          onClose={closeCommentsAndAuditLogSideSection}
        />
      )}

      <EditDialogWrapper
        open={open}
        title={
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" alignItems="flex-start">
              {/* Breadcrumbs */}
              <Box flex={1} component="span">
                <DMABreadcrumbsComponent esrsTopic={esrsTopic} dmaCategory={dmaCategory} />
              </Box>

              <Box display="flex" gap={0.5} mt={-1} mr={-2}>
                {/* 3-Dot Menu */}
                {editMode && !readOnly && (
                  <DotMenu
                    disabled={loading}
                    menuItems={[
                      {
                        label: t("delete_tooltip"),
                        onClick: onDelete,
                        icon: <DeleteIcon />,
                      },
                      {
                        label: t("move_to_other_topic"),
                        onClick: onMoveIRO,
                        disabled: isCategoryVerified,
                        disabledTooltip: t("topic_disabled_reason", {
                          ns: "move_iro_to_other_topic_dialog",
                        }),
                        icon: <ArrowRightIcon />,
                      },
                      {
                        label: t("duplicate"),
                        onClick: handleDuplicateMaterialImpactDialog,
                        disabled: isDirty,
                        disabledTooltip: t("duplicate_disabled_reason"),
                        icon: <CopyIcon />,
                      },
                    ]}
                  />
                )}

                {/* Open Comment and Audit Log Sidebar */}
                {areCommentsAvailable && (
                  <EditDialogExpandCommentsAndAuditLogPaperButton
                    showSideSection={showCommentsAndAuditLogSideSection}
                    onToggle={toggleCommentsAndAuditLogSideSection}
                  />
                )}
              </Box>
            </Box>
            <Divider sx={{ mx: -3, mt: -2 }} />
            <Box display="flex" flexDirection="column" gap={1}>
              {/* IRO Type Chip */}
              {editMode && <IroTypeIndicator iroType="impact" />}

              <Box display="flex" alignItems="flex-start" gap={2}>
                {/* Title Text */}
                <Box flex={1} component="span">
                  {readOnly && materialImpact?.title}
                  {!readOnly && // Only show when NOT readOnly
                    (editMode ? (
                      <FormatTranslation
                        i18nKey="title_edit"
                        t={t}
                        values={{ title: materialImpact.title }}
                      />
                    ) : (
                      t("title_create")
                    ))}
                </Box>

                {/* Status Indicator */}
                {editMode && onUpdateIROState && (
                  <>
                    {/* Allow any Changes by Responsible User AND Changes down from Shared */}
                    {isResponsibleUser || materialImpact.state === IIROState.Shared ? (
                      <IROStateSelect
                        value={materialImpact.state}
                        onChange={handleChangeState}
                        disabled={isDirty || parentDisabled}
                        readOnly={iroStateReadOnly}
                      />
                    ) : (
                      <IROStateIndicator state={materialImpact.state} />
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        }
        mode={editMode ? "edit" : "create"}
        loading={!!loading}
        hasChanges={isDirty}
        onCancel={() => onClose(null)}
        onSave={handleSubmit(handleEmitData)}
        error={error}
        dialogProps={{
          fullWidth: true,
          maxWidth: "md",
          ...dialogProps,
        }}
        readOnly={readOnly}
      >
        <Box display="flex" flexDirection="column" gap={2} pt={1}>
          <IroCommonInputs
            organizationId={organizationId}
            type="material-impact"
            existingEntityId={materialImpact?.id}
            onClickComments={toggleCommentsAndAuditLogSideSection}
            disabled={internalDisabled}
            readOnly={!!readOnly}
          />

          {/* Areas Checkboxes */}
          <IROAreasCheckboxesComponent
            dataEntryObject={dataEntryObject}
            disabled={internalDisabled}
            esrsTopic={esrsTopic}
            entityType="impact"
          />

          {/* Horizons */}
          <Box mt={2}>
            <Divider sx={{ mx: -3 }} />

            {/* Horizons Tabs */}
            <Tabs
              variant="fullWidth"
              value={viewedHorizon}
              onChange={(_, newValue) => setViewedHorizon(newValue)}
              sx={{ mx: -3 }}
            >
              {ORDERED_HORIZONS_VALUES.map((horizon) => (
                <Tab
                  key={horizon}
                  value={horizon}
                  label={
                    <EditDialogHorizonTabContent
                      horizon={horizon}
                      error={!!errors.horizons?.[horizon]}
                    />
                  }
                />
              ))}
            </Tabs>
            <Divider sx={{ mx: -3, mb: 3 }} />

            {/*
              Horizons Sections.
              Have to always be mounted since validation otherwise won't trigger on submit
            */}
            {ORDERED_HORIZONS_VALUES.map((horizon) => (
              <Box
                key={horizon}
                height={viewedHorizon === horizon ? "auto" : 0}
                overflow="hidden"
                mx={-3}
                px={3}
              >
                <DMAMaterialImpactHorizonEditSection
                  // Fix change of inheritance value does not trigger update of inputs and severity/materiality preview
                  key={currentHorizons[horizon].inheritsFromHorizon}
                  control={control}
                  horizon={horizon}
                  inheritFromHorizonsState={horizonsInheritanceState}
                  dmaConfiguration={dmaConfiguration}
                  setValue={setValue}
                  showHorizonsCommentInput={hasHorizonsComment}
                  disabled={internalDisabled}
                  esrsTopic={esrsTopic}
                />
              </Box>
            ))}
          </Box>

          <Divider sx={{ mx: -3 }} />

          {/* Calculated Severity */}

          <Box display="flex" alignItems="center">
            <Typography variant="h6">
              {t("header_total_evaluation", { ns: "dma_impact_or_effect_edit_dialog_common" })}
            </Typography>
            {/* Only show if esrs topic is not null, since tooltips are bound to a specific topic */}
            {esrsTopic && (
              <IntercomReferenceWidget
                entityType="impact"
                identifier={`dialog_subheading_total_assessment_create_help.${esrsTopic.identifier}`}
                iconSize={IconSize.Smaller}
              />
            )}
          </Box>

          <Box display="flex" gap={2}>
            <Typography sx={{ minWidth: 150 }}>
              {t("materiality_degree_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
            </Typography>
            <Typography fontWeight="bold">
              {DmaFormatUtilities.formatMaterialityDegree(calculatedTotalMaterialityDegree)}
            </Typography>
          </Box>

          {/* Calculated Materiality */}
          <Box display="flex" gap={2}>
            <Typography sx={{ minWidth: 150 }}>
              {t("materiality_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}:
            </Typography>
            <Typography fontWeight="bold">
              {calculatedTotalMateriality
                ? t("materiality_true_state", { ns: "dma_common" })
                : t("materiality_false_state", { ns: "dma_common" })}
            </Typography>
          </Box>
        </Box>
      </EditDialogWrapper>
    </FormProvider>
  );
};
