import { Dispatch, FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IInputParameterValueMetaDataEsrsTopicIdentifierEnum } from "@netcero/netcero-core-api-client";
import { ArrayUtilities } from "@netcero/netcero-common";
import { useDebounce } from "@uidotdev/usehooks";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { SearchTextInput } from "../../common/components/search-text-input.component";
import { DialogCloseButton } from "../../common/dialogs/dialog-button.components";
import { MdrEsrsTopicIndicators } from "./mdr-esrs-topic-indicator.component";
import {
  SelectionMode,
  useInputComponentForSelectionMode,
} from "../../selection-mode/selection-mode.hook";
import { ClearButton } from "../../common/components/buttons.components";

export interface IMDRSelectDisplayItem {
  id: string;
  title: string;
  associatedTopics: IInputParameterValueMetaDataEsrsTopicIdentifierEnum[];
}

interface IMDRSelectDialogProps<T> {
  open: boolean;
  title: string;
  onClose: () => void;
  items: T[];
  selectedItemIds: string[];
  onChange: Dispatch<string[]>;
  toDisplayItem: (item: T) => IMDRSelectDisplayItem;
  selectionMode: SelectionMode;
}

export const MDRSelectDialog = <T,>({
  open,
  title,
  onClose,
  items,
  selectedItemIds,
  onChange,
  toDisplayItem,
  selectionMode,
}: IMDRSelectDialogProps<T>) => {
  const { t } = useTranslation("mdr_select_dialog");

  const displayItems = useMemo(() => items.map(toDisplayItem), [items, toDisplayItem]);

  const handleChangeItem = useCallback(
    (item: IMDRSelectDisplayItem) => {
      if (selectionMode === "single") {
        onChange([item.id]);
      } else {
        if (selectedItemIds.includes(item.id)) {
          onChange(selectedItemIds.filter((id) => id !== item.id));
        } else {
          onChange(ArrayUtilities.sorted([...selectedItemIds, item.id]));
        }
      }
    },
    [selectedItemIds, selectionMode, onChange],
  );

  const handleClear = useCallback(() => onChange([]), [onChange]);

  const [search, setSearch] = useState("");
  useEffect(() => {
    if (!open) {
      setTimeout(() => setSearch(""), 100);
    }
  }, [open]);
  const debouncedSearch = useDebounce(search, 250);

  const filteredItems = useMemo(
    () =>
      displayItems.filter((item) =>
        item.title.toLowerCase().includes(debouncedSearch.toLowerCase()),
      ),
    [displayItems, debouncedSearch],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* Search */}
        <SearchTextInput
          search={search}
          onSearchChange={setSearch}
          textFieldProps={{
            sx: { mt: 0.5, mb: 2 },
          }}
        />

        {/* Table */}
        <TableContainer sx={{ maxHeight: 300 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t("table_header_name")}</TableCell>
                <TableCell align="right">{t("table_header_topics")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item) => (
                <MDRSelectDialogTableRow
                  key={item.id}
                  item={item}
                  selectedItemIds={selectedItemIds}
                  selectionMode={selectionMode}
                  onChange={() => handleChangeItem(item)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <ClearButton onClick={handleClear}>{t("clear_selection", { ns: "buttons" })}</ClearButton>
        <Box flex={1} />
        <DialogCloseButton onClick={onClose}>{t("close", { ns: "buttons" })}</DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};

interface IMDRSelectDialogTableRowProps {
  item: IMDRSelectDisplayItem;
  selectedItemIds: string[];
  onChange: VoidFunction;
  selectionMode: SelectionMode;
}

const MDRSelectDialogTableRow: FC<IMDRSelectDialogTableRowProps> = ({
  item,
  selectedItemIds,
  onChange,
  selectionMode,
}) => {
  const isSelected = useMemo(() => selectedItemIds.includes(item.id), [item.id, selectedItemIds]);
  const SelectionComponent = useInputComponentForSelectionMode(selectionMode);

  return (
    <TableRow onClick={onChange} sx={{ cursor: "pointer" }}>
      <TableCell sx={{ width: 74 }}>
        <SelectionComponent checked={isSelected} />
      </TableCell>
      <TableCell>
        <LineClampTypographyWithTooltip maxLines={3}>{item.title}</LineClampTypographyWithTooltip>
      </TableCell>
      <TableCell>
        <Box display="flex" flexWrap="wrap" gap={1} justifyContent="end">
          <MdrEsrsTopicIndicators topics={item.associatedTopics} />
        </Box>
      </TableCell>
    </TableRow>
  );
};
