import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IStakeholderFeedbackType,
  IVerifyStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckAllIcon, InfoIcon, UndoIcon } from "../../../common/constants/tabler-icon.constants";
import { DMACategoryFeedbackUtilities } from "../../common/dma-category-feedback.utilities";
import { useESRSTopicContext } from "../../esrs-topic.context";
import {
  useAllManualFeedbacksForDmaCategoryQuery,
  useAllStakeholderFeedbacksForCategoryQuery,
} from "../../dma.queries";
import { InfoDialogText } from "../../../common/dialogs/variants/info.dialog";
import { ConfirmDialogTextBody } from "../../../common/dialogs/variants/confirm.dialog";

interface IDMACategoryValidationButtonsProps {
  dmaCategory: IDMACategoryWithEffectsAndChildren;
  disabled?: boolean;
  onVerify: (payload: IVerifyStakeholderFeedbackRequest) => void;
}

export const DMACategoryValidationButtons: FC<IDMACategoryValidationButtonsProps> = ({
  dmaCategory,
  disabled,
  onVerify,
}) => {
  const { t } = useTranslation("dma_category_stakeholder_feedback_dialog");

  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [confirmDialogState, setConfirmDialogState] = useState<{
    showDialog: boolean;
    feedbackType: IStakeholderFeedbackType | null;
  }>({
    showDialog: false,
    feedbackType: null,
  });
  const closeConfirmDialog = useCallback(
    () => setConfirmDialogState((prev) => ({ ...prev, showDialog: false })),
    [],
  );

  const { organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId } =
    useESRSTopicContext();

  const stakeholderFeedbacks = useAllStakeholderFeedbacksForCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  const manualStakeholderFeedbacks = useAllManualFeedbacksForDmaCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  const canValidateMaterial = useMemo(
    () => dmaCategory.materialState === IDMACategoryState.Shared,
    [dmaCategory.materialState],
  );

  const hasSubmittedFeedbackForMaterial = useMemo(
    () =>
      DMACategoryFeedbackUtilities.hasSubmittedFeedbackForType(
        stakeholderFeedbacks.data?.stakeholderFeedbacks ?? [],
        manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks ?? [],
        IStakeholderFeedbackType.Material,
      ),
    [
      manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks,
      stakeholderFeedbacks.data?.stakeholderFeedbacks,
    ],
  );

  const canValidateFinancial = useMemo(
    () => dmaCategory.financialState === IDMACategoryState.Shared,
    [dmaCategory.financialState],
  );

  const hasSubmittedFeedbackForFinancial = useMemo(
    () =>
      DMACategoryFeedbackUtilities.hasSubmittedFeedbackForType(
        stakeholderFeedbacks.data?.stakeholderFeedbacks ?? [],
        manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks ?? [],
        IStakeholderFeedbackType.Financial,
      ),
    [
      manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks,
      stakeholderFeedbacks.data?.stakeholderFeedbacks,
    ],
  );

  const handleConfirmClose = (confirmed: boolean) => {
    if (confirmed && confirmDialogState.feedbackType) {
      onVerify({
        verified: true,
        feedbackType: confirmDialogState.feedbackType,
      });
    }
    closeConfirmDialog();
  };

  const handleValidationClick = (feedbackType: IStakeholderFeedbackType) => {
    const hasSubmittedFeedback =
      feedbackType === IStakeholderFeedbackType.Material
        ? hasSubmittedFeedbackForMaterial
        : hasSubmittedFeedbackForFinancial;

    if (hasSubmittedFeedback) {
      // Directly perform the validation if feedback has been submitted
      onVerify({
        verified: true,
        feedbackType: feedbackType,
      });
    } else {
      // Show the confirm dialog if feedback has not been submitted
      setConfirmDialogState({
        showDialog: true,
        feedbackType: feedbackType,
      });
    }
  };

  return (
    <>
      <InfoDialogText
        open={showHelpDialog}
        onClose={() => setShowHelpDialog(false)}
        title={t("title_validation_help")}
        content={t("content_validation_help")}
      />

      <ConfirmDialogTextBody
        open={confirmDialogState.showDialog}
        title={t("title_confirm_validation")}
        content={t("content_confirm_validation_" + confirmDialogState.feedbackType)}
        onClose={handleConfirmClose}
        confirmOverride={t("button_confirm_validation")}
      />

      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="h6">{t("header_validation")}</Typography>
        <IconButton size="small" onClick={() => setShowHelpDialog(true)}>
          <InfoIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
        {/* Material State */}
        {dmaCategory.materialState === IDMACategoryState.Verified ? (
          <Tooltip title={t("tooltip_undo_verified")}>
            <span>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<UndoIcon />}
                disabled={disabled}
                onClick={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Material,
                  });
                }}
              >
                {t("material_verified")}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={!canValidateMaterial ? t("tooltip_steps_to_verify_material") : ""}>
            <span>
              <Button
                variant="outlined"
                color="success"
                startIcon={<CheckAllIcon />}
                onClick={() => handleValidationClick(IStakeholderFeedbackType.Material)}
                disabled={!canValidateMaterial || disabled}
              >
                {t("button_validate_material")}
              </Button>
            </span>
          </Tooltip>
        )}

        {dmaCategory.financialState === IDMACategoryState.Verified ? (
          <Tooltip title={t("tooltip_undo_verified")}>
            <span>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<UndoIcon />}
                disabled={disabled}
                onClick={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Financial,
                  });
                }}
              >
                {t("financial_verified")}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={!canValidateFinancial ? t("tooltip_steps_to_verify_financial") : ""}>
            <span>
              <Button
                variant="outlined"
                color="success"
                startIcon={<CheckAllIcon />}
                onClick={() => handleValidationClick(IStakeholderFeedbackType.Financial)}
                disabled={!canValidateFinancial || disabled}
              >
                {t("button_validate_financial")}
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </>
  );
};
