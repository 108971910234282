import { Dispatch, FC, useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { SerializedEditorState } from "lexical";
import { LexicalUtilities } from "@netcero/netcero-common";
import { LEXICAL_EMPTY_EDITOR_STATE } from "../lexical.constants";

interface IValueAndOnChangeProps {
  value: SerializedEditorState | undefined | null;
  onChange: Dispatch<SerializedEditorState | null>;
}

export const ValueAndOnChange: FC<IValueAndOnChangeProps> = ({ value, onChange }) => {
  const [editor] = useLexicalComposerContext();

  // Register onChange Event
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      const newState = editorState.toJSON();
      if (!value || LexicalUtilities.hasChanges(value, newState)) {
        const isEmpty = LexicalUtilities.isEmpty(newState);
        onChange(isEmpty ? null : newState);
      }
    });
    // This is fine since we only want to trigger on changes of editor or onChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, onChange]);

  // Subscribe to value changes
  useEffect(() => {
    const updateValue = value ?? LEXICAL_EMPTY_EDITOR_STATE;
    const editorValue = editor.getEditorState().toJSON();
    if (LexicalUtilities.hasChanges(updateValue, editorValue)) {
      editor.setEditorState(editor.parseEditorState(updateValue));
    }
  }, [editor, value]);

  return null;
};
