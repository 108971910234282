import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export interface IBreadCrumb {
  label: string;
  to?: string;
}

export interface IBreadCrumbs {
  items?: IBreadCrumb[];
}

export const AppBreadCrumbs = ({ items }: IBreadCrumbs) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items?.map((item, index) =>
        item.to ? (
          <Link
            key={`${item.to}-${index}`}
            color="text.secondary"
            underline="hover"
            typography="body2"
            to={item.to}
            component={RouterLink}
          >
            {item.label}
          </Link>
        ) : (
          <Typography key={`text-${index}`} variant="body2">
            {item.label}
          </Typography>
        ),
      )}
    </Breadcrumbs>
  );
};
