import { Box, Divider } from "@mui/material";
import { FC } from "react";
import {
  SIDE_BAR_CORNER_RADIUS_V2,
  SIDE_BAR_EXPANDED_WIDTH_V2,
  SIDE_BAR_SHRUNK_WIDTH_V2,
  SIDE_BAR_TRANSITION_DELAY,
  SIDE_BAR_TRANSITION_DURATION_V2,
} from "./side-bar-v2.component";
import { OrganizationSelectTopBarSection } from "../top-bar-sections/top-bar-organization-select-section.component";
import { TopBarUserSection } from "../top-bar-sections/top-bar-user-section.component";
import { TopBarPickerSection } from "../top-bar-sections/top-bar-picker-section.component";
import { AuditLogsIconButton } from "../../audit-logging/components/audit-logs-icon-button.component";

/**
 * Configuration for the Top Bar.
 * organizationSelect: Configuration for the Organization Select Top Bar Section. If organizationId is undefined, the Organization Select Top Bar Section will not be rendered.
 */
export interface ITopBarConfiguration {
  organizationSelect?: {
    organizationId: string;
    onChange: (organizationId: string) => void;
  };
  recordingPeriodId?: string;
  dataEntryObjectSelect?: {
    dataEntryObjectId?: string;
    /** When not set all DEOs are selectable. When set only DEOs with provided IDs are selectable */
    selectableDataEntryObjectIds?: string[];
    onChange: (dataEntryObjectId: string) => void;
  };
}

export interface ITopBarProps {
  open: boolean;
  configuration?: ITopBarConfiguration;
}

export const TOP_BAR_HEIGHT = 60;
const TOP_BAR_X_PADDING = 20;
export const TOP_BAR_Y_PADDING = 5;

export const TopBar: FC<ITopBarProps> = ({ open, configuration }) => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      sx={{
        overflowX: "unset",
        overflowY: "unset",
        border: "none",
        py: `${TOP_BAR_Y_PADDING}px`,
        pl: `${
          (open ? SIDE_BAR_EXPANDED_WIDTH_V2 : SIDE_BAR_SHRUNK_WIDTH_V2) +
          SIDE_BAR_CORNER_RADIUS_V2 +
          TOP_BAR_X_PADDING
        }px`,
        transition: `padding-left ${SIDE_BAR_TRANSITION_DURATION_V2}ms`,
        transitionDelay: `${SIDE_BAR_TRANSITION_DELAY}ms`,
        pr: `${TOP_BAR_X_PADDING}px`,
        height: `${TOP_BAR_HEIGHT}px`,
        width: "100%",
        backgroundColor: (theme) => theme.palette.common.white,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* RecordingPeriod- & DEO-Picker */}
      <Box>
        <TopBarPickerSection
          organizationId={configuration?.organizationSelect?.organizationId}
          recordingPeriodId={configuration?.recordingPeriodId}
          dataEntryObjectId={configuration?.dataEntryObjectSelect?.dataEntryObjectId}
          selectableDataEntryObjectIds={
            configuration?.dataEntryObjectSelect?.selectableDataEntryObjectIds
          }
          onChangeDataEntryObjectId={configuration?.dataEntryObjectSelect?.onChange}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        {/* Audit Logs */}
        {configuration?.organizationSelect && (
          <AuditLogsIconButton organizationId={configuration?.organizationSelect?.organizationId} />
        )}
        {/* User */}
        <TopBarUserSection />
        {configuration?.organizationSelect && (
          <>
            {/* Divider */}
            <Divider orientation="vertical" sx={{ height: 26, mr: -2 }} />
            {/* Organization Select */}
            <OrganizationSelectTopBarSection
              organizationId={configuration.organizationSelect.organizationId}
              onChange={configuration.organizationSelect.onChange}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
