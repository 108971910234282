import {
  IInputParameterValueMetaDataNestedOptions,
  IInputParameterValueMetaDataNestedOptionsOption,
} from "@netcero/netcero-core-api-client";
import { ChangeEvent, createContext, FC, useContext, useMemo, useState } from "react";
import MultipleSelectChip from "../../common/components/multiple-chip.component";
import { useProcessOptions } from "../hooks/process-options.hook";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../content-translation/hooks/translate-content.hook";
import { useTranslation } from "react-i18next";
import { useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { useObserveSizeState } from "../../common/hooks/use-observe-size-state.hook";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import {
  ArrayUtilities,
  DataEntryObjectInputParameterValueDefinitionForNestedOptions,
  IRecursiveStructureResult,
  OptionalDefinition,
  RecursiveUtilities,
} from "@netcero/netcero-common";
import Checkbox from "@mui/material/Checkbox";
import { useDebounce } from "@uidotdev/usehooks";
import { useFilteredOptions } from "../hooks/filtered-options.hook";
import { ClearIcon, ReloadIcon } from "../../common/constants/tabler-icon.constants";
import { DialogCloseButton } from "../../common/dialogs/dialog-button.components";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";

// re-enable this if you want to test performance :)
// 12 x 12 * 5 = 720
/*
const options: IInputParameterValueMetaDataNestedOptionsOption[] = generateRange(12).map((i) => ({
  name: ContentTranslationUtilities.createSingleEntry(`Option Nr. ${i}`),
  value: `option_${i}`,
  children: generateRange(12).map((i2) => ({
    name: ContentTranslationUtilities.createSingleEntry(`Option Nr. ${i}|${i2}`),
    value: `option_${i}_${i2}`,
    children: generateRange(5).map((i3) => ({
      name: ContentTranslationUtilities.createSingleEntry(`Option Nr. ${i}|${i2}|${i3}`),
      value: `option_${i}_${i2}_${i3}`,
      children: [],
    })),
  })),
}));

const metaData: IInputParameterValueMetaDataNestedOptions = {
  type: "nested-options",
  multiple: true,
  options,
};
*/

interface INestedOptionsContext {
  // Basic Data
  items: IRecursiveStructureResult<IInputParameterValueMetaDataNestedOptionsOption>[];
  metaData: IInputParameterValueMetaDataNestedOptions;
  selectedItems: Set<string>;
  // Event handlers
  onSelectSingle: (value: string) => void;
  onSelectMultiple: (value: string | string[], add: boolean) => void;
}

const NestedOptionsContext = createContext<INestedOptionsContext>({
  items: [],
  metaData: { type: "nested-options", multiple: false, options: [] },
  selectedItems: new Set<string>(),
  onSelectSingle: () => {},
  onSelectMultiple: () => {},
});

const useNestedOptionsContext = () => useContext(NestedOptionsContext);

/** TODO: When touching this again - try to use ICommonInputProps */
interface INestedOptionsInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForNestedOptions>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForNestedOptions) => void;
  disabled?: boolean;
  error?: string;
  metaData: IInputParameterValueMetaDataNestedOptions;
  disableMaxWidth?: boolean;
  label?: string;
  required?: boolean;
}

export const NestedOptionsInputComponent: FC<INestedOptionsInputComponentProps> = ({
  variant,
  label,
  required,
  disabled,
  error,
  // just comment out this line to enable performance tests :)
  metaData,
  value,
  onChange,
}) => {
  const variantProps = useVariantFormProps(variant);
  const stylesSx = useVariantSxStyles(variant);
  const translateOptionalContent = useTranslateOptionalContent();

  const placeholderText = useMemo(
    () => translateOptionalContent(metaData.placeholder),
    [translateOptionalContent, metaData.placeholder],
  );

  const { parents, parsedValue, flatOptions, currentNames } = useProcessOptions({
    value,
    metaData,
  });
  const { isOpen, openDialog, closeDialog } = useDialogStateWithoutData();

  return (
    <>
      {/* Render select with chips*/}
      <MultipleSelectChip
        {...variantProps}
        formControlSx={stylesSx}
        onSelectClick={openDialog}
        chipNames={currentNames}
        label={label}
        placeholder={placeholderText}
        required={required}
        disabled={disabled}
        error={error}
      />

      {/* Render dialogue */}
      <NestedOptionsDialog
        open={isOpen}
        metaData={metaData}
        onClose={closeDialog}
        items={flatOptions}
        value={parsedValue}
        onChange={onChange}
        required={required}
        parents={parents}
      />
    </>
  );
};

interface INestedOptionsDialogProps {
  open: boolean;
  metaData: IInputParameterValueMetaDataNestedOptions;
  onClose: () => void;
  items: IRecursiveStructureResult<IInputParameterValueMetaDataNestedOptionsOption>[];
  parents: Record<string, string>;
  required?: boolean;
  // Get current value / change value
  value: string[];
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForNestedOptions) => void;
}

const NestedOptionsDialog: FC<INestedOptionsDialogProps> = ({
  open,
  metaData,
  onClose,
  items,
  value,
  onChange,
  required,
  parents,
}) => {
  const translateContent = useTranslateContent();
  const { t } = useTranslation("nested_options_input_component");

  // persist state for search bar
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 100);

  // filter items
  const filteredItems = useFilteredOptions({ parents, searchTerm: debouncedSearchTerm, items });

  // event handlers
  const handleClear = () => {
    onChange(metaData.multiple ? [] : "");
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
      <DialogTitle>
        {metaData.dialogTitle && translateContent(metaData.dialogTitle)} {required && "*"}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={3.5} py={1}>
          {/* Render input for search */}
          <NestedOptionsSearchBarComponent
            value={searchTerm}
            onValueChange={setSearchTerm}
            onClear={handleClear}
            clearDisabled={value.length === 0}
          />

          {/* Actually render item picker */}
          <NestedOptionsListComponent
            items={filteredItems}
            metaData={metaData}
            value={value}
            onChange={onChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCloseButton onClick={() => onClose()}>{t("buttons.close")}</DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};

interface INestedOptionsSearchBarComponentProps {
  value: string | null;
  onValueChange: (value: string | null) => void;
  onClear: () => void;
  clearDisabled: boolean;
}

const NestedOptionsSearchBarComponent: FC<INestedOptionsSearchBarComponentProps> = ({
  onValueChange,
  value,
  onClear,
  clearDisabled,
}) => {
  const { t } = useTranslation("nested_options_input_component");

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onValueChange(newValue.trim() ? newValue : null);
  };

  const handleClearSearch = () => {
    onValueChange(null);
  };

  return (
    <Box display="flex" flexDirection="row" gap={1.5} alignItems="center">
      <TextField
        size="small"
        placeholder={t("search.placeholder")}
        value={value ?? ""}
        onChange={handleValueChange}
        sx={{ flex: 1 }}
        InputProps={{
          endAdornment: (
            <span>
              <IconButton size="small" onClick={handleClearSearch} disabled={value === null}>
                <ClearIcon />
              </IconButton>
            </span>
          ),
        }}
      />
      <Tooltip title={t("buttons.clear")}>
        <span>
          <IconButton size="small" onClick={onClear} disabled={clearDisabled}>
            <ReloadIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

interface INestedOptionsListComponentProps {
  items: IRecursiveStructureResult<IInputParameterValueMetaDataNestedOptionsOption>[];
  metaData: IInputParameterValueMetaDataNestedOptions;
  value: string[];
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForNestedOptions) => void;
}

const NestedOptionsListComponent: FC<INestedOptionsListComponentProps> = ({
  items,
  metaData,
  value,
  onChange,
}) => {
  const { size, setElementRef } = useObserveSizeState();

  // Enables faster lookups
  const selectedItems = useMemo(() => {
    return new Set(value);
  }, [value]);

  const handleSelectMultipleChange = (
    currentValue: DataEntryObjectInputParameterValueDefinitionForNestedOptions,
    add: boolean,
  ) => {
    const currentValueSet = new Set(ArrayUtilities.alwaysArray(currentValue));

    const newItems = add
      ? ArrayUtilities.deduplicatePrimArray([...value, ...currentValueSet])
      : value.filter((v) => !currentValueSet.has(v));

    // be sure to maintain order so as not to mess with change tracking
    newItems.sort();

    onChange(newItems);
  };

  // Event handlers
  const contextValue: INestedOptionsContext = useMemo(
    () => ({
      metaData,
      items,
      selectedItems,
      onSelectSingle: onChange,
      onSelectMultiple: handleSelectMultipleChange,
    }),
    // no need to specify the handlers here since they will be redefined upon every render anyway
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, metaData, selectedItems],
  );

  return (
    <Box
      width="100%"
      height={`min(calc(100vh - 300px), ${40 * items.length + 8}px)`}
      ref={setElementRef}
    >
      <NestedOptionsContext.Provider value={contextValue}>
        <FixedSizeList
          height={size.height}
          width={size.width}
          itemCount={items.length}
          itemSize={40}
        >
          {NestedOptionsSelectGroupItemComponent}
        </FixedSizeList>
      </NestedOptionsContext.Provider>
    </Box>
  );
};

const NestedOptionsSelectGroupItemComponent: FC<ListChildComponentProps> = ({ index, style }) => {
  const translateContent = useTranslateContent();
  const context = useNestedOptionsContext();

  // Derive the current state from context
  const currentItem: IRecursiveStructureResult<IInputParameterValueMetaDataNestedOptionsOption> =
    useMemo(() => context.items[index], [context.items, index]);

  const leaves = useMemo(
    () =>
      RecursiveUtilities.flattenRecursiveStructureDown(currentItem.item.children).filter(
        (c) => c.children.length === 0,
      ),
    [currentItem.item.children],
  );

  const allLeafChildrenSelected = useMemo(
    () => leaves.every((c) => context.selectedItems.has(c.value)),
    [leaves, context.selectedItems],
  );

  const anyLeafChildrenSelected = useMemo(
    () => leaves.some((c) => context.selectedItems.has(c.value)),
    [leaves, context.selectedItems],
  );

  const hasChildren = useMemo(
    () => currentItem.item.children.length > 0,
    [currentItem.item.children.length],
  );

  const isSelfSelected = useMemo(
    () => context.selectedItems.has(currentItem.item.value),
    [context.selectedItems, currentItem.item.value],
  );

  // Actually render component
  const renderSelect = useMemo(() => {
    if (context.metaData.multiple) {
      return !hasChildren ? (
        <Checkbox
          checked={isSelfSelected}
          onChange={(event, checked) => {
            context.onSelectMultiple(currentItem.item.value, checked);
          }}
        />
      ) : (
        <Checkbox
          checked={allLeafChildrenSelected}
          indeterminate={anyLeafChildrenSelected && !allLeafChildrenSelected}
          onChange={() => {
            context.onSelectMultiple(
              leaves.map((l) => l.value),
              !allLeafChildrenSelected,
            );
          }}
        />
      );
    }

    return hasChildren ? null : (
      <Radio
        checked={isSelfSelected}
        onChange={(event, checked) => {
          if (!checked) {
            return;
          }
          context.onSelectSingle(currentItem.item.value);
        }}
        value={currentItem.item.value}
      />
    );
  }, [
    context,
    hasChildren,
    isSelfSelected,
    currentItem.item.value,
    allLeafChildrenSelected,
    anyLeafChildrenSelected,
    leaves,
  ]);

  return (
    // This div is here to apply the absolute styles provided by the virtualized lists
    <div style={style}>
      <Box display="flex" flexDirection="row" height="100%">
        {ArrayUtilities.generateRange(currentItem.recursionLevel).map((index) => (
          <Box key={index} height={style.height} px={2.5}>
            <Divider orientation="vertical" />
          </Box>
        ))}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="space-between"
          height="100%"
          flex={1}
        >
          <Box
            flex={1}
            height="100%"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={1}
          >
            {/* Render select component */}
            {renderSelect}
            {/* Render name of option */}
            <Typography>{translateContent(currentItem.item.name)}</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
