import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IGetAllInputParameterRecordingStructures200Response } from "@netcero/netcero-core-api-client";

export const getRecordingPeriodRecordingStructuresQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
) => ["inputParameterRecordingStructure", organizationId, recordingPeriodId];
export const useRecordingPeriodRecordingStructuresQuery = <
  T = IGetAllInputParameterRecordingStructures200Response,
>(
  organizationId: string,
  recordingPeriodId: string,
  select?: (data: IGetAllInputParameterRecordingStructures200Response) => T,
) => {
  return useQuery({
    queryKey: getRecordingPeriodRecordingStructuresQueryKey(organizationId, recordingPeriodId),
    queryFn: () => {
      return CoreApiService.InputParameterRecordingStructureApi.getAllInputParameterRecordingStructures(
        organizationId,
        recordingPeriodId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    select,
  });
};

export const useInputParameterRecordingStructureInputParametersQuery = (
  organizationId: string,
  recordingPeriodId: string,
  recordingStructureId: string,
) => {
  return useQuery({
    queryKey: [
      "inputParameterRecordingStructureInputParameters",
      organizationId,
      recordingPeriodId,
      recordingStructureId,
    ],
    queryFn: () =>
      CoreApiService.InputParameterRecordingStructureApi.getAllInputParametersOfInputParameterRecordingStructure(
        organizationId,
        recordingPeriodId,
        recordingStructureId,
      )
        .then((req) => req())
        .then((res) => res.data),
  });
};

export const getGetDataEntryObjectsWithAssignedInputParametersQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
  inputParameterRecordingStructureId: string,
) => [
  "data-entry-objects-with-assigned-input-parameters",
  organizationId,
  recordingPeriodId,
  inputParameterRecordingStructureId,
];

export function useGetDataEntryObjectsWithAssignedInputParametersQuery(
  organizationId: string,
  recordingPeriodId: string,
  inputParameterRecordingStructureId: string,
  { enabled = true } = {},
) {
  return useQuery({
    queryKey: getGetDataEntryObjectsWithAssignedInputParametersQueryKey(
      organizationId,
      recordingPeriodId,
      inputParameterRecordingStructureId,
    ),
    queryFn: async () => {
      return CoreApiService.InputParameterRecordingStructureApi.getDataEntryObjectsWithAssignedInputParameters(
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    enabled,
  });
}
