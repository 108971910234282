import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { ICommentRelations, ISortOrder } from "@netcero/netcero-core-api-client";
import { ILocalGetCommentsResponse } from "./comments.types";
import { SerializedEditorState } from "lexical";

const getQueryKeyForCommentRelation = (relations: ICommentRelations): string[] => {
  switch (relations.entity) {
    case "material-impact":
      return [relations.materialImpactId];
    case "financial-effect":
      return [relations.financialEffectId];
    case "data-entry-object-input-parameter":
      return [relations.dataEntryObjectId, relations.inputParameterId];
  }
};

export const getCommentsQueryKeyWithoutOrder = (
  organizationId: string,
  relations: ICommentRelations,
) => {
  return [
    "organizations",
    organizationId,
    "comments",
    relations.entity,
    ...getQueryKeyForCommentRelation(relations),
  ];
};

export const getCommentsQueryKey = (
  organizationId: string,
  relations: ICommentRelations,
  sortOrder: ISortOrder,
) => {
  return [...getCommentsQueryKeyWithoutOrder(organizationId, relations), sortOrder];
};

export const useCommentsQuery = <T = ILocalGetCommentsResponse>(
  organizationId: string,
  relations: ICommentRelations,
  sortOrder: ISortOrder,
  select?: (data: ILocalGetCommentsResponse) => T,
) => {
  return useQuery({
    queryKey: getCommentsQueryKey(organizationId, relations, sortOrder),
    queryFn: () =>
      CoreApiService.CommentsApi.getComments(organizationId, sortOrder, JSON.stringify(relations))
        .then((req) => req())
        .then((r) => r.data)
        .then((data) => ({
          ...data,
          comments: data.comments.map((c) => ({
            ...c,
            createdAt: new Date(c.createdAt),
            content: c.content as SerializedEditorState,
          })),
        })),
    select,
  });
};

const selectCommentsCount = (res: ILocalGetCommentsResponse) => res.comments.length;
export const useCommentsCountQuery = (organizationId: string, relations: ICommentRelations) => {
  return useCommentsQuery(organizationId, relations, ISortOrder.Asc, selectCommentsCount);
};
