import { Box } from "@mui/material";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import TreePicker, { TreeNodeData } from "../../modules/common/inputs/tree-picker.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { ILocalRecordingPeriod } from "../../modules/recording-periods/recording-periods.utilities";
import { useUserContext } from "../../modules/user/user.context";
import { QueriesWrapper } from "../../modules/common/components/queries-wrapper.component";
import { useGetUserDataEntryObjectsAccessQuery } from "../../modules/user/users.queries";
import { AuthenticationUtilities, OrgRole } from "@netcero/netcero-common";

type DeoPickerProps = {
  organizationId: string;
  recordingPeriod: ILocalRecordingPeriod;
  selectedDeoIds: string[];
  onChange: (selectedDeoIds: string[]) => void;
};

export const DeoPicker: FC<DeoPickerProps> = ({
  organizationId,
  recordingPeriod,
  selectedDeoIds,
  onChange,
}) => {
  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId,
    recordingPeriod.id,
  );

  const { user } = useUserContext();
  const userOrgRole = user
    ? AuthenticationUtilities.getOrgRoleOfUser(user.jwtPayload, organizationId)
    : null;
  const getUserDataEntryObjectsAccessQuery = useGetUserDataEntryObjectsAccessQuery(
    organizationId,
    user?.jwtPayload.sub ?? "",
    recordingPeriod.id,
    { enabled: !!user?.jwtPayload.sub },
  );

  return (
    <QueriesWrapper
      queries={[organizationStructureQuery, getUserDataEntryObjectsAccessQuery]}
      build={([organizationStructure, userDataEntryObjectsAccess]) => {
        if (!("structure" in organizationStructure)) {
          // TODO: not sure if this can happen? => handle properly
          return <Box>Organization structure not found</Box>;
        }

        const rootDeo = organizationStructure.structure;
        return (
          <TreePicker
            treeNodeData={mapDeoToTreeNodeData(rootDeo)}
            selectedNodeIds={selectedDeoIds}
            onChange={onChange}
            selectableNodeIds={
              userOrgRole === OrgRole.OBJECT_ADMIN
                ? userDataEntryObjectsAccess.dataEntryObjectIds
                : undefined
            }
          />
        );
      }}
    />
  );
};

function mapDeoToTreeNodeData(deo: IDataEntryObject): TreeNodeData {
  return {
    id: deo.id,
    label: deo.name,
    children: deo.children?.map(mapDeoToTreeNodeData),
  };
}
