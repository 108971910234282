import { useMemo } from "react";
import { Checkbox, Radio } from "@mui/material";

export type SelectionMode = "single" | "multiple";

/**
 * This hook deduces the selection mode from the given count. If the count is > 1, "multiple" is chosen,
 * otherwise, "single" is used.
 * @param count
 */
export const useDeduceSelectionModeFromCount = (count: number): SelectionMode => {
  return useMemo(() => (count > 1 ? "multiple" : "single"), [count]);
};

/**
 * This hook chooses the correct input component based on the passed selection mode. It returns:
 * * `Radio` for single-select
 * * `Checkbox` for multi-select
 * @param mode
 */
export const useInputComponentForSelectionMode = (mode: SelectionMode) => {
  return useMemo(() => (mode === "single" ? Radio : Checkbox), [mode]);
};
