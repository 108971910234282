import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { FormatUtilities } from "../utilities/format.utilities";
import { useTranslation } from "react-i18next";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { Dispatch, FC } from "react";

interface IRecordingPeriodSelectProps {
  recordingPeriodOptions: ILocalRecordingPeriod[];
  selectedRecordingPeriod: ILocalRecordingPeriod | null;
  onChangeRecordingPeriod: Dispatch<ILocalRecordingPeriod>;
}

export const RecordingPeriodSelect: FC<IRecordingPeriodSelectProps> = ({
  recordingPeriodOptions,
  selectedRecordingPeriod,
  onChangeRecordingPeriod,
}) => {
  const { t } = useTranslation("organization_structure_configuration_component");

  const handleSelectRecordingPeriod = (event: SelectChangeEvent<string>) => {
    const selectedRecordingPeriod = recordingPeriodOptions.find(
      (recordingPeriod) => recordingPeriod.id === event.target.value,
    );
    if (selectedRecordingPeriod) {
      onChangeRecordingPeriod(selectedRecordingPeriod);
    }
  };

  return (
    <FormControl fullWidth sx={{ minWidth: "400px" }}>
      <InputLabel id="recording-period-select-label">
        {t("recording_period_select_label")}
      </InputLabel>
      <Select
        labelId="recording-period-select-label"
        id="recording-period-select"
        value={selectedRecordingPeriod?.id ?? ""}
        label={t("recording_period_select_label")}
        onChange={handleSelectRecordingPeriod}
      >
        {recordingPeriodOptions.map((recordingPeriod) => (
          <MenuItem key={recordingPeriod.id} value={recordingPeriod.id}>
            <Box display="flex" gap={1}>
              <span>{FormatUtilities.formatDate(recordingPeriod.startDate)}</span>
              <span>-</span>
              <span>{FormatUtilities.formatDate(recordingPeriod.endDate)}</span>
              <Box>
                <Divider orientation="vertical" />
              </Box>
              <Box display="flex" gap={0.5}>
                <Tooltip title={recordingPeriod.description} placement="right">
                  <span>{recordingPeriod.name}</span>
                </Tooltip>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
