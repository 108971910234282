import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { IDataEntryObjectRecordingInputParameter } from "./data-entry-object-input-parameters.utilities";
import { CoreApiService } from "../core-api/core-api.service";
import { getDataEntryObjectValuesQueryKey } from "../data-entry-object-values/queries/data-entry-object-values.queries";
import { getEvaluationForRecordingPeriodQueryKey } from "../evaluation/evaluation.queries";
import {
  IDataEntryObjectInputParameterContext,
  IDataEntryObjectInputParameterExclusion,
  IUpdateSourcesOfDataEntryObjectInputParameterRequest,
} from "@netcero/netcero-core-api-client";
import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";
import { getSourcesForInputParameterQueryKey } from "./data-entry-object-input-parameter.query";
import { getGetDataEntryObjectsWithAssignedInputParametersQueryKey } from "../recording-structures/recording-structures.queries";

interface IUpdateInputParameterConfigurationMutation {
  updates: IDataEntryObjectRecordingInputParameter[] | IDataEntryObjectRecordingInputParameter;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  recordingStructureId: string;
}

async function invalidateDataEntryObjectInputParametersQueries(
  queryClient: QueryClient,
  variables: {
    organizationId: string;
    recordingPeriodId: string;
    dataEntryObjectId: string;
    recordingStructureId: string;
  },
  invalidateEvaluation: boolean,
  invalidateDeoAccess: boolean,
) {
  const promises = [
    // Invalidate Cached DEO Input Parameters
    queryClient.invalidateQueries({
      queryKey: getDataEntryObjectValuesQueryKey(
        variables.organizationId,
        variables.recordingPeriodId,
        variables.dataEntryObjectId,
        variables.recordingStructureId,
      ),
    }),
  ];

  // Invalidate Cached DEO Access
  if (invalidateDeoAccess) {
    promises.push(
      queryClient.invalidateQueries({
        queryKey: getGetDataEntryObjectsWithAssignedInputParametersQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.recordingStructureId,
        ),
      }),
    );
  }

  // Invalidate Cached Entered Values
  await Promise.all(promises);

  if (invalidateEvaluation) {
    // Invalidate Cached Evaluations
    void queryClient.invalidateQueries({
      queryKey: getEvaluationForRecordingPeriodQueryKey(
        variables.organizationId,
        variables.recordingPeriodId,
        variables.recordingStructureId,
      ),
    });
  }
}

export const useUpdateInputParameterConfigurationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      updates,
      recordingPeriodId,
      dataEntryObjectId,
      organizationId,
    }: IUpdateInputParameterConfigurationMutation) => {
      if (!Array.isArray(updates)) {
        updates = [updates];
      }

      return Promise.all(
        updates.map((update) =>
          CoreApiService.DataEntryObjectInputParameterRecordingApi.setDataEntryObjectInputParameterData(
            organizationId,
            recordingPeriodId,
            dataEntryObjectId,
            update.inputParameter.id,
            {
              isActive: update.isActive,
              contributingUserIds: update.contributingUserIds,
              responsibleUserId: update.responsibleUserId,
            },
          ).then((req) => req()),
        ),
      );
    },
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false, true),
  });
};

interface IDEOIPIdentityData {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  recordingStructureId: string;
  inputParameterId: string;
}

export const useResetDataEntryObjectInputParameterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
    }: IDEOIPIdentityData) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.resetInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false, false),
  });
};

interface IWithDataEntryObjectInputParameterContext {
  context: IDataEntryObjectInputParameterContext;
}

export const useSubmitDataEntryObjectInputParameterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
      context,
    }: IDEOIPIdentityData & IWithDataEntryObjectInputParameterContext) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.submitInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        context,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false, false),
  });
};

export const useApproveDataEntryObjectInputParameterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
    }: IDEOIPIdentityData) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.approveInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false, false),
  });
};

export const useRejectDataEntryObjectInputParameterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
      context,
    }: IDEOIPIdentityData & IWithDataEntryObjectInputParameterContext) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.rejectInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        context,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false, false),
  });
};

interface IExclusionMutationData extends IDEOIPIdentityData {
  payload: IDataEntryObjectInputParameterExclusion;
}

export const useExcludeDataEntryObjectInputParameterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
      payload,
    }: IExclusionMutationData) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.excludeInputParameter(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        payload,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false, false),
  });
};

export const useIncludeDataEntryObjectInputParameterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
    }: IDEOIPIdentityData) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.includeInputParameter(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
      ).then((req) => req()),
    onSuccess: async (_data, variables) =>
      invalidateDataEntryObjectInputParametersQueries(queryClient, variables, false, false),
  });
};

interface IUpdateSourcesForInputParameterRecording {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  inputParameterId: string;
  sourceIds: IUpdateSourcesOfDataEntryObjectInputParameterRequest;
}

export const useUpdateSourcesForInputParameterMutation = () => {
  const invalidateQueriesByKeys = useInvalidateQueriesByKeys();

  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
      sourceIds,
    }: IUpdateSourcesForInputParameterRecording) => {
      return CoreApiService.DataEntryObjectInputParameterRecordingApi.updateDataEntryObjectInputParameterSources(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        sourceIds,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables) => {
      await invalidateQueriesByKeys({
        awaitQueryKeys: [
          getSourcesForInputParameterQueryKey(
            variables.organizationId,
            variables.recordingPeriodId,
            variables.dataEntryObjectId,
            variables.inputParameterId,
          ),
        ],
      });
    },
  });
};
