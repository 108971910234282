import { OrgRole } from "@netcero/netcero-common";
import { useUserContext } from "../user.context";
import { useAuthenticatedUserOrganizationRole } from "./authenticated-user-org-role.hook";
import { useGetUserDataEntryObjectsAccessQuery } from "../users.queries";

export function useHasObjectAdminDataEntryObjectAccess({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
}: {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
}) {
  const { user } = useUserContext();
  const authenticatedUserId = user?.jwtPayload.sub ?? "";
  const authenticatedUserOrgRole = useAuthenticatedUserOrganizationRole();

  const { data } = useGetUserDataEntryObjectsAccessQuery(
    organizationId,
    authenticatedUserId,
    recordingPeriodId,
    {
      enabled: !!authenticatedUserId && authenticatedUserOrgRole === OrgRole.OBJECT_ADMIN,
    },
  );
  return !!data?.dataEntryObjectIds.includes(dataEntryObjectId);
}
