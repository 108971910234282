import {
  IHydratedCompleteInputParameterRecordingEsrsStructure,
  IHydratedCompleteInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
  IHydratedInputParameterRecordingEsrsStructure,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
} from "./input-parameter-recording-esrs-structures.interfaces";
import { ILocalDataEntryObjectInputParameter } from "../../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import {
  IInputParameter,
  IInputParameterESRSMetaData,
  IInputParameterESRSMetaDataPhaseInEligibility,
  IInputParameterRecordingStructureGroupESRS,
  IInputParameterRecordingStructureGroupESRSDisclosureRequirement,
  IInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
  IInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
} from "@netcero/netcero-core-api-client";
import { InputParameterRecordingStructuresUtilities } from "../input-parameter-recording-structures.utilities";
import { isEnum } from "class-validator";
import { EsrsFilters, StatusExcludedOption } from "../../filters/filter.constants";
import { OrgRole, UndefinedOrNull } from "@netcero/netcero-common";
import { EntityKey } from "@netcero/netcero-entities";

/** EFRAG IDs of DRs which are eligible for phase in */
export enum EligiblePhaseInDrsEfragIds {
  SBM_1 = "SBM-1",
  SBM_3 = "SBM-3",
  E1_6 = "E1-6",
  E1_9 = "E1-9",
  E2_6 = "E2-6",
  E3_5 = "E3-5",
  E4_6 = "E4-6",
  E5_6 = "E5-6",
  S1_7 = "S1-7",
  S1_8 = "S1-8",
  S1_11 = "S1-11",
  S1_12 = "S1-12",
  S1_13 = "S1-13",
  S1_14 = "S1-14",
  S1_15 = "S1-15",
}

export class InputParameterRecordingEsrsStructuresUtilities {
  /**
   * Hydrates the given ESRS structure with the given data entry object input parameters
   * @param esrsStructure The ESRS structure to hydrate
   * @param dataEntryObjectInputParameters The data entry object input parameters to hydrate the structure with
   */
  public static hydrateStructureStructureGroup(
    esrsStructure: IInputParameterRecordingStructureGroupESRS,
    dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[],
  ): IHydratedInputParameterRecordingEsrsStructure {
    const inputParameterLookup =
      InputParameterRecordingStructuresUtilities.getInputParameterEntryLookupMap(
        dataEntryObjectInputParameters,
      );

    return {
      ...esrsStructure,
      sections: esrsStructure.sections.map((section) =>
        InputParameterRecordingEsrsStructuresUtilities.hydrateDisclosureRequirementSection(
          section,
          inputParameterLookup,
        ),
      ),
      // Handle Phase In
      phaseIn: esrsStructure.phaseIn
        ? {
            ...esrsStructure.phaseIn,
            inputParameters: esrsStructure.phaseIn.inputParameterIds.map(
              (inputParameterId) => inputParameterLookup.get(inputParameterId) ?? null,
            ),
          }
        : null,
    };
  }

  private static hydrateDisclosureRequirementSection(
    section: IInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
    inputParametersLookup: Map<string, ILocalDataEntryObjectInputParameter>,
  ): IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection {
    return {
      ...section,
      disclosureRequirements: section.disclosureRequirements.map((disclosureRequirement) =>
        InputParameterRecordingEsrsStructuresUtilities.hydrateDisclosureRequirement(
          disclosureRequirement,
          inputParametersLookup,
        ),
      ),
    };
  }

  private static hydrateDisclosureRequirement(
    disclosureRequirement: IInputParameterRecordingStructureGroupESRSDisclosureRequirement,
    inputParametersLookup: Map<string, ILocalDataEntryObjectInputParameter>,
  ): IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement {
    const disclosureRequirementInputParameter = inputParametersLookup.get(disclosureRequirement.id);

    if (!disclosureRequirementInputParameter) {
      console.warn(
        `Could not find disclosure requirement with id (input parameter that holds disclosure requirement data): ${disclosureRequirement.id}`,
      );
    }

    return {
      ...disclosureRequirement,
      disclosureRequirementInputParameter: disclosureRequirementInputParameter ?? null,
      inputParameters: disclosureRequirement.inputParameters.map((inputParameter) =>
        InputParameterRecordingEsrsStructuresUtilities.hydrateDisclosureRequirementInputParameter(
          inputParameter,
          inputParametersLookup,
        ),
      ),
    };
  }

  private static hydrateDisclosureRequirementInputParameter(
    inputParameter: IInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
    inputParametersLookup: Map<string, ILocalDataEntryObjectInputParameter>,
  ): IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP {
    const deoInputParameter = inputParametersLookup.get(inputParameter.parameterId);

    if (!deoInputParameter) {
      console.warn(
        `Could not find input parameter inside disclosure requirement id: ${inputParameter.parameterId}`,
      );
    }

    return {
      ...inputParameter,
      inputParameter: deoInputParameter ?? null,
    };
  }

  // Clean Up Structure Group

  /**
   * Cleans up the given hydrated structure group by removing incomplete disclosure requirements and input parameters.
   * @param hydratedStructure
   * @returns A tuple containing the cleaned up structure, incomplete disclosure requirements and incomplete input parameters
   */
  public static cleanUpStructureGroup(
    hydratedStructure: IHydratedInputParameterRecordingEsrsStructure,
  ): [
    IHydratedCompleteInputParameterRecordingEsrsStructure,
    IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement[],
    IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP[],
  ] {
    const incompleteDisclosureRequirements: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement[] =
      [];
    const incompleteInputParameters: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP[] =
      [];

    const completeStructure = {
      ...hydratedStructure,
      sections: hydratedStructure.sections.map((section) => ({
        ...section,
        disclosureRequirements: section.disclosureRequirements
          // Remove all incomplete disclosure requirements and add to incomplete list
          .filter((disclosureRequirement) => {
            if (disclosureRequirement.disclosureRequirementInputParameter === null) {
              incompleteDisclosureRequirements.push(disclosureRequirement);
              return false;
            }
            return true;
          })
          .map((disclosureRequirement) => ({
            ...disclosureRequirement,
            // Now only the complete disclosure requirements are left
            disclosureRequirementInputParameter:
              disclosureRequirement.disclosureRequirementInputParameter!,
            // Remove all incomplete disclosure requirements and add to incomplete list
            inputParameters: disclosureRequirement.inputParameters.filter((inputParameter) => {
              if (inputParameter.inputParameter === null) {
                incompleteInputParameters.push(inputParameter);
                return false;
              }
              return true;
            }) as IHydratedCompleteInputParameterRecordingStructureGroupESRSDisclosureRequirementIP[], // This is fine since we filtered out the incomplete ones
          })),
      })),
      phaseIn: hydratedStructure.phaseIn
        ? {
            ...hydratedStructure.phaseIn,
            inputParameters: hydratedStructure.phaseIn.inputParameters.filter(
              (inputParameter, index): inputParameter is ILocalDataEntryObjectInputParameter => {
                if (inputParameter === null) {
                  incompleteInputParameters.push({
                    parameterId: hydratedStructure.phaseIn!.inputParameterIds[index],
                    level: 0,
                    inputParameter,
                  });
                  return false;
                }
                return true;
              },
            ),
          }
        : null,
    };

    return [completeStructure, incompleteDisclosureRequirements, incompleteInputParameters];
  }

  // Helper functions for filter conditions
  private static matchesResponsiblePerson(
    responsibleUserId: string | undefined,
    filterValues: string[],
  ): boolean {
    return filterValues.length === 0 || filterValues.includes(responsibleUserId ?? "");
  }

  private static matchesContributingPeople(
    contributingUserIds: string[] | undefined,
    filterValues: string[],
  ): boolean {
    if (filterValues.length === 0) {
      return true;
    }
    if (!contributingUserIds) {
      return false;
    }
    return contributingUserIds?.some((id) => filterValues.includes(id));
  }

  private static matchesAuthenticatedUser({
    authenticatedUserId,
    responsibleUserId,
    contributingUserIds,
  }: {
    authenticatedUserId: EntityKey;
    responsibleUserId: string | undefined;
    contributingUserIds: string[] | undefined;
  }) {
    return (
      responsibleUserId === authenticatedUserId ||
      contributingUserIds?.includes(authenticatedUserId)
    );
  }

  public static canUserAccessInputParameter({
    authenticatedUserId,
    authenticatedUserOrgRole,
    inputParameter,
    hasObjectAdminDataEntryObjectAccess,
  }: {
    authenticatedUserId?: EntityKey;
    authenticatedUserOrgRole?: OrgRole | null;
    inputParameter: UndefinedOrNull<ILocalDataEntryObjectInputParameter>;
    hasObjectAdminDataEntryObjectAccess?: boolean;
  }) {
    if (!authenticatedUserId) {
      return false;
    }

    if (authenticatedUserOrgRole === OrgRole.ORGANIZATION_ADMIN) {
      return true;
    }

    if (authenticatedUserOrgRole === OrgRole.OBJECT_ADMIN && hasObjectAdminDataEntryObjectAccess) {
      return true;
    }

    if (
      (authenticatedUserOrgRole === OrgRole.VIEW_MEMBERS ||
        authenticatedUserOrgRole === OrgRole.OBJECT_ADMIN) &&
      InputParameterRecordingEsrsStructuresUtilities.matchesAuthenticatedUser({
        responsibleUserId: inputParameter?.responsibleUserId,
        contributingUserIds: inputParameter?.contributingUserIds,
        authenticatedUserId,
      })
    ) {
      return true;
    }

    return false;
  }

  private static matchesStatus(
    inputParameter: UndefinedOrNull<ILocalDataEntryObjectInputParameter>,
    filterValues: string[],
    isDrExcluded?: boolean,
  ): boolean {
    if (filterValues.length === 0) {
      return true;
    }

    const isExcludedSelected = filterValues.includes(StatusExcludedOption);
    const statusFilters = filterValues.filter((value) => value !== StatusExcludedOption);

    // if either the parameter itself or the DR is excluded, skip checking the status
    if (isDrExcluded || inputParameter?.exclude !== undefined) {
      return isExcludedSelected;
    }

    return statusFilters.includes(inputParameter?.status ?? "");
  }

  private static matchesPhaseIn(
    inputParameter: IInputParameter | undefined,
    filterValues: string[],
  ): boolean {
    if (filterValues.length === 0) {
      return true;
    }
    if (!inputParameter) {
      return false;
    }

    const isEligible =
      InputParameterRecordingEsrsStructuresUtilities.isInputParameterEligibleForPhaseIn(
        inputParameter,
      );
    const filterIncludesTrue = filterValues.includes("true");
    const filterIncludesFalse = filterValues.includes("false");

    // Check if eligible and filterTrue OR not eligible and filterFalse
    // Also covers both filters being selected
    return (filterIncludesTrue && isEligible) || (filterIncludesFalse && !isEligible);
  }

  private static matchesOptional(
    esrsMetaData: IInputParameterESRSMetaData | undefined,
    filterValues: string[],
  ): boolean {
    if (filterValues.length === 0) {
      return true;
    }
    if (!esrsMetaData) {
      return false;
    }

    const isOptional = !esrsMetaData.required;
    const filterIncludesTrue = filterValues.includes("true");
    const filterIncludesFalse = filterValues.includes("false");

    // Check if optional and filterTrue OR not optional and filterFalse
    // Also covers both filters being selected
    return (filterIncludesTrue && isOptional) || (filterIncludesFalse && !isOptional);
  }

  public static getFiltersFromSearchParams(searchParams: URLSearchParams) {
    return {
      responsiblePerson: searchParams.getAll(EsrsFilters.ResponsiblePerson),
      contributingPeople: searchParams.getAll(EsrsFilters.ContributingPeople),
      status: searchParams.getAll(EsrsFilters.Status),
      phaseIn: searchParams.getAll(EsrsFilters.PhaseIn),
      optional: searchParams.getAll(EsrsFilters.Optional),
    };
  }

  /**
   * Filters the hydrated structure group based on the provided search parameters.
   * @param hydratedStructure The hydrated structure group to be filtered.
   * @param searchParams - The URL search parameters containing filter criteria.
   */
  public static filterStructureGroup(
    hydratedStructure: IHydratedInputParameterRecordingEsrsStructure,
    searchParams: URLSearchParams,
    authenticatedUserId?: EntityKey,
    authenticatedUserOrgRole?: OrgRole | null,
    hasObjectAdminDataEntryObjectAccess?: boolean,
  ): IHydratedInputParameterRecordingEsrsStructure {
    const filters =
      InputParameterRecordingEsrsStructuresUtilities.getFiltersFromSearchParams(searchParams);

    const filteredStructure = {
      ...hydratedStructure,
      // Sections
      sections: hydratedStructure.sections
        ?.map((section) => ({
          ...section,
          disclosureRequirements: section.disclosureRequirements
            .map((dr) => ({
              ...dr,
              // First filter IPs
              inputParameters: dr.inputParameters.filter((ip) => {
                const ipInput = ip.inputParameter;
                return (
                  (InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
                    authenticatedUserId,
                    authenticatedUserOrgRole,
                    inputParameter: dr.disclosureRequirementInputParameter,
                    hasObjectAdminDataEntryObjectAccess,
                  }) ||
                    InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
                      authenticatedUserId,
                      authenticatedUserOrgRole,
                      inputParameter: ipInput,
                      hasObjectAdminDataEntryObjectAccess,
                    })) &&
                  InputParameterRecordingEsrsStructuresUtilities.doesInputParameterMatchFilters(
                    ipInput,
                    filters,
                    dr.disclosureRequirementInputParameter?.exclude !== undefined,
                  )
                );
              }),
            }))
            // Then filter DRs
            .filter((dr) => {
              // Keep DR if it has filtered IPs
              if (dr.inputParameters.length > 0) {
                return true;
              }

              // Otherwise check input parameter of DR
              const drInput = dr.disclosureRequirementInputParameter;
              return (
                InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
                  authenticatedUserId,
                  authenticatedUserOrgRole,
                  inputParameter: drInput,
                  hasObjectAdminDataEntryObjectAccess,
                }) &&
                InputParameterRecordingEsrsStructuresUtilities.doesInputParameterMatchFilters(
                  drInput,
                  filters,
                )
              );
            }),
        }))
        // Finally filter sections
        .filter((section) => section.disclosureRequirements.length > 0),
      // Phase In
      phaseIn: hydratedStructure.phaseIn
        ? {
            ...hydratedStructure.phaseIn,
            inputParameters: hydratedStructure.phaseIn?.inputParameters.filter(
              (inputParameter) =>
                InputParameterRecordingEsrsStructuresUtilities.canUserAccessInputParameter({
                  authenticatedUserId,
                  authenticatedUserOrgRole,
                  inputParameter,
                }) &&
                InputParameterRecordingEsrsStructuresUtilities.doesInputParameterMatchFilters(
                  inputParameter,
                  filters,
                ),
            ),
          }
        : null,
    };
    return filteredStructure;
  }

  /**
   * This method determines whether the passed input parameter matches the given filters.
   * @param inputParameter The input parameter to evaluate (can also be the IP of a DR)
   * @param filters The filters to evaluate against
   * @param isDrExcluded Whether the input parameter is a child of a disclosure requirement that is excluded - if the IP is a DR itself, this should be omitted.
   * @returns Whether the input parameter matches the filters
   */
  public static doesInputParameterMatchFilters(
    inputParameter: UndefinedOrNull<ILocalDataEntryObjectInputParameter>,
    filters: {
      responsiblePerson: string[];
      contributingPeople: string[];
      status: string[];
      phaseIn: string[];
      optional: string[];
    },
    isDrExcluded?: boolean,
  ): boolean {
    return (
      InputParameterRecordingEsrsStructuresUtilities.matchesResponsiblePerson(
        inputParameter?.responsibleUserId,
        filters.responsiblePerson,
      ) &&
      InputParameterRecordingEsrsStructuresUtilities.matchesContributingPeople(
        inputParameter?.contributingUserIds,
        filters.contributingPeople,
      ) &&
      InputParameterRecordingEsrsStructuresUtilities.matchesStatus(
        inputParameter,
        filters.status,
        isDrExcluded,
      ) &&
      InputParameterRecordingEsrsStructuresUtilities.matchesPhaseIn(
        inputParameter?.inputParameter,
        filters.phaseIn,
      ) &&
      InputParameterRecordingEsrsStructuresUtilities.matchesOptional(
        inputParameter?.inputParameter?.metaData.esrs,
        filters.optional,
      )
    );
  }

  /**
   * Checks whether a given input parameter is eligible for phase in
   * @param inputParameter
   */
  public static isInputParameterEligibleForPhaseIn = (inputParameter: IInputParameter) => {
    return (
      inputParameter.metaData.esrs?.phaseInEligibility ===
      IInputParameterESRSMetaDataPhaseInEligibility.Eligible
    );
  };

  /**
   * Gets the translation key for the disclosure requirement of the given input parameter
   * @param inputParameter
   */
  public static getDisclosureRequirementTranslationKey(inputParameter: IInputParameter) {
    if (isEnum(inputParameter.metaData.esrs?.efragId, EligiblePhaseInDrsEfragIds)) {
      return inputParameter.metaData.esrs?.efragId as EligiblePhaseInDrsEfragIds;
    }

    console.warn(
      "Tried to read Disclosure Requirement translation key for non-eligible input parameter",
    );

    return null;
  }

  /**
   * Gets the dislosure requirement input parameter to which the given input parameter belongs to
   * @param inputParameterId
   * @param recordingStructure
   */
  public static getDisclosureRequirementOfInputParameterById(
    inputParameterId: string | null,
    recordingStructure: IHydratedCompleteInputParameterRecordingEsrsStructure,
  ) {
    for (const section of recordingStructure.sections) {
      for (const dr of section.disclosureRequirements) {
        const hasMatchingInputParameter = dr.inputParameters.some(
          (ip: IHydratedCompleteInputParameterRecordingStructureGroupESRSDisclosureRequirementIP) =>
            ip.inputParameter.inputParameter.id === inputParameterId,
        );

        if (hasMatchingInputParameter) {
          return dr.disclosureRequirementInputParameter.inputParameter;
        }
      }
    }

    return null;
  }

  /**
   * Gets the input parameter by id
   * @param inputParameterId
   * @param recordingStructure
   */
  public static getInputParameterById(
    inputParameterId: string | null,
    recordingStructure: IHydratedCompleteInputParameterRecordingEsrsStructure,
  ) {
    for (const section of recordingStructure.sections) {
      for (const dr of section.disclosureRequirements) {
        for (const ip of dr.inputParameters) {
          if (ip.inputParameter.inputParameter.id === inputParameterId) {
            return ip.inputParameter.inputParameter;
          }
        }

        if (dr.disclosureRequirementInputParameter.inputParameter.id === inputParameterId) {
          return dr.disclosureRequirementInputParameter.inputParameter;
        }
      }
    }

    return null;
  }

  /**
   * Gets the section id to which the given input parameter belongs
   * @param inputParameterId The id of the input parameter
   * @param recordingStructure The recording structure to search in
   */
  public static getSectionIdOfInputParameterById(
    inputParameterId: string | null,
    recordingStructure: IHydratedCompleteInputParameterRecordingEsrsStructure,
  ): string | null {
    for (const section of recordingStructure.sections) {
      for (const dr of section.disclosureRequirements) {
        // Check input parameters
        const hasMatchingInputParameter = dr.inputParameters.some(
          (ip) => ip.inputParameter.inputParameter.id === inputParameterId,
        );

        // Check disclosure requirement input parameter
        if (
          hasMatchingInputParameter ||
          dr.disclosureRequirementInputParameter.inputParameter.id === inputParameterId
        ) {
          return section.id;
        }
      }
    }
    return null;
  }
}
