import { IEmissionFactorType } from "@netcero/netcero-core-api-client";

export class EmissionFactorsUtilities {
  public static readonly MarketBasedEmissionFactorTypes: IEmissionFactorType[] = [
    IEmissionFactorType.MarketBasedEnergy,
  ];

  public static readonly LocationBasedEmissionFactorTypes: IEmissionFactorType[] = [
    IEmissionFactorType.ActivityBased,
    IEmissionFactorType.LocationBasedEnergy,
    IEmissionFactorType.SpendingBased,
    IEmissionFactorType.Other,
  ];

  public static isEmissionFactorTypeMarketBased(type: IEmissionFactorType): boolean {
    return EmissionFactorsUtilities.MarketBasedEmissionFactorTypes.includes(type);
  }

  public static isEmissionFactorTypeLocationBased(type: IEmissionFactorType): boolean {
    return EmissionFactorsUtilities.LocationBasedEmissionFactorTypes.includes(type);
  }
}
