import { FC, ReactNode, useCallback } from "react";
import { Box, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FilterSelect } from "./filter-select.component";
import { FilterOpenSearch } from "./filter-open-search.component";
import { FilterMenuSearch } from "./filter-menu-search.component";
import { FilterType, IFilterConfig } from "./filter.type";
import { ClearButton } from "../common/components/buttons.components";
import { useIsFeatureFlagEnabled } from "../common/hooks/is-feature-flag-enabled.hook";
import { FeatureFlag } from "../common/constants/feature-flags.constants";

interface IFilterBarProps {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams?: URLSearchParams) => void;
  filters: IFilterConfig[];
  startSectionElement?: ReactNode | string;
}

export function getFilterConfigGenerator(t: (key: string) => string) {
  return (filter: string, config: Omit<IFilterConfig, "name" | "label">): IFilterConfig => ({
    name: filter,
    label: t(filter),
    ...config,
  });
}

export const FilterBar: FC<IFilterBarProps> = ({
  filters,
  startSectionElement,
  searchParams,
  setSearchParams,
}) => {
  const { t } = useTranslation("filter_bar");

  // TODO: NC-XYZ Remove feature flag check
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  const handleChange = useCallback(
    (name: string, value: string | string[]) => {
      const params = new URLSearchParams(searchParams.toString());
      if (Array.isArray(value)) {
        params.delete(name);
        value.forEach((v) => params.append(name, v));
      } else {
        if (!params.getAll(name).includes(value)) {
          params.set(name, value);
        } else {
          params.delete(name);
        }
      }
      setSearchParams(params);
    },
    [searchParams, setSearchParams],
  );

  const handleClear = useCallback(() => {
    const params = new URLSearchParams(searchParams.toString());
    filters.forEach((filter) => params.delete(filter.name));
    setSearchParams(params);
  }, [filters, searchParams, setSearchParams]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isFeatureFlagEnabled ? "start" : "space-between",
        alignItems: "center",
        backgroundColor: "common.white",
        borderRadius: 5,
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 15px 5px",
        px: 2.5,
        py: 1.5,
      }}
    >
      <Box sx={{ display: "flex" }}>{startSectionElement}</Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: isFeatureFlagEnabled ? "space-between" : undefined,
          width: isFeatureFlagEnabled ? "100%" : undefined,
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 2.5, rowGap: 0.5 }}>
          {filters.map((filter) => {
            switch (filter.type) {
              case FilterType.SearchOpen:
                return (
                  <FilterOpenSearch
                    key={filter.name}
                    filter={filter}
                    searchParams={searchParams}
                    handleChange={handleChange}
                  />
                );
              case FilterType.SearchMenu:
                return (
                  <FilterMenuSearch
                    key={filter.name}
                    filter={filter}
                    searchParams={searchParams}
                    handleChange={handleChange}
                  />
                );
              case FilterType.Select:
                return (
                  <FilterSelect
                    key={filter.name}
                    filter={filter}
                    searchParams={searchParams}
                    handleChange={handleChange}
                  />
                );
              case FilterType.Checkbox:
                return (
                  <Box sx={{ fontSize: "14px", display: "flex", alignItems: "center" }}>
                    <Checkbox
                      key={filter.name}
                      checked={!!searchParams.get(filter.name)}
                      onChange={(e) => handleChange(filter.name, e.target.checked ? "true" : "")}
                      size="small"
                    />
                    <Box>{filter.label}</Box>
                  </Box>
                );
              default:
                return null;
            }
          })}
        </Box>
        <Box sx={{ display: "flex" }}>
          <ClearButton onClick={handleClear} sx={{ ml: 2 }}>
            {t("clear_filter")}
          </ClearButton>
        </Box>
      </Box>
    </Box>
  );
};
