import { Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useOrganizations } from "../../modules/organizations/organizations.hook";
import { useLocalStorage } from "@uidotdev/usehooks";
import { LS_ORGANIZATION_ID_KEY } from "../../modules/common/constants/localstorage.constants";

interface IOrganizationsRedirectPageProps {}

export const OrganizationsRedirectPage: FC<IOrganizationsRedirectPageProps> = () => {
  const { t } = useTranslation("organizations_redirect_page");

  const organizations = useOrganizations();
  const [lastUsedOrganizationId] = useLocalStorage(LS_ORGANIZATION_ID_KEY, "");

  if (organizations.length === 0) {
    return <Typography variant="body1">{t("no_associated_organizations")}</Typography>;
  }

  if (lastUsedOrganizationId && organizations.find((org) => org.id === lastUsedOrganizationId)) {
    return <Navigate to={`/organizations/${lastUsedOrganizationId}`} replace />;
  }

  return <Navigate to={`/organizations/${organizations[0].id}`} replace />;
};
