import { Predicate } from "../common";

type GetPropertyOfTypeCallback<T, U> = (node: T) => U;
export type GetChildrenCallback<T> = GetPropertyOfTypeCallback<T, T[]>;

export type TreeNodeData = {
  id: string;
  label: string;
  children: TreeNodeData[];
};

export class TreeUtilities {
  /**
   * Gets the path of tree elements from the root to the selected child.
   * Returns null if the child is not found.
   * @param tree the tree to look for the child in
   * @param getChildren a function that returns the children of the node
   * @param isSelectedChild a function that returns true if the child is the selected child
   * @returns the path of tree elements from the root to the selected child
   */
  public static getPathToTreeChild<T>(
    tree: T,
    getChildren: (node: T) => T[],
    isSelectedChild: Predicate<T>,
  ): T[] | null {
    if (isSelectedChild(tree)) {
      return [tree];
    }

    for (const child of getChildren(tree)) {
      const result = TreeUtilities.getPathToTreeChild(child, getChildren, isSelectedChild);

      if (result) {
        return [tree, ...result];
      }
    }

    return null;
  }

  /**
   * Gets the path of tree elements from the root to the selected child.
   * @param trees the trees to look for the child in
   * @param getChildren a function that returns the children of the node
   * @param isSelectedChild a function that returns true if the child is the selected child
   */
  public static getPathToTreeChildMultiple<T>(
    trees: T[],
    getChildren: (node: T) => T[],
    isSelectedChild: Predicate<T>,
  ) {
    for (const tree of trees) {
      const result = TreeUtilities.getPathToTreeChild(tree, getChildren, isSelectedChild);
      if (result !== null) {
        return result;
      }
    }

    return null;
  }

  /**
   * Gets the selected child in the tree.
   * @param tree the tree to look for the child in
   * @param getChildren a function that returns the children of the node
   * @param isSelectedChild a function that returns true if the child is the selected child
   * @returns the selected child or null if the child is not found
   */
  public static getChildInTree<T>(
    tree: T,
    getChildren: (node: T) => T[],
    isSelectedChild: Predicate<T>,
  ): T | null {
    if (isSelectedChild(tree)) {
      return tree;
    }

    for (const child of getChildren(tree)) {
      const result = TreeUtilities.getChildInTree(child, getChildren, isSelectedChild);

      if (result) {
        return result;
      }
    }

    return null;
  }

  /**
   * Checks if the node matches the search term.
   * @param node the node to check
   * @param searchTerm the search term to check against
   * @returns true if the node matches the search term, false otherwise
   */
  private static doesNodeMatchSearchTerm(node: TreeNodeData, searchTerm: string): boolean {
    return node.label.toLowerCase().includes(searchTerm.toLowerCase());
  }

  /**
   * Gets the ancestors of all nodes that match the given search term.
   * Returns empty array if nothing is found.
   * @param tree the tree to look for the child in
   * @param term string that contains the term to search for
   * @returns the array of the ancestors of all nodes that match the search term
   */
  public static getMatchingAncestorsBySearchTerm(tree: TreeNodeData, term: string): string[] {
    const matches: string[] = [];

    const search = (currentNode: TreeNodeData, parents: string[]) => {
      if (this.doesNodeMatchSearchTerm(currentNode, term)) {
        matches.push(...parents);
      }

      currentNode.children.forEach((child) => {
        search(child, [...parents, currentNode.id]);
      });
    };

    search(tree, []);
    return [...new Set(matches)];
  }

  /**
   * Gets all nodes with descendants that match the given search term.
   * Returns empty array if nothing is found.
   * @param tree the tree to look for the child in
   * @param searchTerm string that contains the term to search for
   * @returns the array of all nodes including descendants that match the search term
   */
  public static getMatchingNodesWithDescendants(
    tree: TreeNodeData,
    searchTerm: string,
  ): TreeNodeData | null {
    const filterNode = (node: TreeNodeData): TreeNodeData | null => {
      // If node matches, keep it and all descendants
      if (this.doesNodeMatchSearchTerm(node, searchTerm)) {
        return {
          ...node,
          children: node.children.map((child) => ({ ...child })),
        };
      }

      // If node doesn't match, check children
      const filteredChildren = node.children
        .map((child) => filterNode(child))
        .filter((child): child is TreeNodeData => child !== null);

      // Return node with filtered children if any children match
      if (filteredChildren.length > 0) {
        return {
          ...node,
          children: filteredChildren,
        };
      }

      // No matches in this branch
      return null;
    };

    return filterNode(tree);
  }

  /**
   * Converts a tree to a flat array.
   * @param tree The tree to convert to a flat array
   * @param getChildren A function that returns the children of the node
   * @param map The function to map the tree item to the flat array item
   * @param level The current level of the tree (initially 0)
   * @returns The flat array
   */
  public static flatMap<T, U>(
    tree: T,
    getChildren: GetChildrenCallback<T>,
    map: (item: T, level: number) => U,
    level = 0,
  ): U[] {
    const result: U[] = [];

    result.push(map(tree, level));

    for (const child of getChildren(tree)) {
      result.push(...TreeUtilities.flatMap(child, getChildren, map, level + 1));
    }

    return result;
  }

  /**
   * Converts multiple trees to a flat array. Wrapper around flatMap.
   * @param trees The trees to convert to a flat array
   * @param getChild A function that returns the children of the node
   * @param map The function to map the tree item to the flat array item
   * @param level The current level of the tree (initially 0)
   */
  public static flatMapMultiple<T, U>(
    trees: T[],
    getChild: GetChildrenCallback<T>,
    map: (item: T, level: number) => U,
    level = 0,
  ): U[] {
    const result: U[] = [];

    for (const child of trees) {
      result.push(...TreeUtilities.flatMap(child, getChild, map, level));
    }

    return result;
  }

  /**
   * Counts the number of nodes in a tree.
   * @param tree The tree to count the nodes in
   * @param getChildren A function that returns the children of the node
   */
  public static countNodes<T>(tree: T, getChildren: GetChildrenCallback<T>): number {
    return TreeUtilities.flatMap(tree, getChildren, (c) => c).length;
  }

  public static createRecursiveLookup<T, K extends string | number | symbol = string>(
    item: T,
    getId: GetPropertyOfTypeCallback<T, K>,
    getChildren: GetChildrenCallback<T>,
    lookup: Record<K, T> = {} as Record<K, T>,
  ): Record<K, T> {
    lookup[getId(item)] = item;

    for (const child of getChildren(item)) {
      TreeUtilities.createRecursiveLookup(child, getId, getChildren, lookup);
    }

    return lookup;
  }

  /**
   * Returns an array of all IDs from a tree structure.
   * @param trees The array of tree nodes to traverse.
   * @param getChildren A function that returns the children of the node.
   * @returns Array of all IDs in the tree.
   */
  public static getAllIds<T extends { id: string }>(
    trees: T[],
    getChildren: GetChildrenCallback<T>,
  ): string[] {
    const ids = trees.flatMap((tree) =>
      TreeUtilities.flatMap(tree, getChildren, (node) => node.id),
    );

    return ids;
  }

  public static getNodeAndDescendants<T>(
    tree: T,
    getChildren: GetChildrenCallback<T>,
    isSelectedChild: Predicate<T>,
  ): T[] {
    const node = TreeUtilities.getChildInTree(tree, getChildren, isSelectedChild);

    if (!node) {
      return [];
    }

    return TreeUtilities.flatMap(node, getChildren, (node) => node);
  }
}
