import { useEffect, useRef } from "react";

export interface IScrollPosition {
  x: number;
  y: number;
}

export interface IScrollPositionChange {
  newPosition: IScrollPosition;
  prevPosition: IScrollPosition;
}

export type IScrollPositionChangeCallback = (change: IScrollPositionChange) => void;

/**
 * Hook to get the current scroll position of a container or the window.
 * @param delay
 * @param onChangeCallback
 * @param container The container to listen to. If not provided, the window will be used.
 */
export function useScrollPosition(
  delay: number = 16,
  onChangeCallback?: IScrollPositionChangeCallback,
  container?: HTMLElement,
) {
  const lastScrollPositionRef = useRef<IScrollPosition>({ x: 0, y: 0 });
  const lastEventEmittedTimestampRef = useRef<number>(Date.now());

  const scrollPositionCb = useRef(onChangeCallback);
  useEffect(() => {
    scrollPositionCb.current = onChangeCallback;
  }, [onChangeCallback]);

  const emitLastEventIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const scrollContainer = container || window;

    const emitEvent = () => {
      const newValue = {
        x: container?.scrollLeft || window.scrollX,
        y: container?.scrollTop || window.scrollY,
      };
      scrollPositionCb.current?.({
        newPosition: newValue,
        prevPosition: lastScrollPositionRef.current,
      });
      lastScrollPositionRef.current = newValue;
      lastEventEmittedTimestampRef.current = Date.now();
    };

    const handleScroll = () => {
      const timeUntilNextEmit = lastEventEmittedTimestampRef.current + delay - Date.now();
      // Should already have been emitted --> Emit
      if (timeUntilNextEmit < 0) {
        emitEvent();
        if (emitLastEventIntervalRef.current !== null) {
          clearTimeout(emitLastEventIntervalRef.current);
          emitLastEventIntervalRef.current = null;
        }
      } else {
        // Setup Timeout to emit
        emitLastEventIntervalRef.current = setTimeout(() => {
          emitEvent();
          emitLastEventIntervalRef.current = null;
        }, timeUntilNextEmit);
      }
    };
    // Setup Listeners
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [delay, container]);

  return lastScrollPositionRef.current;
}
