/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IContentLanguage } from "../models";
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from "../models";
// @ts-ignore
import { IGetComments404Response } from "../models";
// @ts-ignore
import { IUpdateUserDataEntryObjectsAccess400Response } from "../models";
// @ts-ignore
import { IUpdateUserDataEntryObjectsAccess404Response } from "../models";
// @ts-ignore
import { IUpdateUserDataEntryObjectsAccess409Response } from "../models";
// @ts-ignore
import { IUpdateUserOrgRoleRequest } from "../models";
// @ts-ignore
import { IUpdateUserRole403Response } from "../models";
// @ts-ignore
import { IUserDataEntryObjectsAccess } from "../models";
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Exports all users of the given organization
     * @summary Exports all users of the given organization
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IContentLanguage} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportOrganizationUsers: async (
      organizationId: string,
      language: IContentLanguage,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("exportOrganizationUsers", "organizationId", organizationId);
      // verify required parameter 'language' is not null or undefined
      assertParamExists("exportOrganizationUsers", "language", language);
      const localVarPath = `/organizations/{organizationId}/users/export`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a list of data entry objects the user has access to.
     * @summary Get a list of data entry objects the user has access to.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} userId The id of the user.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDataEntryObjectsAccess: async (
      organizationId: string,
      userId: string,
      recordingPeriodId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getUserDataEntryObjectsAccess", "organizationId", organizationId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getUserDataEntryObjectsAccess", "userId", userId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getUserDataEntryObjectsAccess", "recordingPeriodId", recordingPeriodId);
      const localVarPath =
        `/organizations/{organizationId}/users/{userId}/recording-periods/{recordingPeriodId}/data-entry-objects-access`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the user\'s access to the data entry objects.
     * @summary Update the user\'s access to the data entry objects.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} userId The id of the user.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IUserDataEntryObjectsAccess} [iUserDataEntryObjectsAccess]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserDataEntryObjectsAccess: async (
      organizationId: string,
      userId: string,
      recordingPeriodId: string,
      iUserDataEntryObjectsAccess?: IUserDataEntryObjectsAccess,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateUserDataEntryObjectsAccess", "organizationId", organizationId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("updateUserDataEntryObjectsAccess", "userId", userId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateUserDataEntryObjectsAccess", "recordingPeriodId", recordingPeriodId);
      const localVarPath =
        `/organizations/{organizationId}/users/{userId}/recording-periods/{recordingPeriodId}/data-entry-objects-access`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUserDataEntryObjectsAccess,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the role of a user inside the organization.
     * @summary Update the role of a user inside the organization.
     * @param {string} organizationId The id of the organization.
     * @param {string} userId The id of the user.
     * @param {IUpdateUserOrgRoleRequest} [iUpdateUserOrgRoleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserRole: async (
      organizationId: string,
      userId: string,
      iUpdateUserOrgRoleRequest?: IUpdateUserOrgRoleRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateUserRole", "organizationId", organizationId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("updateUserRole", "userId", userId);
      const localVarPath = `/organizations/{organizationId}/users/{userId}/role`
        .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUpdateUserOrgRoleRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * Exports all users of the given organization
     * @summary Exports all users of the given organization
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IContentLanguage} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportOrganizationUsers(
      organizationId: string,
      language: IContentLanguage,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportOrganizationUsers(
        organizationId,
        language,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get a list of data entry objects the user has access to.
     * @summary Get a list of data entry objects the user has access to.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} userId The id of the user.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserDataEntryObjectsAccess(
      organizationId: string,
      userId: string,
      recordingPeriodId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserDataEntryObjectsAccess>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataEntryObjectsAccess(
        organizationId,
        userId,
        recordingPeriodId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update the user\'s access to the data entry objects.
     * @summary Update the user\'s access to the data entry objects.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} userId The id of the user.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IUserDataEntryObjectsAccess} [iUserDataEntryObjectsAccess]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserDataEntryObjectsAccess(
      organizationId: string,
      userId: string,
      recordingPeriodId: string,
      iUserDataEntryObjectsAccess?: IUserDataEntryObjectsAccess,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserDataEntryObjectsAccess(
        organizationId,
        userId,
        recordingPeriodId,
        iUserDataEntryObjectsAccess,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates the role of a user inside the organization.
     * @summary Update the role of a user inside the organization.
     * @param {string} organizationId The id of the organization.
     * @param {string} userId The id of the user.
     * @param {IUpdateUserOrgRoleRequest} [iUpdateUserOrgRoleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserRole(
      organizationId: string,
      userId: string,
      iUpdateUserOrgRoleRequest?: IUpdateUserOrgRoleRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRole(
        organizationId,
        userId,
        iUpdateUserOrgRoleRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * Exports all users of the given organization
     * @summary Exports all users of the given organization
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {IContentLanguage} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportOrganizationUsers(
      organizationId: string,
      language: IContentLanguage,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportOrganizationUsers(organizationId, language, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a list of data entry objects the user has access to.
     * @summary Get a list of data entry objects the user has access to.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} userId The id of the user.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserDataEntryObjectsAccess(
      organizationId: string,
      userId: string,
      recordingPeriodId: string,
      options?: any,
    ): AxiosPromise<IUserDataEntryObjectsAccess> {
      return localVarFp
        .getUserDataEntryObjectsAccess(organizationId, userId, recordingPeriodId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the user\'s access to the data entry objects.
     * @summary Update the user\'s access to the data entry objects.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} userId The id of the user.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IUserDataEntryObjectsAccess} [iUserDataEntryObjectsAccess]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserDataEntryObjectsAccess(
      organizationId: string,
      userId: string,
      recordingPeriodId: string,
      iUserDataEntryObjectsAccess?: IUserDataEntryObjectsAccess,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUserDataEntryObjectsAccess(
          organizationId,
          userId,
          recordingPeriodId,
          iUserDataEntryObjectsAccess,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the role of a user inside the organization.
     * @summary Update the role of a user inside the organization.
     * @param {string} organizationId The id of the organization.
     * @param {string} userId The id of the user.
     * @param {IUpdateUserOrgRoleRequest} [iUpdateUserOrgRoleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserRole(
      organizationId: string,
      userId: string,
      iUpdateUserOrgRoleRequest?: IUpdateUserOrgRoleRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateUserRole(organizationId, userId, iUpdateUserOrgRoleRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
  /**
   * Exports all users of the given organization
   * @summary Exports all users of the given organization
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {IContentLanguage} language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  exportOrganizationUsers(
    organizationId: string,
    language: IContentLanguage,
    options?: AxiosRequestConfig,
  ): AxiosPromise<File>;

  /**
   * Get a list of data entry objects the user has access to.
   * @summary Get a list of data entry objects the user has access to.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} userId The id of the user.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getUserDataEntryObjectsAccess(
    organizationId: string,
    userId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IUserDataEntryObjectsAccess>;

  /**
   * Update the user\'s access to the data entry objects.
   * @summary Update the user\'s access to the data entry objects.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} userId The id of the user.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IUserDataEntryObjectsAccess} [iUserDataEntryObjectsAccess]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  updateUserDataEntryObjectsAccess(
    organizationId: string,
    userId: string,
    recordingPeriodId: string,
    iUserDataEntryObjectsAccess?: IUserDataEntryObjectsAccess,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Updates the role of a user inside the organization.
   * @summary Update the role of a user inside the organization.
   * @param {string} organizationId The id of the organization.
   * @param {string} userId The id of the user.
   * @param {IUpdateUserOrgRoleRequest} [iUpdateUserOrgRoleRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  updateUserRole(
    organizationId: string,
    userId: string,
    iUpdateUserOrgRoleRequest?: IUpdateUserOrgRoleRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
  /**
   * Exports all users of the given organization
   * @summary Exports all users of the given organization
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {IContentLanguage} language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public exportOrganizationUsers(
    organizationId: string,
    language: IContentLanguage,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .exportOrganizationUsers(organizationId, language, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of data entry objects the user has access to.
   * @summary Get a list of data entry objects the user has access to.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} userId The id of the user.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserDataEntryObjectsAccess(
    organizationId: string,
    userId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .getUserDataEntryObjectsAccess(organizationId, userId, recordingPeriodId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the user\'s access to the data entry objects.
   * @summary Update the user\'s access to the data entry objects.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} userId The id of the user.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IUserDataEntryObjectsAccess} [iUserDataEntryObjectsAccess]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserDataEntryObjectsAccess(
    organizationId: string,
    userId: string,
    recordingPeriodId: string,
    iUserDataEntryObjectsAccess?: IUserDataEntryObjectsAccess,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .updateUserDataEntryObjectsAccess(
        organizationId,
        userId,
        recordingPeriodId,
        iUserDataEntryObjectsAccess,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the role of a user inside the organization.
   * @summary Update the role of a user inside the organization.
   * @param {string} organizationId The id of the organization.
   * @param {string} userId The id of the user.
   * @param {IUpdateUserOrgRoleRequest} [iUpdateUserOrgRoleRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUserRole(
    organizationId: string,
    userId: string,
    iUpdateUserOrgRoleRequest?: IUpdateUserOrgRoleRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .updateUserRole(organizationId, userId, iUpdateUserOrgRoleRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
