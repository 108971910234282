import {
  $getRoot,
  $getSelection,
  CreateEditorArgs,
  LexicalEditor,
  SerializedEditorState,
  SerializedElementNode,
  SerializedLexicalNode,
} from "lexical";
import { BeautifulMentionNode, SerializedBeautifulMentionNode } from "lexical-beautiful-mentions";
import { createHeadlessEditor } from "@lexical/headless";
import {
  INetCeroSerializedBeautifulMentionsNode,
  NetceroSerializedBeautifulMentionsNodeData,
} from "./lexical.public-types";
import { COMMON_LEXICAL_NODES } from "./common-lexical-nodes";
import { DeferredPromise } from "../common";
import { plainToInstance } from "class-transformer";
import { ValidationUtilities } from "../validation";
import { ErrorHandler } from "lexical/LexicalEditor";
import { $generateNodesFromDOM } from "@lexical/html";

export class LexicalUtilities {
  /**
   * This method verifies that the passed value is a valid editor state object, including properly formatted messages.
   * In short, use this to ensure that an unknown value is an expected deserialized editor state for the application.
   * @param value
   */
  public static isValidEditorStateWithMentions(value: unknown) {
    if (typeof value !== "object" || value === null) {
      return false;
    }

    // be sure that the passed value is a lexical editor state at all
    const result = LexicalUtilities.isValidEditorState(value);

    if (!result) {
      return false;
    }

    // The "as" is fine as the structure is verified first anyway
    const mentions = LexicalUtilities.parseMentionsFromEditorState(value as SerializedEditorState);

    // Make sure that the data within the mentions is of the expected shape
    for (const mention of mentions) {
      const instance = plainToInstance(NetceroSerializedBeautifulMentionsNodeData, mention.data);
      const result = ValidationUtilities.validateWithDefaultsSync(instance);
      if (result.length !== 0) {
        return false;
      }
    }

    return true;
  }

  /**
   * This method attempts to parse the passed state and returns a boolean indicating whether said parsing was successful.
   * Any errors beside parsing errors are simply re-thrown.
   * @param state The state that should be inspected
   * @param editorConfig The remaining configuration, forwarded to `createHeadlessEditor`. If `onError` is provided, it is called before throwing the error.
   */
  public static isValidEditorState(state: object, editorConfig: CreateEditorArgs = {}): boolean {
    // This exists to ensure that only validation errors are reinterpreted
    class CustomError extends Error {}

    try {
      LexicalUtilities.parseEditorStateOrThrow(state, () => new CustomError(), editorConfig);
      return true;
    } catch (err) {
      // be sure to only reinterpret validation errors, not all kinds of errors
      if (err instanceof CustomError) {
        return false;
      }
      throw err;
    }
  }

  /**
   * This method parses the given editor state and throws an error in case it is invalid
   * @param state The state that should be inspected
   * @param errorFactory The error that should be thrown in case the configuration is invalid
   * @param editorConfig The remaining configuration, forwarded to `createHeadlessEditor`. If `onError` is provided, it is called before throwing the error.
   */
  public static parseEditorStateOrThrow(
    state: object,
    errorFactory: (error: Error) => Error,
    editorConfig: CreateEditorArgs = {},
  ) {
    const editor = LexicalUtilities.createHeadlessEditor((error) => {
      throw errorFactory(error);
    }, editorConfig);

    const editorState = editor.parseEditorState(state as SerializedEditorState);
    editor.setEditorState(editorState);

    return { editor, editorState };
  }

  /**
   * This method takes an HTML string and loads it into a newly created headless editor (see `createHeadlessEditor`)
   * @param html The HTML to convert
   */
  public static async parseNodesFromHtmlHeadless(html: string) {
    const editor = LexicalUtilities.createHeadlessEditor();

    // Get correct dom object
    let document: Document;
    if (process.versions.node) {
      // Handle nodejs - NOT Browser environment
      const { JSDOM } = await import("jsdom");
      const dom = new JSDOM(html);
      document = dom.window.document;
    } else {
      // Handle in Browser
      const parser = new DOMParser();
      document = parser.parseFromString(html, "text/html");
    }

    // Update the editor
    await LexicalUtilities.updateEditorAsync(editor, () => {
      const nodes = $generateNodesFromDOM(editor, document);
      $getRoot().select();
      const selection = $getSelection();
      selection!.insertNodes(nodes);
    });

    return editor;
  }

  /**
   * This method creates a new headless editor. It always includes the `COMMON_LEXICAL_NODES` in the `nodes` config array.
   * @param onError The error callback; called after calling `editorConfig.onError()`
   * @param editorConfig The remaining editor configuration
   */
  public static createHeadlessEditor(onError?: ErrorHandler, editorConfig: CreateEditorArgs = {}) {
    return createHeadlessEditor({
      ...editorConfig,
      nodes: [...(editorConfig.nodes ?? []), ...COMMON_LEXICAL_NODES],
      onError: (error) => {
        editorConfig.onError?.(error);
        onError?.(error);
      },
    });
  }

  /**
   * This method is used to determine whether a node is a node of the BeautifulMentions library.
   * It does determine this by inspecting the type.
   */
  private static isBeautifulMentionsNode(
    node: SerializedLexicalNode,
  ): node is SerializedBeautifulMentionNode {
    return node.type === BeautifulMentionNode.getType();
  }

  /**
   * This method is used to determine whether a node is a node of the SerializedElementNode class / category.
   * It does determine this by checking whether the node has a "children" property (does not verify whether there are any elements present)
   */
  private static canNodeHaveChildren(node: SerializedLexicalNode): node is SerializedElementNode {
    return "children" in node;
  }

  /**
   * This method retrieves all mention nodes of the given tree.
   */
  private static retrieveMentionNodes(
    node: SerializedLexicalNode,
    nodes: INetCeroSerializedBeautifulMentionsNode[],
  ) {
    if (LexicalUtilities.isBeautifulMentionsNode(node)) {
      nodes.push(node as unknown as INetCeroSerializedBeautifulMentionsNode);
    }

    if (LexicalUtilities.canNodeHaveChildren(node)) {
      for (const child of node.children) {
        LexicalUtilities.retrieveMentionNodes(child, nodes);
      }
    }
  }

  public static parseMentionsFromEditorState(editorState: SerializedEditorState) {
    const nodes: INetCeroSerializedBeautifulMentionsNode[] = [];
    LexicalUtilities.retrieveMentionNodes(editorState.root, nodes);
    return nodes;
  }

  /**
   * Executes the provided callback within the editors `read` method. Resolves once the read callback has been fired.
   * @param editor The editor to read
   * @param callback The callback to execute within `read`
   * @returns The result of the callback, wrapped in a promise that resolves once the callback has been called
   */
  public static readEditorAsync<T>(editor: LexicalEditor, callback: () => T): Promise<T> {
    const deferredPromise = new DeferredPromise<T>();

    editor.read(() => {
      const value = callback();
      deferredPromise.resolve(value);
    });

    return deferredPromise.promise;
  }

  /**
   * Executes the provided callback within the editors `update` method. Resolves once the update callback has been fired.
   * @param editor The editor
   * @param callback The callback to execute within `update`
   * @returns The result of the callback, wrapped in a promise that resolves once the callback has been called
   */
  public static updateEditorAsync<T>(editor: LexicalEditor, callback: () => T): Promise<T> {
    const deferredPromise = new DeferredPromise<T>();

    editor.update(() => {
      const value = callback();
      deferredPromise.resolve(value);
    });

    return deferredPromise.promise;
  }

  public static hasChanges(original: SerializedEditorState, current: SerializedEditorState) {
    return JSON.stringify(original) !== JSON.stringify(current);
  }

  /**
   * This method is used to determine whether the given editor state is empty.
   * @param editorState
   */
  public static isEmpty(editorState: SerializedEditorState) {
    // More than one child means that there is content
    if (editorState.root.children.length > 1) {
      return false;
    }

    // No children means that there is no content
    if (editorState.root.children.length === 0) {
      return true;
    }
    // Check if first child is empty
    const firstChild = editorState.root.children[0];
    if ("children" in firstChild && Array.isArray(firstChild.children)) {
      return firstChild.children.length === 0;
    }
    // Always return false
    return false;
  }
}
