import { FC, useMemo } from "react";
import {
  DataEntryObjectInputParameterValueDefinitionForTarget,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  ITarget,
} from "@netcero/netcero-core-api-client";
import { useTargetsQuery } from "../targets.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { MdrInputHandler } from "../../minimum-disclosure-requirements-common/components/mdr-input-handler.component";
import { useTranslation } from "react-i18next";
import { TargetsTable } from "./targets-table.component";

interface ITargetsListProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  rootDataEntryObjectId: string;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForTarget>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForTarget) => void;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  disabled?: boolean;
}

export const TargetsList: FC<ITargetsListProps> = (props) => {
  const targetsQuery = useTargetsQuery(props);

  return (
    <QueryWrapper
      query={targetsQuery}
      centerLoader
      build={(targets) => <TargetsListInternal targets={targets} {...props} />}
    />
  );
};

interface ITargetsListInternalProps extends ITargetsListProps {
  targets: ITarget[];
}

const TargetsListInternal: FC<ITargetsListInternalProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  rootDataEntryObjectId,
  targets,
  value,
  onChange,
  topicIdentifier,
  disabled,
}) => {
  const filteredTargets = useMemo(
    () => targets.filter((target) => target.associatedTopics.includes(topicIdentifier)),
    [targets, topicIdentifier],
  );

  const { t } = useTranslation("data_entry_object_target_component");

  return (
    <MdrInputHandler
      value={value}
      onChange={onChange}
      buttonPresentText={t("present_targets")}
      buttonNotPresentText={t("no_present_targets")}
      noticeChosenToNotDefine={t("notice_chosen_to_not_define_targets")}
      buttonChooseToAdoptText={t("targets_adopted")}
      disabled={disabled}
      mdrList={
        <TargetsTable
          targets={filteredTargets}
          topicIdentifier={topicIdentifier}
          organizationId={organizationId}
          recordingPeriodId={recordingPeriodId}
          dataEntryObjectId={dataEntryObjectId}
          rootDataEntryObjectId={rootDataEntryObjectId}
          onChange={onChange}
          disabled={disabled}
        />
      }
    />
  );
};
