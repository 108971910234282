import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  IDataEntryObject,
  IDataEntryObjectData,
  IInputParameterRecordingStructureGroupTHGSC,
} from "@netcero/netcero-core-api-client";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChartColorPalette } from "../../../theme/charts-theme";
import { FilterIcon, PointFilledIcon } from "../../common/constants/tabler-icon.constants";
import { GroupedCalculationResult, Predicate } from "@netcero/netcero-common";
import { EmissionsByScopeBarChart } from "./emissions-by-scope-bar-chart.component";
import { EmissionsUtilities } from "./emissions.utilities";

const DEFAULT_IS_CHILD_DEO_SELECTABLE = () => true;

interface IEmissionsByScopeBarsListProps {
  generalResult: GroupedCalculationResult;
  currentDataEntryObject: IDataEntryObject;
  structure: IInputParameterRecordingStructureGroupTHGSC;
  onSelectChildDataEntryObject: (dataEntryObject: IDataEntryObjectData) => void;
  isChildDataEntryObjectSelectable?: Predicate<IDataEntryObject>;
}

export const EmissionsByScopeBarsList: FC<IEmissionsByScopeBarsListProps> = ({
  generalResult,
  currentDataEntryObject,
  structure,
  onSelectChildDataEntryObject,
  isChildDataEntryObjectSelectable = DEFAULT_IS_CHILD_DEO_SELECTABLE,
}) => {
  const { t } = useTranslation("emissions_by_scope_bar_charts");

  /** Totals for Scopes */
  const totalResult = useMemo(
    () =>
      EmissionsUtilities.getEmissionScopesSumsForResultAndGroup(
        generalResult,
        currentDataEntryObject,
        structure,
        true, // Include all child DEOs
      ),
    [currentDataEntryObject, generalResult, structure],
  );

  /** Scope Results by DEO. Index 0 is Root DEO (only values directly recorded for it) */
  const scopeSumsByDataEntryObject = useMemo(() => {
    const rootDeoScopeSums = EmissionsUtilities.getEmissionScopesSumsForResultAndGroup(
      generalResult,
      currentDataEntryObject,
      structure,
      false, // Only Root DEO itself
    );
    const rootDeoResult = {
      isRoot: true,
      isSelectable: false, // Root DEO is never selectable
      scopeResult: rootDeoScopeSums,
      dataEntryObject: currentDataEntryObject,
    };

    const childrenResults = currentDataEntryObject.children.map((child) => {
      const childDeoScopeSums = EmissionsUtilities.getEmissionScopesSumsForResultAndGroup(
        generalResult,
        child,
        structure,
        true, // Include all children of child
      );

      return {
        isRoot: false,
        isSelectable: isChildDataEntryObjectSelectable(child),
        scopeResult: childDeoScopeSums,
        dataEntryObject: child,
      };
    });

    return [rootDeoResult, ...childrenResults];
  }, [generalResult, currentDataEntryObject, structure, isChildDataEntryObjectSelectable]);

  const sortedDEOScopeSums = useMemo(
    () =>
      scopeSumsByDataEntryObject.sort((a, b) =>
        b.isRoot ? 1 : a.isRoot ? -1 : b.scopeResult.total - a.scopeResult.total,
      ),
    [scopeSumsByDataEntryObject],
  );

  // Filters

  const filterButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [shownDEOs, setShownDEOs] = useState<string[]>([]);

  // Reset shown DEOs when results change
  useEffect(() => {
    setShownDEOs(
      sortedDEOScopeSums
        .filter((result) => result.scopeResult.total !== 0)
        .map((result) => result.dataEntryObject.id),
    );
  }, [sortedDEOScopeSums]);

  const shownDEOScopeSums = useMemo(
    () => sortedDEOScopeSums.filter((result) => shownDEOs.includes(result.dataEntryObject.id)),
    [sortedDEOScopeSums, shownDEOs],
  );

  const handleShowFilterMenu = () => {
    setShowFilterMenu(true);
  };
  const handleCloseFilterMenu = () => {
    setShowFilterMenu(false);
  };

  const hasAnyEmissions = useMemo(
    () => scopeSumsByDataEntryObject.some((result) => result.scopeResult.total !== 0),
    [scopeSumsByDataEntryObject],
  );

  return (
    <Box display="flex" gap={4}>
      {/* Legend */}
      <Box pt={2} pr={4}>
        <Box position="sticky" top={16}>
          <Box display="flex" flexDirection="column" gap={2}>
            {["scope1", "scope2", "scope3"].map((scope, index) => (
              <Box key={scope} display="flex" alignItems="center" gap={2}>
                <SvgIcon sx={{ color: ChartColorPalette[index % ChartColorPalette.length] }}>
                  <PointFilledIcon />
                </SvgIcon>
                <Typography variant="body1" component="span">
                  {t(`title_${scope}`)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Divider orientation="vertical" flexItem />

      {/* Bar Charts */}
      <Box pt={2} flex={1}>
        {/* Filter */}
        <Box mb={4}>
          <Button onClick={handleShowFilterMenu} ref={filterButtonRef}>
            <Badge
              color="primary"
              badgeContent={
                shownDEOs.length !== sortedDEOScopeSums.length ? shownDEOs.length : null
              }
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              showZero
            >
              <SvgIcon sx={{ mr: 1 }}>
                <FilterIcon />
              </SvgIcon>
            </Badge>
            {t("button_filter")}
          </Button>

          {/* Empty Message */}
          {!hasAnyEmissions && (
            <Typography textAlign="center" mt={2}>
              {t("no_deo_with_emissions_notice")}
            </Typography>
          )}

          <Menu
            open={showFilterMenu}
            onClose={handleCloseFilterMenu}
            anchorEl={filterButtonRef.current}
          >
            <FormGroup sx={{ px: 2 }}>
              {/* <FormControlLabel control={<Checkbox checked disabled />} label={t("title_total")} /> */}
              {sortedDEOScopeSums.map((result) => (
                <FormControlLabel
                  key={result.dataEntryObject.id}
                  control={
                    <Checkbox
                      checked={shownDEOs.includes(result.dataEntryObject.id)}
                      onChange={(_, checked) => {
                        if (checked) {
                          setShownDEOs((prev) => [...prev, result.dataEntryObject.id]);
                        } else {
                          setShownDEOs((prev) =>
                            prev.filter((id) => id !== result.dataEntryObject.id),
                          );
                        }
                      }}
                    />
                  }
                  label={
                    result.dataEntryObject.name +
                    (result.scopeResult.total === 0 ? ` ${t("deo_result_no_emissions")}` : "")
                  }
                />
              ))}
            </FormGroup>
          </Menu>
        </Box>

        {/* Bar Charts */}
        <Box>
          <Grid container spacing={2}>
            {/* Totals Result */}
            {hasAnyEmissions && (
              <Grid item xs={12} md={6} lg={4} xl={3} display="flex" justifyContent="center">
                <EmissionsByScopeBarChart title={t("title_total")} scopesResult={totalResult} />
              </Grid>
            )}
            {/* DEO Results */}
            {shownDEOScopeSums.map((result) => (
              <Grid
                key={result.dataEntryObject.id}
                item
                xs={12}
                md={6}
                lg={4}
                xl={3}
                display="flex"
                justifyContent="center"
              >
                <EmissionsByScopeBarChart
                  title={result.dataEntryObject.name}
                  scopesResult={result.scopeResult}
                  onClickDataEntryObject={
                    result.isSelectable
                      ? () => onSelectChildDataEntryObject(result.dataEntryObject)
                      : undefined
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
