import {
  IGetTargetPathProgressResponse,
  IReferenceBudgetStoredRecordingPeriodWithoutDetailedValues,
} from "@netcero/netcero-core-api-client";
import { DateTime } from "luxon";
import { ITargetPathLabels } from "./target-path.public-types";
import { ResultPerCalculationKey } from "../calculation";
import { ObjectUtilities } from "../common/utilities/object.utilities";

export class TargetPathCalculations {
  public static generateNumbersBetween(start: number, end: number) {
    const result: number[] = [];

    for (let i = start; i <= end; i++) {
      result.push(i);
    }

    return result;
  }

  private static getAllYearsForRecordingPeriod(
    rp: IReferenceBudgetStoredRecordingPeriodWithoutDetailedValues,
  ) {
    return this.generateNumbersBetween(
      DateTime.fromISO(rp.startDate, { zone: "utc" }).year,
      DateTime.fromISO(rp.endDate, { zone: "utc" }).year,
    );
  }

  private static getAmountOfDaysInRecordingPeriod(
    rp: IReferenceBudgetStoredRecordingPeriodWithoutDetailedValues,
  ) {
    const start = DateTime.fromISO(rp.startDate, { zone: "utc" });
    const end = DateTime.fromISO(rp.endDate, { zone: "utc" });
    return end.diff(start, "days").days;
  }

  /**
   * CAREFUL: rp has to provide it's timestamps in UTC; daylight savings time is not handled / considered!
   * @param rp
   * @param year
   * @private
   */
  private static getDaysOfRecordingPeriodWithinYear(
    rp: IReferenceBudgetStoredRecordingPeriodWithoutDetailedValues,
    year: number,
  ) {
    const startOfYear = DateTime.fromObject({ year: year, day: 1, month: 1 }, { zone: "utc" });
    const startOfNextYear = DateTime.fromObject(
      { year: year + 1, day: 1, month: 1 },
      { zone: "utc" },
    );

    const start = DateTime.max(DateTime.fromISO(rp.startDate, { zone: "utc" }), startOfYear);
    const end = DateTime.min(DateTime.fromISO(rp.endDate, { zone: "utc" }), startOfNextYear);

    return end.diff(start, "days").days;
  }

  public static computeValuesPerYear(
    years: ITargetPathLabels,
    { progress }: IGetTargetPathProgressResponse,
  ) {
    const recordingPeriodsPerYear: Record<
      number,
      IReferenceBudgetStoredRecordingPeriodWithoutDetailedValues[]
    > = {};

    for (const recordingPeriod of Object.values(
      ObjectUtilities.objectToRecord<IReferenceBudgetStoredRecordingPeriodWithoutDetailedValues>(
        progress,
      ),
    )) {
      const years = this.getAllYearsForRecordingPeriod(recordingPeriod);
      years.forEach((y) => {
        if (recordingPeriodsPerYear[y] === undefined) {
          recordingPeriodsPerYear[y] = [];
        }
        recordingPeriodsPerYear[y].push(recordingPeriod);
      });
    }

    const emissionsPerYear: Record<number, ResultPerCalculationKey> = {};

    for (const year of years.years) {
      const emissionsForThisYear: ResultPerCalculationKey = {};
      const recordingPeriodsForYear = recordingPeriodsPerYear[year] ?? [];

      for (const rp of recordingPeriodsForYear) {
        const days = this.getDaysOfRecordingPeriodWithinYear(rp, year);
        const totalDays = this.getAmountOfDaysInRecordingPeriod(rp);
        const percentageOfDays = days / totalDays;

        for (const [key, value] of Object.entries(
          ObjectUtilities.objectToRecord<number>(rp.total),
        )) {
          if (emissionsForThisYear[key] === undefined) {
            emissionsForThisYear[key] = 0;
          }

          emissionsForThisYear[key]! += value * percentageOfDays;
        }
      }

      emissionsPerYear[year] = emissionsForThisYear;
    }

    return emissionsPerYear;
  }
}
