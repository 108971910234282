import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useStakeholdersQuery } from "../../modules/stakeholder/stakeholder.queries";
import { StakeholdersConfiguration } from "../../modules/stakeholder/stakeholders-configuration.component";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { useTranslation } from "react-i18next";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";
import { PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT } from "../../modules/common/components/app-shell-wrapper.component";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";

interface IStakeholderConfigurationPageProps {}
export const StakeholderConfigurationPage: FC<IStakeholderConfigurationPageProps> = () => {
  const { organizationId } = useParams();
  const { t } = useTranslation("side_bar_nav_items");

  const breadCrumbItems: IBreadCrumb[] = [
    { label: t("configuration"), to: NavItemUrlUtilities.getConfigurationPageUrl(organizationId!) },
    { label: t("configuration_stakeholder") },
  ];

  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/stakeholder`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration/stakeholder`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/stakeholder`);
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const stakeholderQuery = useStakeholdersQuery(organizationId);

  return (
    <Box>
      <AppBreadCrumbs items={breadCrumbItems} />
      <QueryWrapper
        query={stakeholderQuery}
        loadingOverride={() => (
          <CenteredCircularProgress minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT} />
        )}
        build={(stakeholder) => (
          <StakeholdersConfiguration
            organizationId={organizationId ?? ""}
            stakeholdersResponse={stakeholder}
          />
        )}
      />
    </Box>
  );
};
