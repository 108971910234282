import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { LS_DATA_ENTRY_OBJECT_ID_KEY } from "../common/constants/localstorage.constants";
import { useLocalStorage } from "@uidotdev/usehooks";

/**
 * Hook to get the last used data entry object id for an organization.
 * @param recordingPeriodId
 */
export const useLastUsedDataEntryObjectId = (recordingPeriodId: string) => {
  const [lastUsedDataEntryObjects] = useLocalStorage<Record<string, string>>(
    LS_DATA_ENTRY_OBJECT_ID_KEY,
    {},
  );

  const lastUsedDeoId: string | null = useMemo(
    () => lastUsedDataEntryObjects[recordingPeriodId],

    [lastUsedDataEntryObjects, recordingPeriodId],
  );

  return lastUsedDeoId ?? null;
};

/**
 * Hook to automatically update the last used data entry object id in local storage on changes.
 * @param recordingPeriodId
 * @param dataEntryObjectId
 */
export const useUpdateLastUsedDataEntryObjectIdLocalStorage = (
  recordingPeriodId: string,
  dataEntryObjectId: string,
) => {
  const [lastUsedDataEntryObjectIds, setLastUsedDataEntryObjectIds] = useLocalStorage<
    Record<string, string>
  >(LS_DATA_ENTRY_OBJECT_ID_KEY, {});

  useEffect(() => {
    if (!recordingPeriodId || !dataEntryObjectId) {
      return;
    }

    if (lastUsedDataEntryObjectIds[recordingPeriodId] === dataEntryObjectId) {
      return;
    }

    setLastUsedDataEntryObjectIds((prev) => ({
      ...prev,
      [recordingPeriodId]: dataEntryObjectId,
    }));
  }, [
    recordingPeriodId,
    lastUsedDataEntryObjectIds,
    setLastUsedDataEntryObjectIds,
    dataEntryObjectId,
  ]);
};

/**
 * Hook to get the last used data entry object id from the router parameters.
 */
export const useLastUsedDataEntryObjectIdFromRouter = () => {
  const { recordingPeriodId } = useParams();
  return useLastUsedDataEntryObjectId(recordingPeriodId!);
};

/**
 * Hook to update the last used data entry object id in local storage from the router parameters.
 */
export const useUpdateLastUsedDataEntryObjectIdLocalStorageFromRouter = () => {
  const { recordingPeriodId, dataEntryObjectId } = useParams();
  useUpdateLastUsedDataEntryObjectIdLocalStorage(recordingPeriodId!, dataEntryObjectId!);
};

/**
 * Hook to get the last used data entry object id from the router parameters.
 * Updates to the local storage will also trigger updates of the return value.
 * (this hook is a full setup - updates only considered when occurring inside this hook - no instances of the update hook)
 */
export const useLastUsedDataEntryObjectSetupFromRouter = () => {
  const { recordingPeriodId } = useParams();

  return useLastUsedDataEntryObjectId(recordingPeriodId!);
};
