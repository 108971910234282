import { Box, Divider, MenuItem, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { CalendarIcon, SelectorIcon } from "../constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { useRecordingPeriodsQuery } from "../../recording-periods/recording-periods.queries";
import { useNavigate } from "react-router-dom";
import { TopBarPicker } from "./top-bar-picker.component";
import { InfoDialog } from "../dialogs/variants/info.dialog";
import { DeoPickerTopBar } from "./top-bar-deo-picker.component";
import { NavItemUrlUtilities } from "../utilities/nav-item-url.utilities";

interface ITopBarPickerSectionProps {
  organizationId?: string;
  recordingPeriodId?: string;
  dataEntryObjectId?: string;
  selectableDataEntryObjectIds?: string[];
  onChangeDataEntryObjectId?: (dataEntryObjectId: string) => void;
}

export const TopBarPickerSection: FC<ITopBarPickerSectionProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  selectableDataEntryObjectIds,
  onChangeDataEntryObjectId,
}) => {
  const { t } = useTranslation("top_bar");
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId);

  const currentRecordingPeriod = useMemo(
    () =>
      recordingPeriodsQuery.data?.recordingPeriods.find((item) => item.id === recordingPeriodId),
    [recordingPeriodId, recordingPeriodsQuery.data],
  );

  const handleSelectRecordingPeriod = useCallback(
    (recordingPeriodId: string) => {
      if (!organizationId || !recordingPeriodId) {
        return;
      }
      navigate(
        NavItemUrlUtilities.getRecordingPeriodDashboardPageUrl(organizationId, recordingPeriodId),
      );
      setIsOpen(false);
    },
    [navigate, organizationId],
  );

  const dialogContent = useMemo(() => {
    return (
      <Box>
        <Typography sx={{ mb: 2 }}>{t("change_recording_period_text")}</Typography>
        <Box sx={{ overflowY: "auto", maxHeight: "200px" }}>
          {recordingPeriodsQuery.data?.recordingPeriods.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              disabled={item.id === recordingPeriodId}
              onClick={() => handleSelectRecordingPeriod(item.id)}
            >
              {item.name}
            </MenuItem>
          ))}
        </Box>
      </Box>
    );
  }, [
    t,
    recordingPeriodsQuery.data?.recordingPeriods,
    recordingPeriodId,
    handleSelectRecordingPeriod,
  ]);

  return (
    <>
      <InfoDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={t("recording_period")}
        content={dialogContent}
      />
      {/* Picker Section TODO: extend with || as soon as DEO Picker is implemented */}
      {currentRecordingPeriod && (
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{
            px: 2.5,
            py: 1,
            backgroundColor: (theme) => theme.palette.grey[50],
            borderRadius: 4,
            height: "50px",
            minWidth: "200px",
          }}
        >
          {currentRecordingPeriod && (
            <TopBarPicker
              label={t("recording_period")}
              value={currentRecordingPeriod?.name ?? ""}
              startIcon={<CalendarIcon size={24} opacity={0.54} />}
              arrowIcon={<SelectorIcon size={20} />}
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          {organizationId &&
            currentRecordingPeriod &&
            dataEntryObjectId &&
            onChangeDataEntryObjectId && (
              <>
                <Divider orientation="vertical" />
                <DeoPickerTopBar
                  organizationId={organizationId}
                  recordingPeriod={currentRecordingPeriod}
                  selectedDeoId={dataEntryObjectId}
                  selectableDeoIds={selectableDataEntryObjectIds}
                  onChange={onChangeDataEntryObjectId}
                />
              </>
            )}
        </Box>
      )}
    </>
  );
};
