import { loadIcons as iconifyLoadIcons } from "@iconify/react";
import {
  IDataEntryObject,
  IDataEntryObjectBaseObjectTypeEnum,
} from "@netcero/netcero-core-api-client";
import { TreeUtilities } from "@netcero/netcero-common";

const DEO_TYPE_ICONS: { [key in IDataEntryObjectBaseObjectTypeEnum]: string } = {
  // Note: Since not every icon is a Tabler icon, iconify is alright here
  // @marckornberger TODO: Move to iconify-icon.constants.tsx at some point in future
  [IDataEntryObjectBaseObjectTypeEnum.Location]: "fluent:location-16-filled",
  [IDataEntryObjectBaseObjectTypeEnum.Department]: "mingcute:department-fill",
  [IDataEntryObjectBaseObjectTypeEnum.Team]: "fluent:people-team-16-filled",
  [IDataEntryObjectBaseObjectTypeEnum.BusinessArea]: "tabler:businessplan",
  [IDataEntryObjectBaseObjectTypeEnum.Grouping]: "tabler:stack-2",
  [IDataEntryObjectBaseObjectTypeEnum.Organization]: "octicon:organization-16",
};

export class DataEntryObjectsAppUtilities {
  static async loadIcons() {
    return new Promise((resolve, reject) => {
      const iconsToLoad = Object.values(IDataEntryObjectBaseObjectTypeEnum).map((val) =>
        DataEntryObjectsAppUtilities.getIconForObjectType(val),
      );
      iconifyLoadIcons(iconsToLoad, (loaded, missing, pending) => {
        // Only resolve when all icons are loaded
        if (pending.length === 0) {
          resolve({ loaded, missing, pending });
        }
      });
    });
  }

  static getIconForObjectType(objectType: IDataEntryObjectBaseObjectTypeEnum): string {
    return DEO_TYPE_ICONS[objectType];
  }

  public static findDataEntryObjectById(
    data: IDataEntryObject,
    idToFind: string,
  ): IDataEntryObject | null {
    return TreeUtilities.getChildInTree(
      data,
      (node) => node.children,
      (node) => node.id === idToFind,
    );
  }

  public static getDataEntryObjectNameBasedOnId(
    data: IDataEntryObject,
    idToFind: string,
  ): string | undefined {
    const node = DataEntryObjectsAppUtilities.findDataEntryObjectById(data, idToFind);
    return node?.name;
  }
}
