import { FC, useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { requiresAuthentication } from "../../modules/authentication/requires-authentication.hoc";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { useOrganizations } from "../../modules/organizations/organizations.hook";
import { useLocalStorage } from "@uidotdev/usehooks";
import { LS_ORGANIZATION_ID_KEY } from "../../modules/common/constants/localstorage.constants";
import { AuthenticationUtilities } from "@netcero/netcero-common";
import { useUserContext } from "../../modules/user/user.context";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EnvironmentUtilities } from "../../modules/common/utilities/environment.utilities";
import { FormatTranslation } from "../../modules/common/components/format-translation.component";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";

export const OrganizationsLayout: FC = requiresAuthentication(() => {
  const { t } = useTranslation("organizations_layout");
  const organizations = useOrganizations();
  const currentOrganization = useCurrentOrganization();

  const { organizationId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization!.id,
            onChange: (organizationId) => {
              navigate(`/organizations/${organizationId}`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: location.pathname,
        items: sideBarOrganizationNavItems,
      },
    }),
    [currentOrganization, location.pathname, sideBarOrganizationNavItems, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLastUsedOrganizationId] = useLocalStorage(LS_ORGANIZATION_ID_KEY, "");

  useEffect(() => {
    if (!organizations.length || !organizationId) {
      return;
    }

    const organizationIds = organizations.map((org) => org.id);
    if (!organizationIds.includes(organizationId)) {
      console.error(`User is not allowed to access organization ${organizationId}`);
      navigate("/organizations");
      return;
    }

    // If the organization was found, set the document title to NetCero | {current organization display name}
    if (currentOrganization) {
      document.title = `NetCero | ${currentOrganization.name}`;
    }
  }, [currentOrganization, navigate, organizationId, organizations]);

  useEffect(() => {
    if (organizationId) {
      setLastUsedOrganizationId(organizationId);
    }
  }, [organizationId, setLastUsedOrganizationId]);

  const { user } = useUserContext();

  if (
    user &&
    organizationId &&
    AuthenticationUtilities.isOrganizationDisabled(user.jwtPayload, organizationId)
  ) {
    return (
      <Typography variant="h3" textAlign="center" mt={10}>
        <FormatTranslation
          t={t}
          i18nKey="organization_disabled"
          components={{
            link: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a href={`mailto:${EnvironmentUtilities.SUPPORT_EMAIL}`} style={{ color: "inherit" }}>
                {EnvironmentUtilities.SUPPORT_EMAIL}
              </a>
            ),
          }}
        />
      </Typography>
    );
  }

  return <Outlet />;
});
