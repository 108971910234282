import { Box } from "@mui/material";
import { IDataEntryObject, IDMAConfiguration } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorTextComponent } from "../../modules/common/components/error-text.component";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { DMADashboardOverview } from "../../modules/double-materiality-assessment-dashboard/dma-dashboard-overview.component";
import {
  useDMAConfigurationQuery,
  useESRSTopicsQuery,
} from "../../modules/double-materiality-assessment/dma.queries";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import {
  DeoDmaDashboardContextProvider,
  IDeoDmaDashboardContextData,
} from "../../modules/double-materiality-assessment-dashboard/deo-dma-dashboard.context";
import { PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT } from "../../modules/common/components/app-shell-wrapper.component";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";

export const DMADashboardPage: FC = () => {
  const { organizationId, recordingPeriodId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("double_materiality_assessment_page");

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: {
        organizationId: organizationId ?? "",
        onChange: (organizationId) => {
          navigate(`/organizations/${organizationId}`);
        },
      },
      navigationItems: {
        activePath: NavItemUrlUtilities.getDMAPageUrl(organizationId!, recordingPeriodId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, recordingPeriodId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: {
        organizationId: organizationId ?? "",
        onChange: (organizationId) => {
          navigate(`/organizations/${organizationId}`);
        },
      },
      recordingPeriodId: recordingPeriodId,
    }),
    [organizationId, recordingPeriodId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  const dmaConfigurationQuery = useDMAConfigurationQuery(organizationId!, recordingPeriodId!);

  return (
    <Box>
      <QueryWrapper
        query={organizationStructureQuery}
        loadingOverride={() => (
          <CenteredCircularProgress minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT} />
        )}
        build={(organizationStructureResponse) => (
          <OrganizationStructureDraftWrapper
            organizationId={organizationId!}
            recordingPeriodId={recordingPeriodId!}
            organizationStructure={organizationStructureResponse}
            build={(rootDataEntryObject) => (
              <QueryWrapper
                query={dmaConfigurationQuery}
                loadingOverride={() => (
                  <CenteredCircularProgress minHeight={PAGE_CIRCULAR_PROGRESS_MIN_HEIGHT} />
                )}
                build={(dmaConfiguration) => {
                  return dmaConfiguration.type === "does-not-exist" ? (
                    <Box>{t("no_dma_configuration_present")}</Box>
                  ) : (
                    <DMADashboardForRootDEO
                      organizationId={organizationId!}
                      recordingPeriodId={recordingPeriodId!}
                      dataEntryObject={rootDataEntryObject}
                      dmaConfiguration={dmaConfiguration.configuration}
                    />
                  );
                }}
              />
            )}
          />
        )}
      />
    </Box>
  );
};

// Internal Component

interface IDMADashboardForRootDEOProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObject: IDataEntryObject;
  dmaConfiguration: IDMAConfiguration;
}

const DMADashboardForRootDEO: FC<IDMADashboardForRootDEOProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObject,
  dmaConfiguration,
}) => {
  const { t } = useTranslation("double_materiality_assessment_page");

  const doubleMaterialityCategoriesQuery = useESRSTopicsQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObject.id,
  );

  const isLoading = useMemo(
    () => doubleMaterialityCategoriesQuery.isLoading,
    [doubleMaterialityCategoriesQuery.isLoading],
  );

  const queriesError = useMemo(
    () => doubleMaterialityCategoriesQuery.error,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [doubleMaterialityCategoriesQuery.isError],
  );

  const deoDmaDashboardContextValue: IDeoDmaDashboardContextData = useMemo(() => {
    return {
      organizationId: organizationId!,
      recordingPeriodId: recordingPeriodId!,
      dataEntryObject: dataEntryObject,
    };
  }, [dataEntryObject, organizationId, recordingPeriodId]);

  // Render Component (states)

  if (isLoading) {
    return <CenteredCircularProgress />;
  }

  if (queriesError) {
    return <ErrorTextComponent error={queriesError} />;
  }

  const esrsTopicsResponse = doubleMaterialityCategoriesQuery.data!;

  return esrsTopicsResponse.esrsTopics.length > 0 ? (
    <DeoDmaDashboardContextProvider value={deoDmaDashboardContextValue}>
      <DMADashboardOverview
        dmaConfiguration={dmaConfiguration}
        esrsTopics={esrsTopicsResponse.esrsTopics}
      />
    </DeoDmaDashboardContextProvider>
  ) : (
    <Box>{t("categories_empty")}</Box>
  );
};
