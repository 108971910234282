import { useCallback } from "react";
import { useGetUsersByOrgRoleQuery } from "../users.queries";
import { OrgRole } from "@netcero/netcero-common";

export function useGetOrgRoleOfUser(organizationId: string) {
  const { data: organizationAdminUsers } = useGetUsersByOrgRoleQuery(
    organizationId,
    OrgRole.ORGANIZATION_ADMIN,
  );
  const { data: objectAdminUsers } = useGetUsersByOrgRoleQuery(
    organizationId,
    OrgRole.OBJECT_ADMIN,
  );
  const { data: viewMemberUsers } = useGetUsersByOrgRoleQuery(organizationId, OrgRole.VIEW_MEMBERS);

  return useCallback(
    (userId: string | undefined | null) => {
      if (organizationAdminUsers?.find((user) => user.id === userId)) {
        return OrgRole.ORGANIZATION_ADMIN;
      } else if (objectAdminUsers?.find((user) => user.id === userId)) {
        return OrgRole.OBJECT_ADMIN;
      } else if (viewMemberUsers?.find((user) => user.id === userId)) {
        return OrgRole.VIEW_MEMBERS;
      }
      return null;
    },
    [organizationAdminUsers, objectAdminUsers, viewMemberUsers],
  );
}
