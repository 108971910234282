import { ValidatorOptions } from "class-validator/types/validation/ValidatorOptions";
import { ValidationError } from "class-validator/types/validation/ValidationError";
import { validate, validateSync } from "class-validator";

export class ValidationUtilities {
  public static readonly DefaultValidatorSettings: ValidatorOptions = {
    whitelist: true,
    forbidNonWhitelisted: true,
  };

  /**
   * Simple forwards the call to `class-validator` while applying the default validation parameters (constant DefaultValidatorSettings)
   * @param value
   * @param validatorOptions
   */
  public static validateWithDefaults(
    value: object,
    validatorOptions: ValidatorOptions = {},
  ): Promise<ValidationError[]> {
    return validate(value, {
      ...ValidationUtilities.DefaultValidatorSettings,
      ...validatorOptions,
    });
  }

  /**
   * Simple forwards the call to `class-validator` while applying the default validation parameters (constant DefaultValidatorSettings)
   * @param value
   * @param validatorOptions
   */
  public static validateWithDefaultsSync(
    value: object,
    validatorOptions: ValidatorOptions = {},
  ): ValidationError[] {
    return validateSync(value, {
      ...ValidationUtilities.DefaultValidatorSettings,
      ...validatorOptions,
    });
  }
}
