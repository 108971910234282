import { SerializedBeautifulMentionNode } from "lexical-beautiful-mentions";
import { UUID } from "../common";
import { BeautifulMentionsItemData } from "lexical-beautiful-mentions/BeautifulMentionsPluginProps";
import { IsUUID } from "class-validator";

export interface INetCeroSerializedBeautifulMentionsNode
  extends Omit<SerializedBeautifulMentionNode, "data"> {
  data: INetCeroSerializedBeautifulMentionsNodeData;
}

export interface INetCeroSerializedBeautifulMentionsNodeData {
  userId: UUID;
}

interface IBeautifulMentionsComplexItem {
  value: string;

  [key: string]: BeautifulMentionsItemData;
}

export interface INetceroBeautifulMentionsItem
  extends IBeautifulMentionsComplexItem,
    INetCeroSerializedBeautifulMentionsNodeData {}

export class NetceroSerializedBeautifulMentionsNodeData
  implements INetCeroSerializedBeautifulMentionsNodeData
{
  @IsUUID("4")
  public userId: string;
}
