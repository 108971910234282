import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Box, Menu, MenuItem, MenuItemProps, Tooltip } from "@mui/material";
import { DataEntryObjectInputParameterStatusIndicator } from "./data-entry-object-input-parameter-status-indicator.component";
import { IDataEntryObjectInputParameterStatus } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { ArrowRightIcon } from "../../common/constants/tabler-icon.constants";
import { useUserContext } from "../../user/user.context";
import { DataEntryObjectInputParameterExclusionDialogWithMutation } from "../dialogs/data-entry-object-input-parameter-exclusion.dialog";
import { ILocalDataEntryObjectInputParameter } from "../../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import { DataEntryObjectInputParameterEsrsDiscriminator } from "../data-entry-object-input-parameter.types";

interface IDataEntryObjectInputParameterStatusIndicatorWithMenuProps {
  type: DataEntryObjectInputParameterEsrsDiscriminator;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  recordingStructureId: string;
  dataEntryObjectInputParameter: ILocalDataEntryObjectInputParameter;
  onReset: VoidFunction;
  onSubmit: VoidFunction;
  onApprove: VoidFunction;
  onReject: VoidFunction;
  disabled?: boolean;
  parentIsExcluded?: boolean;
}

export const DataEntryObjectInputParameterStatusIndicatorWithMenu: FC<
  IDataEntryObjectInputParameterStatusIndicatorWithMenuProps
> = ({
  type,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  recordingStructureId,
  dataEntryObjectInputParameter,
  onReset,
  onSubmit,
  onApprove,
  onReject,
  disabled,
  parentIsExcluded,
}) => {
  const { user } = useUserContext();
  const excluded = !!dataEntryObjectInputParameter.exclude;
  const { status, responsibleUserId } = dataEntryObjectInputParameter;

  const { t } = useTranslation("data_entry_object_input_parameter_common");

  const statusIndicatorRef = useRef<HTMLDivElement>(null);

  const [showExclusionDialog, setShowExclusionDialog] = useState(false);
  const handleClickExclude = useCallback(() => setShowExclusionDialog(true), []);

  const [showMenu, setShowMenu] = useState(false);
  const forwardWithCloseMenu = useCallback(
    (cb: VoidFunction) => () => {
      setShowMenu(false);
      cb();
    },
    [],
  );

  const CurrentStatusTransition = useMemo(() => {
    switch (status) {
      case "in-progress":
        return StatusInProgressMenuContent;
      case "in-review":
        return StatusInReviewMenuContent;
      case "done":
        return StatusDoneMenuContent;
      default:
        return () => null;
    }
  }, [status]);

  const canExclude = useMemo(
    () =>
      !dataEntryObjectInputParameter.responsibleUserId ||
      dataEntryObjectInputParameter.responsibleUserId === user?.userProfile.id,
    [dataEntryObjectInputParameter.responsibleUserId, user],
  );

  const isExcludeAvailable = useMemo(() => status !== "done", [status]);

  return (
    <>
      {/* Exclusion Dialog */}
      <DataEntryObjectInputParameterExclusionDialogWithMutation
        open={showExclusionDialog}
        mode={type}
        onClose={() => setShowExclusionDialog(false)}
        dataEntryObjectInputParameter={dataEntryObjectInputParameter}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        dataEntryObjectId={dataEntryObjectId}
        recordingStructureId={recordingStructureId}
      />

      {/* Menu */}
      <Menu
        open={showMenu}
        anchorEl={statusIndicatorRef.current}
        onClose={() => setShowMenu(false)}
      >
        {!excluded && [
          /* State Transition only shown when not excluded */
          <CurrentStatusTransition
            key={0}
            responsibleUserId={responsibleUserId}
            onReset={forwardWithCloseMenu(onReset)}
            onSubmit={forwardWithCloseMenu(onSubmit)}
            onApprove={forwardWithCloseMenu(onApprove)}
            onReject={forwardWithCloseMenu(onReject)}
          />,
          /* Excluded Option */
          isExcludeAvailable && (
            <Tooltip
              key={1}
              title={canExclude ? null : t("notice_exclusion_only_possible_as_responsible")}
              placement="left"
            >
              <span>
                <MenuItem
                  onClick={forwardWithCloseMenu(handleClickExclude)}
                  disabled={!canExclude}
                  sx={{ gap: 2 }}
                >
                  {t("exclude_transition_name")}
                  <Box flex={1} />
                  <ArrowRightIcon />
                  <Box display="flex" justifyContent="end">
                    <DataEntryObjectInputParameterStatusIndicator excluded={true} />
                  </Box>
                </MenuItem>
              </span>
            </Tooltip>
          ),
        ]}
      </Menu>

      {/* Status */}
      <DataEntryObjectInputParameterStatusIndicator
        ref={statusIndicatorRef}
        status={status}
        excluded={excluded || parentIsExcluded}
        onClick={() => (excluded ? setShowExclusionDialog(true) : setShowMenu(true))}
        disabled={disabled}
        isMenuOpen={showMenu}
        shouldRenderChevronIcon={true}
      />
    </>
  );
};

interface IStatusMenuContentProps {
  responsibleUserId: string | undefined;
  onReset: VoidFunction;
  onSubmit: VoidFunction;
  onApprove: VoidFunction;
  onReject: VoidFunction;
  disabled?: boolean;
}

type MenuContentType = FC<IStatusMenuContentProps>;

const StatusInProgressMenuContent: MenuContentType = ({
  responsibleUserId,
  onSubmit,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_input_parameter_common");

  return (
    <MenuItem onClick={onSubmit} disabled={disabled} sx={{ gap: 2 }}>
      {t(
        responsibleUserId
          ? "transition_actions.submit_with_reviewer"
          : "transition_actions.submit_without_reviewer",
      )}
      <Box flex={1} />
      <ArrowRightIcon />
      <Box display="flex" justifyContent="end">
        <DataEntryObjectInputParameterStatusIndicator
          status={
            responsibleUserId
              ? IDataEntryObjectInputParameterStatus.InReview
              : IDataEntryObjectInputParameterStatus.Done
          }
        />
      </Box>
    </MenuItem>
  );
};

const StatusInReviewMenuContent: MenuContentType = ({
  responsibleUserId,
  onApprove,
  onReject,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_input_parameter_common");
  const { user } = useUserContext();

  const isResponsibleUser = useMemo(
    () => responsibleUserId && responsibleUserId === user?.userProfile?.id,
    [responsibleUserId, user],
  );

  const tooltip = useMemo(
    () =>
      !isResponsibleUser
        ? t("transition_actions.notice_only_responsible_person_can_approve_or_reject")
        : null,
    [isResponsibleUser, t],
  );

  return (
    <>
      {/* Approve Option */}
      <Tooltip title={tooltip} placement="right">
        <span>
          <MenuItem onClick={onApprove} disabled={!isResponsibleUser || disabled} sx={{ gap: 2 }}>
            {t("transition_actions.approve")}
            <Box flex={1} />
            <ArrowRightIcon />
            <Box display="flex" justifyContent="end">
              <DataEntryObjectInputParameterStatusIndicator
                status={IDataEntryObjectInputParameterStatus.Done}
              />
            </Box>
          </MenuItem>
        </span>
      </Tooltip>
      {/* Reject Option */}
      <Tooltip title={tooltip} placement="right">
        <span>
          <MenuItem onClick={onReject} disabled={!isResponsibleUser || disabled} sx={{ gap: 2 }}>
            {t("transition_actions.reject")}
            <Box flex={1} />
            <ArrowRightIcon />
            <Box display="flex" justifyContent="end">
              <DataEntryObjectInputParameterStatusIndicator
                status={IDataEntryObjectInputParameterStatus.InProgress}
              />
            </Box>
          </MenuItem>
        </span>
      </Tooltip>
    </>
  );
};

const StatusDoneMenuContent: MenuContentType = ({ onReset, disabled }) => {
  return <ResetStatusButton onClick={onReset} disabled={disabled} />;
};

const ResetStatusButton: FC<Pick<MenuItemProps, "onClick" | "disabled">> = ({
  onClick,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_input_parameter_common");

  return (
    <MenuItem onClick={onClick} disabled={disabled} sx={{ gap: 2 }}>
      {t("transition_actions.reset")}
      <Box flex={1} />
      <ArrowRightIcon />
      <Box display="flex" justifyContent="end">
        <DataEntryObjectInputParameterStatusIndicator
          status={IDataEntryObjectInputParameterStatus.InProgress}
        />
      </Box>
    </MenuItem>
  );
};
