import { FC, useCallback, useMemo } from "react";
import {
  IDataEntryObject,
  IDataEntryObjectInputParameterStatus,
  IOrganization,
} from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { OrganizationStructureBreadcrumbs } from "../../organization-structures/navigation/organization-structure-breadcrumbs.component";
import { FilterBar, getFilterConfigGenerator } from "../../filters/filter-bar.component";
import { useMapUserIdsToUsers } from "../../user/hooks/map-user-ids-to-users.hook";
import { EsrsFilters, StatusExcludedOption } from "../../filters/filter.constants";
import { IFilterConfig, FilterType } from "../../filters/filter.type";
import { useIsFeatureFlagEnabled } from "../../common/hooks/is-feature-flag-enabled.hook";
import { FeatureFlag } from "../../common/constants/feature-flags.constants";

interface IDeoEsrsFilterBarComponentProps {
  searchParams: URLSearchParams;
  setSearchParams: (searchParams?: URLSearchParams) => void;
  organizationStructure: IDataEntryObject;
  organization: IOrganization;
  dataEntryObject: IDataEntryObject;
  onChangeDataEntryObject: (dataEntryObjectId: string) => void;
  contributingUserIds: string[];
  responsibleUserIds: string[];
}

export const DeoEsrsFilterBarComponent: FC<IDeoEsrsFilterBarComponentProps> = ({
  searchParams,
  setSearchParams,
  organizationStructure,
  organization,
  dataEntryObject,
  onChangeDataEntryObject,
  contributingUserIds,
  responsibleUserIds,
}) => {
  const { t: tStatus } = useTranslation("data_entry_object_input_parameter_common");
  const { t: tFilter } = useTranslation("data_entry_object_filter");
  const { t: tBoolean } = useTranslation("boolean_input");

  // TODO: NC-XYZ Remove feature flag check
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  // Helper function to find selected values that are no longer in the available options
  const getUnavailableOptions = useCallback(
    (filterKey: EsrsFilters, availableIds: string[]) => {
      const params = searchParams.getAll(filterKey);
      return params.filter((value) => !availableIds.some((id) => id === value));
    },
    [searchParams],
  );

  const unavailableResponsible = useMemo(
    () => getUnavailableOptions(EsrsFilters.ResponsiblePerson, responsibleUserIds),
    [getUnavailableOptions, responsibleUserIds],
  );

  const unavailableContributing = useMemo(
    () => getUnavailableOptions(EsrsFilters.ContributingPeople, contributingUserIds),
    [getUnavailableOptions, contributingUserIds],
  );

  const [filterableContributingUsers] = useMapUserIdsToUsers(
    organization.id,
    [...contributingUserIds, ...unavailableContributing],
    undefined,
  );
  const [filterableResponsibleUsers] = useMapUserIdsToUsers(
    organization.id,
    [...responsibleUserIds, ...unavailableResponsible],
    undefined,
  );

  const contributors = useMemo(
    () =>
      filterableContributingUsers?.map((user) => ({
        value: user.id ?? "",
        name: `${user.firstName} ${user.lastName}`,
      })) || [],
    [filterableContributingUsers],
  );

  const responsibleUsers = useMemo(
    () =>
      filterableResponsibleUsers?.map((user) => ({
        value: user.id ?? "",
        name: `${user.firstName} ${user.lastName}`,
      })) || [],
    [filterableResponsibleUsers],
  );

  const statusOptions = useMemo(
    () => [
      ...Object.values(IDataEntryObjectInputParameterStatus).map((status) => ({
        name: tStatus(`status.${status}.name`),
        value: status,
      })),
      {
        name: tStatus("excluded_status"),
        value: StatusExcludedOption,
      },
    ],
    [tStatus],
  );

  const booleanOptions = useMemo(
    () => [
      { value: "true", name: tBoolean("value_true") },
      { value: "false", name: tBoolean("value_false") },
    ],
    [tBoolean],
  );

  const generateFilterConfig = useMemo(() => getFilterConfigGenerator(tFilter), [tFilter]);

  const filters: IFilterConfig[] = useMemo(
    () => [
      generateFilterConfig(EsrsFilters.ResponsiblePerson, {
        type: FilterType.SearchMenu,
        options: responsibleUsers,
        multiple: true,
      }),
      generateFilterConfig(EsrsFilters.ContributingPeople, {
        type: FilterType.SearchMenu,
        options: contributors,
        multiple: true,
      }),
      generateFilterConfig(EsrsFilters.Status, {
        type: FilterType.Select,
        options: statusOptions,
        multiple: true,
      }),
      generateFilterConfig(EsrsFilters.PhaseIn, {
        type: FilterType.Select,
        options: booleanOptions,
        multiple: true,
      }),
      generateFilterConfig(EsrsFilters.Optional, {
        type: FilterType.Select,
        options: booleanOptions,
        multiple: true,
      }),
    ],
    [generateFilterConfig, responsibleUsers, contributors, statusOptions, booleanOptions],
  );

  return (
    <FilterBar
      filters={filters}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      startSectionElement={
        !isFeatureFlagEnabled && (
          <OrganizationStructureBreadcrumbs
            organizationStructure={organizationStructure}
            onChange={onChangeDataEntryObject}
            dataEntryObjectId={dataEntryObject.id}
          />
        )
      }
    />
  );
};
