import { Location } from "@remix-run/router/history";
import { UUID_REGEX } from "@netcero/netcero-common";

export class RouterUtilities {
  /**
   * Reads the UUID from the location fragment.
   * This wrapper is necessary to avoid the keycloak fragment information in the location.
   * @param location
   */
  public static readUuidFromLocationFragment(location: Location): string | null {
    const fragment = location.hash ? location.hash.slice(1, 37) : null;
    return fragment && UUID_REGEX.test(fragment) ? fragment : null;
  }
}
