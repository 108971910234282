import {
  AppModule,
  IJwtOrganizationInfo,
  IJwtPayload,
  OrgRole,
} from "./authentication.public-types";
import { UUID } from "../common";
import { ORG_ROLES_ASCENDING, ORG_ROLES_DESCENDING } from "./authentication.constants";

export class AuthenticationUtilities {
  public static getNetCeroOrganizationIds(jwtPayload: IJwtPayload) {
    return AuthenticationUtilities.getNetCeroOrganizations(jwtPayload).map((org) => org.id);
  }

  public static getNetCeroOrganizations(jwtPayload: IJwtPayload) {
    return Object.values(jwtPayload.organization_mapping).map((value) => ({
      id: value.attributes.netcero_id[0],
      name: value.name,
    }));
  }

  /**
   * Extracts the Keycloak organization ID from the JWT payload for the given NetCero organization ID.
   * @param jwtPayload The JWT payload.
   * @param netCeroOrganizationId The NetCero organization ID.
   * @returns The Keycloak organization ID or null if not found.
   */
  public static findKeycloakOrganizationIdForNetCeroOrganizationId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ): UUID | null {
    const organizationMapping = jwtPayload?.organization_mapping;
    if (!organizationMapping) {
      return null;
    }

    for (const [keycloakOrganizationId, mappingData] of Object.entries(organizationMapping)) {
      if (mappingData.attributes.netcero_id.includes(netCeroOrganizationId)) {
        return keycloakOrganizationId;
      }
    }

    return null;
  }

  public static getKeycloakOrganizationIdOrThrow(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const keycloakOrganizationId =
      AuthenticationUtilities.findKeycloakOrganizationIdForNetCeroOrganizationId(
        jwtPayload,
        netCeroOrganizationId,
      );

    if (!keycloakOrganizationId) {
      throw new Error("Organization not found in JWT");
    }

    return keycloakOrganizationId;
  }

  /**
   * Extracts the organization data from the JWT payload for the given NetCero organization ID.
   * @param jwtPayload The JWT payload.
   * @param netCeroOrganizationId The NetCero organization ID.
   */
  public static findOrganizationDataForNetCeroOrganizationId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizationMapping = jwtPayload?.organization_mapping;
    if (!organizationMapping) {
      return null;
    }

    return (
      Object.values(organizationMapping).find((mappingData) =>
        mappingData.attributes.netcero_id.includes(netCeroOrganizationId),
      ) ?? null
    );
  }

  public static getOrganizationForNetCeroId(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizations = Object.values(jwtPayload.organization_mapping);
    return (
      organizations.find((org) => org.attributes.netcero_id[0] === netCeroOrganizationId) ?? null
    );
  }

  /**
   * Get the module access for a specific organization
   * @param jwtPayload The JWT payload
   * @param netCeroOrganizationId The organization ID (netcero - NOT keycloak)
   */
  public static getOrganizationModuleAccessNetCero(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ): Record<AppModule, boolean> | null {
    const organization = AuthenticationUtilities.getOrganizationForNetCeroId(
      jwtPayload,
      netCeroOrganizationId,
    );

    if (!organization) {
      return null;
    }

    // Full Access Check
    if (AuthenticationUtilities.checkModuleAccessJwt(organization, "full_access")) {
      return {
        [AppModule.DMA]: true,
        [AppModule.ESRS]: true,
        [AppModule.CARBON_ACCOUNTING]: true,
      };
    }

    // Check Modules
    return {
      [AppModule.DMA]: AuthenticationUtilities.checkModuleAccessJwt(organization, "dma_access"),
      [AppModule.ESRS]: AuthenticationUtilities.checkModuleAccessJwt(organization, "esrs_access"),
      [AppModule.CARBON_ACCOUNTING]: AuthenticationUtilities.checkModuleAccessJwt(
        organization,
        "carbon_accounting_access",
      ),
    };
  }

  /**
   * Get the available modules for a specific organization as an array
   * @param jwtPayload
   * @param netCeroOrganizationId
   */
  public static getOrganizationAvailableModulesNetCero(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organizationAccess = AuthenticationUtilities.getOrganizationModuleAccessNetCero(
      jwtPayload,
      netCeroOrganizationId,
    );
    // Handle organization not in JWT
    if (!organizationAccess) {
      return null;
    }
    // Create Array with available modules
    return (Object.entries(organizationAccess) as [AppModule, boolean][])
      .filter(([, hasAccess]) => hasAccess)
      .map(([key]) => key);
  }

  public static hasOrganizationFullAccessNetCero(
    jwtPayload: IJwtPayload,
    netCeroOrganizationId: string,
  ) {
    const organization = AuthenticationUtilities.getOrganizationForNetCeroId(
      jwtPayload,
      netCeroOrganizationId,
    );
    return organization
      ? AuthenticationUtilities.checkModuleAccessJwt(organization, "full_access")
      : null;
  }

  /**
   * Check if the organization has access to a specific module
   * @param orgInfo The organization info
   * @param key The key to check
   */
  private static checkModuleAccessJwt(
    orgInfo: IJwtOrganizationInfo,
    key: keyof Omit<IJwtOrganizationInfo["attributes"], "netcero_id" | "deo_count_max">,
  ) {
    return orgInfo.attributes[key]?.[0] === "true";
  }

  /**
   * Get the OrgRole of the user
   * @param jwtPayload The JWT payload
   * @param netCeroOrganizationId The organization ID (netcero - NOT keycloak)
   */
  public static getOrgRoleOfUser(
    jwtPayload: IJwtPayload | undefined,
    netCeroOrganizationId: string,
  ): OrgRole | null {
    if (!jwtPayload) {
      return null;
    }

    const keycloakOrganizationId =
      AuthenticationUtilities.findKeycloakOrganizationIdForNetCeroOrganizationId(
        jwtPayload,
        netCeroOrganizationId,
      );

    if (!keycloakOrganizationId) {
      return null;
    }

    const organization = jwtPayload.organizations[keycloakOrganizationId];

    if (!organization) {
      return null;
    }

    return AuthenticationUtilities.getHighestOrgRole(organization.roles);
  }

  /**
   * Get the available OrgRole options for the user
   * @param executingUserRole The role of the user executing the request
   * @param currentTargetUserRole The role of the user that is the target of the role update request
   */
  public static getOrgRoleUpdateOptions(
    executingUserRole: OrgRole | null,
    currentTargetUserRole: OrgRole | null,
  ): OrgRole[] {
    // No executing user role is handled further down by the fact that the user can
    // only assign roles that are lower or equal to his own role. No role means no options.

    const [executingUserRoleLevel, currentTargetUserRoleLevel] =
      AuthenticationUtilities.mapRolesToAscendingPermissionLevels(
        executingUserRole,
        currentTargetUserRole,
      );

    // Handle executing user has less privileges than the target user (cannot update)
    if (executingUserRoleLevel < currentTargetUserRoleLevel) {
      return [];
    }

    // Get all roles that are lower or equal to the executing user role
    const rolesExecutingUserCanAssign = ORG_ROLES_ASCENDING.slice(0, executingUserRoleLevel + 1);

    // Return no option when: Only single option is available and that option is already selected
    if (
      rolesExecutingUserCanAssign.length === 1 &&
      rolesExecutingUserCanAssign[0] === currentTargetUserRole
    ) {
      return [];
    }

    // Return normal options
    return rolesExecutingUserCanAssign;
  }

  public static isUserPartOfOrganization(jwtPayload: IJwtPayload, organizationId: string) {
    const userOrganizationIds = AuthenticationUtilities.getNetCeroOrganizationIds(jwtPayload);
    return userOrganizationIds.includes(organizationId);
  }

  /**
   * Helper function to get the highest available OrgRole from an array
   * @param roles The roles
   */
  public static getHighestOrgRole(roles: string[]): OrgRole | null {
    for (const role of ORG_ROLES_DESCENDING) {
      if (roles.includes(role)) {
        return role;
      }
    }
    return null;
  }

  public static isOrganizationDisabled(jwtPayload: IJwtPayload, organizationId: string) {
    const organization = AuthenticationUtilities.getOrganizationForNetCeroId(
      jwtPayload,
      organizationId,
    );

    return !!organization?.attributes.disabled?.includes("true");
  }

  /**
   * Get the permission levels of the given roles in ascending order.
   * Basically their index in the ORG_ROLES_ASCENDING array.
   * @param roles The roles. Null values will be mapped to -1.
   */
  public static mapRolesToAscendingPermissionLevels(...roles: (OrgRole | null)[]) {
    return roles.map((role) => (role ? ORG_ROLES_ASCENDING.indexOf(role) : -1));
  }
}
