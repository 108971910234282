import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HideChildrenInProductionAndNoFullAccessComponent } from "../../modules/common/components/hide-children-in-production-and-no-full-access.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { AuditLogIcon, IconSize } from "../../modules/common/constants/tabler-icon.constants";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ConfigurationSection } from "./configuration-section.component";
import { RequireFeatureFlag } from "../../modules/common/components/require-feature-flag.component";
import { FeatureFlag } from "../../modules/common/constants/feature-flags.constants";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";
import { HideFromNonOrganizationAdmins } from "../../modules/common/components/hide-from-non-organization-admins.component";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";

export const ConfigurationDashboardPage: FC = () => {
  const { t } = useTranslation("configuration_dashboard_page");
  const { t: tNavItems } = useTranslation("side_bar_nav_items");

  const { organizationId } = useParams();
  const navigate = useNavigate();

  const breadCrumbItems: IBreadCrumb[] = [
    {
      label: tNavItems("configuration"),
      to: NavItemUrlUtilities.getConfigurationPageUrl(organizationId!),
    },
  ];

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(NavItemUrlUtilities.getConfigurationPageUrl(orgId));
            },
          }
        : undefined,
      navigationItems: {
        activePath: NavItemUrlUtilities.getConfigurationPageUrl(organizationId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(NavItemUrlUtilities.getConfigurationPageUrl(orgId));
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  return (
    <Box maxWidth="100%" width="100%" mx="auto">
      <AppBreadCrumbs items={breadCrumbItems} />
      <Box display="flex" justifyContent="space-between" gap={2} mt={1}>
        <Typography variant="h1" mb={4}>
          {t("title")}
        </Typography>
        <HideFromNonOrganizationAdmins>
          <Link to={NavItemUrlUtilities.getAuditLoggingConfigurationPageUrl(organizationId!)}>
            <Tooltip title={t("title_audit_logging", { ns: "common" })}>
              <IconButton>
                <AuditLogIcon size={IconSize.Large} />
              </IconButton>
            </Tooltip>
          </Link>
        </HideFromNonOrganizationAdmins>
      </Box>
      {/* Organization */}
      {/* <ConfigurationOverviewSectionContainer>
        <Typography variant="h2">{t("title_organization")}</Typography>
        <Box display="flex" gap={2} mt={2}>
          <Typography flex={1}>{t("description_organization")}</Typography>
          <Link to={`/organizations/${organizationId}/configuration/organization`}>
            <Button variant="contained">{t("button_organization")}</Button>
          </Link>
        </Box>
      </ConfigurationOverviewSectionContainer>

      <Divider sx={{ my: 2 }} /> */}

      <HideFromNonOrganizationAdmins>
        <ConfigurationSection
          title={t("title_recording_periods")}
          description={t("description_recording_periods")}
          url={NavItemUrlUtilities.getConfigurationRecordingPeriodPageUrl(organizationId!)}
          buttonLabel={t("button_recording_periods")}
        />
        <Divider sx={{ my: 2 }} />
      </HideFromNonOrganizationAdmins>

      <ConfigurationSection
        title={t("title_stakeholder")}
        description={t("description_stakeholder")}
        url={NavItemUrlUtilities.getConfigurationStakeholderPageUrl(organizationId!)}
        buttonLabel={t("button_stakeholder")}
      />
      <Divider sx={{ my: 2 }} />

      <HideFromNonOrganizationAdmins>
        <HideChildrenInProductionAndNoFullAccessComponent>
          <ConfigurationSection
            title={t("title_distribution_criteria")}
            description={t("description_distribution_criteria")}
            url={NavItemUrlUtilities.getConfigurationDistributionCriteriaPageUrl(organizationId!)}
            buttonLabel={t("button_distribution_criteria")}
          />
          <Divider sx={{ my: 2 }} />
        </HideChildrenInProductionAndNoFullAccessComponent>
      </HideFromNonOrganizationAdmins>

      <ConfigurationSection
        title={t("title_sources")}
        description={t("description_sources")}
        url={NavItemUrlUtilities.getConfigurationSourcesPageUrl(organizationId!)}
        buttonLabel={t("button_sources")}
      />
      <Divider sx={{ my: 2 }} />

      <RequireFeatureFlag featureFlag={FeatureFlag.USER_MANAGEMENT}>
        <ConfigurationSection
          title={t("title_users")}
          description={t("description_users")}
          url={NavItemUrlUtilities.getConfigurationUsersPageUrl(organizationId!)}
          buttonLabel={t("button_users")}
        />
        <Divider sx={{ my: 2 }} />
      </RequireFeatureFlag>
    </Box>
  );
};
