import { FC } from "react";
import NetCeroLogoColorImage from "../../../assets/Logo_color.svg";
import NetCeroLogoWhiteImage from "../../../assets/Logo_white.svg";
import NetCeroLogoIconWhiteImage from "../../../assets/Symbol_white.svg";
import NetCeroLogoTextWhiteImage from "../../../assets/Text_white.svg";
import NetCeroLogoTextColorImage from "../../../assets/Text_color.svg";
import NetCeroLogoIconColorImage from "../../../assets/Symbol_color.svg";

export const NetCeroLogoColor: FC = () => {
  return <img src={NetCeroLogoColorImage} alt="NetCero" height={42} />;
};
export const NetCeroLogoWhite: FC = () => {
  return <img src={NetCeroLogoWhiteImage} alt="NetCero" height={42} />;
};
export const NetCeroLogoIconColor: FC = () => {
  return <img src={NetCeroLogoIconColorImage} alt="NetCero" height={42} />;
};
export const NetCeroLogoIconWhite: FC = () => {
  return <img src={NetCeroLogoIconWhiteImage} alt="NetCero" height={42} />;
};
export const NetCeroLogoTextColor: FC = () => {
  return <img src={NetCeroLogoTextColorImage} alt="NetCero" height={20} />;
};
export const NetCeroLogoTextWhite: FC = () => {
  return <img src={NetCeroLogoTextWhiteImage} alt="NetCero" height={20} />;
};
