import { FC, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { CAN_REDO_COMMAND, REDO_COMMAND } from "lexical";
import { LexicalPriority } from "../../lexical.constants";
import { RedoIcon } from "../../../common/constants/tabler-icon.constants";

interface IRedoButtonProps {
  disabled?: boolean;
}

export const RedoButton: FC<IRedoButtonProps> = ({ disabled }) => {
  const [editor] = useLexicalComposerContext();

  const [canRedo, setCanRedo] = useState(false);

  useEffect(() => {
    return editor.registerCommand(
      CAN_REDO_COMMAND,
      (payload) => {
        setCanRedo(payload);
        return false;
      },
      LexicalPriority.LOW,
    );
  }, [editor]);

  return (
    <IconButton
      size="small"
      onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
      disabled={!canRedo || disabled}
    >
      <RedoIcon />
    </IconButton>
  );
};
