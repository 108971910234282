import { PropsWithChildren } from "react";
import { OrgRole } from "@netcero/netcero-common";
import { useAuthenticatedUserOrganizationRole } from "../../user/hooks/authenticated-user-org-role.hook";

// TODO: somehow merge this with RequireOrganizationAdminOrgRoleLayout?
export const HideFromNonOrganizationAdmins: React.FC<PropsWithChildren> = ({ children }) => {
  const authenticatedUserOrgRole = useAuthenticatedUserOrganizationRole();

  return authenticatedUserOrgRole === OrgRole.ORGANIZATION_ADMIN ? children : null;
};
