import { OrgRole } from "@netcero/netcero-common";
import { TFunction } from "i18next";

export class OrgRoleUtilities {
  public static translationKeyByOrgRole: Record<OrgRole, string> = {
    [OrgRole.ORGANIZATION_ADMIN]: "organization_admin",
    [OrgRole.OBJECT_ADMIN]: "object_admin",
    [OrgRole.VIEW_MEMBERS]: "view_members",
  };

  public static descriptionKeyByOrgRole: Record<OrgRole, string> = {
    [OrgRole.ORGANIZATION_ADMIN]: "organization_admin_description",
    [OrgRole.OBJECT_ADMIN]: "object_admin_description",
    [OrgRole.VIEW_MEMBERS]: "view_members_description",
  };

  public static getOrgRoleTranslation(role: OrgRole, t: TFunction): string {
    return t(OrgRoleUtilities.translationKeyByOrgRole[role], { ns: "roles" });
  }

  public static getOrgRoleDescription(role: OrgRole, t: TFunction): string {
    return t(OrgRoleUtilities.descriptionKeyByOrgRole[role], { ns: "roles" });
  }
}
