import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import {
  ISideBarConfiguration as ISideBarConfigurationV1,
  ISideBarProps,
  SideBarV1,
} from "../components/side-bar-v1.component";
import { FeatureFlag } from "../constants/feature-flags.constants";
import {
  SideBarV2,
  ISideBarConfiguration as ISideBarConfigurationV2,
} from "../components/side-bar-v2.component";
import { useIsFeatureFlagEnabled } from "../hooks/is-feature-flag-enabled.hook";

// TODO: NC-XYZ Clean up - Remove ISideBarConfigurationV1 and remove V2 from name
interface ISideBarContext {
  sideBarConfiguration: ISideBarConfigurationV1 | ISideBarConfigurationV2;
  updateSideBarConfiguration: (
    sideBarConfiguration: ISideBarConfigurationV1 | ISideBarConfigurationV2,
  ) => void;
}
const InternalSideBarContext = createContext<ISideBarContext>({
  sideBarConfiguration: {},
  updateSideBarConfiguration: () => {},
});

interface ISideBarContextProps {
  children: ReactNode;
}
export const SideBarContext: FC<ISideBarContextProps> = ({ children }) => {
  const [sideBarConfiguration, updateSideBarConfiguration] = useState<
    ISideBarConfigurationV1 | ISideBarConfigurationV2
  >({});

  return (
    <InternalSideBarContext.Provider
      value={{
        sideBarConfiguration,
        updateSideBarConfiguration,
      }}
      children={children}
    />
  );
};

export const useSideBarContext = () => useContext(InternalSideBarContext);

type IContextBasedSideBarProps = Omit<ISideBarProps, "configuration">;
export const ContextBasedSideBar: FC<IContextBasedSideBarProps> = ({ ...props }) => {
  const { sideBarConfiguration } = useSideBarContext();

  // TODO: NC-XYZ Clean up - remove the ternary operator with V1 and only return V2
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);
  return isFeatureFlagEnabled ? (
    <SideBarV2 {...props} configuration={sideBarConfiguration} />
  ) : (
    <SideBarV1 {...props} configuration={sideBarConfiguration} />
  );
};

/**
 * Hook to update the Application SideBar configuration. This hook will also reset the SideBar configuration when the component unmounts.
 * @param sideBarConfiguration Configuration for the SideBars. Should probably be wrapped by a useMemo to avoid unnecessary re-renders or re-render loops.
 */
export const useCustomSideBarContent = (
  sideBarConfiguration: ISideBarConfigurationV1 | ISideBarConfigurationV2,
) => {
  const { updateSideBarConfiguration } = useSideBarContext();

  useEffect(() => {
    updateSideBarConfiguration(sideBarConfiguration);
  }, [sideBarConfiguration, updateSideBarConfiguration]);
};
