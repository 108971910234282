import { FC } from "react";
import { Box } from "@mui/material";

interface INumberBadgeInlineProps {
  count: number | null | undefined;
}

export const NumberBadgeInline: FC<INumberBadgeInlineProps> = ({ count }) => {
  return (
    !!count && (
      <Box
        borderRadius="9999px"
        ml={0.5}
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          height: 20,
          minWidth: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 0.5,
        }}
      >
        <Box fontSize={12} mt="-2%">
          {count}
        </Box>
      </Box>
    )
  );
};
