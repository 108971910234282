import { IInputParameterRecordingStructureBase } from "@netcero/netcero-core-api-client";

export class RecordingModelTemplateGroupsUtilities {
  public static readonly TOTAL_ESRS_RECORDING_STRUCTURES = 11;

  public static hasMissingEsrsRecordingStructures(
    recordingStructures: IInputParameterRecordingStructureBase[],
  ) {
    return (
      recordingStructures.length <
      RecordingModelTemplateGroupsUtilities.TOTAL_ESRS_RECORDING_STRUCTURES
    );
  }
}
