import { createContext, useContext } from "react";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";

export interface IDeoDmaDashboardContextData {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObject: IDataEntryObject;
}

const DeoDmaDashboardContext = createContext<IDeoDmaDashboardContextData | null>(null);

export const DeoDmaDashboardContextProvider = DeoDmaDashboardContext.Provider;

export const useDeoDmaDashboardContext = () => {
  const context = useContext(DeoDmaDashboardContext);
  if (!context) {
    throw new Error(
      "useDeoDmaDashboardContext must be used within a DeoDmaDashboardContextProvider",
    );
  }
  return context;
};
