/* tslint:disable */
/* eslint-disable */
/**
 * Phase Two Admin REST API
 * This is a REST API reference for the Phase Two Keycloak custom resources. These are extensions to the standard [Keycloak Admin REST API](https://www.keycloak.org/docs-api/17.0/rest-api/index.html).  ### Base URI format Paths specified in the documentation are relative to the the base URI. - Format: `https://<host>:<port>/auth/realms` - Example: `https://app.phasetwo.io/auth/realms`  ### Authentication Authentication is achieved by using the `Authentication: Bearer <token>` header in all requests. This is either the access token received from a normal authentication, or by a request directly to the OpenID Connect token endpoint.  It is recommended that you use a Keycloak Admin Client, such as [this one for Javascript](https://github.com/keycloak/keycloak-nodejs-admin-client), as they take care of authentication, getting an access token, and refreshing it when it expires.  #### Client credentials grant example ``` POST /auth/realms/test-realm/protocol/openid-connect/token Host: app.phasetwo.io Accept: application/json Content-type: application/x-www-form-urlencoded  grant_type=client_credentials&client_id=admin-cli&client_secret=fd649804-3a74-4d69-acaa-8f065c6b7da1 ```  #### Password grant example ``` POST /auth/realms/test-realm/protocol/openid-connect/token Host: app.phasetwo.io Accept: application/json Content-type: application/x-www-form-urlencoded  grant_type=password&username=uname@foo.com&password=pwd123AZY&client_id=admin-cli ```  ### SDKs Modern API libraries are available for several common languages. These are available as open source at the links below, or you can choose to generate your own using our [OpenAPI spec file](https://raw.githubusercontent.com/p2-inc/phasetwo-docs/main/openapi.yaml).  | Language | Library | | --- | --- | | Java (and other JVM langs) | https://github.com/p2-inc/phasetwo-java | | JavaScript/TypeScript | https://github.com/p2-inc/phasetwo-js | | Python | https://github.com/p2-inc/phasetwo-python |
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IOrganizationRoleRepresentation } from "../models";
// @ts-ignore
import { IUserRepresentation } from "../models";
/**
 * OrganizationRolesApi - axios parameter creator
 * @export
 */
export const OrganizationRolesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Check if a user has an organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkUserOrganizationRole: async (
      realm: string,
      orgId: string,
      name: string,
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("checkUserOrganizationRole", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("checkUserOrganizationRole", "orgId", orgId);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("checkUserOrganizationRole", "name", name);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("checkUserOrganizationRole", "userId", userId);
      const localVarPath = `/{realm}/orgs/{orgId}/roles/{name}/users/{userId}`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"name"}}`, encodeURIComponent(String(name)))
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new role for this organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganizationRole: async (
      realm: string,
      orgId: string,
      iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("createOrganizationRole", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("createOrganizationRole", "orgId", orgId);
      // verify required parameter 'iOrganizationRoleRepresentation' is not null or undefined
      assertParamExists(
        "createOrganizationRole",
        "iOrganizationRoleRepresentation",
        iOrganizationRoleRepresentation,
      );
      const localVarPath = `/{realm}/orgs/{orgId}/roles`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iOrganizationRoleRepresentation,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete this organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrganizationRole: async (
      realm: string,
      orgId: string,
      name: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("deleteOrganizationRole", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("deleteOrganizationRole", "orgId", orgId);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("deleteOrganizationRole", "name", name);
      const localVarPath = `/{realm}/orgs/{orgId}/roles/{name}`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"name"}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get role for this organization by name
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationRole: async (
      realm: string,
      orgId: string,
      name: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("getOrganizationRole", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("getOrganizationRole", "orgId", orgId);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("getOrganizationRole", "name", name);
      const localVarPath = `/{realm}/orgs/{orgId}/roles/{name}`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"name"}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get roles for this organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationRoles: async (
      realm: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("getOrganizationRoles", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("getOrganizationRoles", "orgId", orgId);
      const localVarPath = `/{realm}/orgs/{orgId}/roles`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get users with this organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserOrganizationRoles: async (
      realm: string,
      orgId: string,
      name: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("getUserOrganizationRoles", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("getUserOrganizationRoles", "orgId", orgId);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("getUserOrganizationRoles", "name", name);
      const localVarPath = `/{realm}/orgs/{orgId}/roles/{name}/users`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"name"}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Grant the specified user to the specified organization role
     * @summary Grant a user an organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    grantUserOrganizationRole: async (
      realm: string,
      orgId: string,
      name: string,
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("grantUserOrganizationRole", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("grantUserOrganizationRole", "orgId", orgId);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("grantUserOrganizationRole", "name", name);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("grantUserOrganizationRole", "userId", userId);
      const localVarPath = `/{realm}/orgs/{orgId}/roles/{name}/users/{userId}`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"name"}}`, encodeURIComponent(String(name)))
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Revoke the specified organization role from the specified user
     * @summary Revoke an organization role from a user
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeUserOrganizationRole: async (
      realm: string,
      orgId: string,
      name: string,
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("revokeUserOrganizationRole", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("revokeUserOrganizationRole", "orgId", orgId);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("revokeUserOrganizationRole", "name", name);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("revokeUserOrganizationRole", "userId", userId);
      const localVarPath = `/{realm}/orgs/{orgId}/roles/{name}/users/{userId}`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"name"}}`, encodeURIComponent(String(name)))
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update role for this organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganizationRole: async (
      realm: string,
      orgId: string,
      name: string,
      iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("updateOrganizationRole", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("updateOrganizationRole", "orgId", orgId);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("updateOrganizationRole", "name", name);
      // verify required parameter 'iOrganizationRoleRepresentation' is not null or undefined
      assertParamExists(
        "updateOrganizationRole",
        "iOrganizationRoleRepresentation",
        iOrganizationRoleRepresentation,
      );
      const localVarPath = `/{realm}/orgs/{orgId}/roles/{name}`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"name"}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iOrganizationRoleRepresentation,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationRolesApi - functional programming interface
 * @export
 */
export const OrganizationRolesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrganizationRolesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Check if a user has an organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkUserOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkUserOrganizationRole(
        realm,
        orgId,
        name,
        userId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a new role for this organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrganizationRole(
      realm: string,
      orgId: string,
      iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationRole(
        realm,
        orgId,
        iOrganizationRoleRepresentation,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete this organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationRole(
        realm,
        orgId,
        name,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get role for this organization by name
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrganizationRoleRepresentation>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationRole(
        realm,
        orgId,
        name,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get roles for this organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationRoles(
      realm: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<IOrganizationRoleRepresentation>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationRoles(
        realm,
        orgId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get users with this organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserOrganizationRoles(
      realm: string,
      orgId: string,
      name: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUserRepresentation>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOrganizationRoles(
        realm,
        orgId,
        name,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Grant the specified user to the specified organization role
     * @summary Grant a user an organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async grantUserOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.grantUserOrganizationRole(
        realm,
        orgId,
        name,
        userId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Revoke the specified organization role from the specified user
     * @summary Revoke an organization role from a user
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async revokeUserOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.revokeUserOrganizationRole(
        realm,
        orgId,
        name,
        userId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update role for this organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationRole(
        realm,
        orgId,
        name,
        iOrganizationRoleRepresentation,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OrganizationRolesApi - factory interface
 * @export
 */
export const OrganizationRolesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrganizationRolesApiFp(configuration);
  return {
    /**
     *
     * @summary Check if a user has an organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkUserOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      userId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .checkUserOrganizationRole(realm, orgId, name, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new role for this organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrganizationRole(
      realm: string,
      orgId: string,
      iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createOrganizationRole(realm, orgId, iOrganizationRoleRepresentation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete this organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteOrganizationRole(realm, orgId, name, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get role for this organization by name
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      options?: any,
    ): AxiosPromise<IOrganizationRoleRepresentation> {
      return localVarFp
        .getOrganizationRole(realm, orgId, name, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get roles for this organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationRoles(
      realm: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<Array<IOrganizationRoleRepresentation>> {
      return localVarFp
        .getOrganizationRoles(realm, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get users with this organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserOrganizationRoles(
      realm: string,
      orgId: string,
      name: string,
      options?: any,
    ): AxiosPromise<Array<IUserRepresentation>> {
      return localVarFp
        .getUserOrganizationRoles(realm, orgId, name, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Grant the specified user to the specified organization role
     * @summary Grant a user an organization role
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    grantUserOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      userId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .grantUserOrganizationRole(realm, orgId, name, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Revoke the specified organization role from the specified user
     * @summary Revoke an organization role from a user
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeUserOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      userId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .revokeUserOrganizationRole(realm, orgId, name, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update role for this organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} name organization role name
     * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOrganizationRole(
      realm: string,
      orgId: string,
      name: string,
      iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateOrganizationRole(realm, orgId, name, iOrganizationRoleRepresentation, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrganizationRolesApi - interface
 * @export
 * @interface OrganizationRolesApi
 */
export interface OrganizationRolesApiInterface {
  /**
   *
   * @summary Check if a user has an organization role
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApiInterface
   */
  checkUserOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    userId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Create a new role for this organization
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApiInterface
   */
  createOrganizationRole(
    realm: string,
    orgId: string,
    iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Delete this organization role
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApiInterface
   */
  deleteOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Get role for this organization by name
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApiInterface
   */
  getOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IOrganizationRoleRepresentation>;

  /**
   *
   * @summary Get roles for this organization
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApiInterface
   */
  getOrganizationRoles(
    realm: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Array<IOrganizationRoleRepresentation>>;

  /**
   *
   * @summary Get users with this organization role
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApiInterface
   */
  getUserOrganizationRoles(
    realm: string,
    orgId: string,
    name: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Array<IUserRepresentation>>;

  /**
   * Grant the specified user to the specified organization role
   * @summary Grant a user an organization role
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApiInterface
   */
  grantUserOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    userId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Revoke the specified organization role from the specified user
   * @summary Revoke an organization role from a user
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApiInterface
   */
  revokeUserOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    userId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Update role for this organization
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApiInterface
   */
  updateOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;
}

/**
 * OrganizationRolesApi - object-oriented interface
 * @export
 * @class OrganizationRolesApi
 * @extends {BaseAPI}
 */
export class OrganizationRolesApi extends BaseAPI implements OrganizationRolesApiInterface {
  /**
   *
   * @summary Check if a user has an organization role
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApi
   */
  public checkUserOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationRolesApiFp(this.configuration)
      .checkUserOrganizationRole(realm, orgId, name, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new role for this organization
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApi
   */
  public createOrganizationRole(
    realm: string,
    orgId: string,
    iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationRolesApiFp(this.configuration)
      .createOrganizationRole(realm, orgId, iOrganizationRoleRepresentation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete this organization role
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApi
   */
  public deleteOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationRolesApiFp(this.configuration)
      .deleteOrganizationRole(realm, orgId, name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get role for this organization by name
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApi
   */
  public getOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationRolesApiFp(this.configuration)
      .getOrganizationRole(realm, orgId, name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get roles for this organization
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApi
   */
  public getOrganizationRoles(realm: string, orgId: string, options?: AxiosRequestConfig) {
    return OrganizationRolesApiFp(this.configuration)
      .getOrganizationRoles(realm, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get users with this organization role
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApi
   */
  public getUserOrganizationRoles(
    realm: string,
    orgId: string,
    name: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationRolesApiFp(this.configuration)
      .getUserOrganizationRoles(realm, orgId, name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Grant the specified user to the specified organization role
   * @summary Grant a user an organization role
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApi
   */
  public grantUserOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationRolesApiFp(this.configuration)
      .grantUserOrganizationRole(realm, orgId, name, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Revoke the specified organization role from the specified user
   * @summary Revoke an organization role from a user
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApi
   */
  public revokeUserOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationRolesApiFp(this.configuration)
      .revokeUserOrganizationRole(realm, orgId, name, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update role for this organization
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} name organization role name
   * @param {IOrganizationRoleRepresentation} iOrganizationRoleRepresentation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationRolesApi
   */
  public updateOrganizationRole(
    realm: string,
    orgId: string,
    name: string,
    iOrganizationRoleRepresentation: IOrganizationRoleRepresentation,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationRolesApiFp(this.configuration)
      .updateOrganizationRole(realm, orgId, name, iOrganizationRoleRepresentation, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
