import { ReactNode } from "react";

export class HighlightUtils {
  /**
   * Highlight text that matches the search term.
   * Returns the text with the search term highlighted.
   * @param text the text to check for matches
   * @param searchText the search term to highlight
   * @returns the text with the search term highlighted or the original text if no matches
   */
  public static highlightMatch(text: string, searchText: string): ReactNode | string {
    if (!searchText) {
      return text;
    }

    const parts = text.split(new RegExp(`(${searchText})`, "gi"));
    return (
      <>
        {parts.map((part, i) =>
          part.toLowerCase() === searchText.toLowerCase() ? (
            <mark key={i} style={{ backgroundColor: "#E0EDEF" }}>
              {part}
            </mark>
          ) : (
            part
          ),
        )}
      </>
    );
  }
}
