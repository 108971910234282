import { Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Select } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { FC, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { useUserContext } from "../../user/user.context";
import {
  ChevronDownIcon,
  IconSize,
  LanguageIcon,
  LogoutIcon,
  UserIcon,
} from "../constants/tabler-icon.constants";
import { AVAILABLE_LANGUAGE, AVAILABLE_LANGUAGE_KEY, UserUtilities } from "@netcero/netcero-common";
import { UserAvatar } from "../../user/components/user-avatar.component";
import { TOP_BAR_HEIGHT, TOP_BAR_Y_PADDING } from "../components/top-bar.component";

interface ITopBarUserSectionProps {}

export const TopBarUserSection: FC<ITopBarUserSectionProps> = () => {
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const { user } = useUserContext();
  const intercom = useIntercom();

  const userMenuAnchorElement = useRef<null | HTMLButtonElement>(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const handleOpenUserMenu = () => {
    setUserMenuOpen(true);
  };
  const handleCloseUserMenu = () => {
    setUserMenuOpen(false);
  };

  const handleLogout = async () => {
    void keycloak.logout();
    intercom.shutdown();
    handleCloseUserMenu();
  };

  const onChangeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
  };

  const userFullName = useMemo(() => UserUtilities.formatUserFullName(user?.userProfile), [user]);

  return (
    user && (
      <>
        <Box
          ref={userMenuAnchorElement}
          display="flex"
          alignItems="center"
          onClick={handleOpenUserMenu}
        >
          <UserAvatar user={user.userProfile} />
        </Box>
        {/* Menu */}
        <Menu
          id="user-menu"
          anchorEl={userMenuAnchorElement.current}
          open={userMenuOpen}
          onClose={handleCloseUserMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            marginTop: `${(TOP_BAR_HEIGHT - 30) / 2 + TOP_BAR_Y_PADDING}px`,
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            dense: true,
          }}
        >
          <Box display="flex" alignItems="center" px={2}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <UserIcon />
            </ListItemIcon>
            <ListItemText>{userFullName}</ListItemText>
          </Box>

          <MenuItem>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <Select
              value={i18n.language as AVAILABLE_LANGUAGE_KEY}
              onChange={(event) => onChangeLanguage(event?.target.value)}
              variant="standard"
              fullWidth
              disableUnderline
              IconComponent={() => (
                <Box display="flex" sx={{ cursor: "pointer" }}>
                  <ChevronDownIcon size={IconSize.Small} />
                </Box>
              )}
              sx={{
                pr: 0,
              }}
            >
              {Object.entries(AVAILABLE_LANGUAGE).map(([lng, lngDisplayName]) => (
                <MenuItem key={lng} value={lng}>
                  <Box mt={0.5}>
                    <ListItemText>{lngDisplayName}</ListItemText>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </MenuItem>
          <Divider sx={{ my: 1 }} />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>{t("logout_button_text")}</ListItemText>
          </MenuItem>
        </Menu>
      </>
    )
  );
};
