import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  styled,
  SvgIcon,
  Switch,
  Tooltip,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { InfoIcon } from "../../common/constants/tabler-icon.constants";
import { DMASlider } from "../../double-materiality-assessment/common/dma-slider.component";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IStakeholderFeedbackType } from "@netcero/netcero-core-api-client";

const SliderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: { flexWrap: "wrap" },
  [theme.breakpoints.up("md")]: { flexWrap: "nowrap" },
  paddingRight: theme.spacing(4),
}));

const MIN_WIDTH_SLIDER_LABEL = 240;

interface IStakeholderFeedbacksAssessmentSliderProps {
  categoryName: string;
  feedbackType?: IStakeholderFeedbackType;
  disabled?: boolean;
}

export interface IStakeholderFeedbacksAssessmentSliderValues {
  diverge: boolean;
  assessment: number;
}

export const StakeholderFeedbacksAssessmentSlider: FC<
  IStakeholderFeedbacksAssessmentSliderProps
> = ({ categoryName, feedbackType, disabled }) => {
  const { control, watch } = useFormContext<IStakeholderFeedbacksAssessmentSliderValues>();

  const currentDiverge = watch("diverge");
  const { t } = useTranslation("stakeholder_feedback_dialog");

  return (
    <>
      <Controller
        control={control}
        name="diverge"
        render={({ field, fieldState: { error } }) => (
          <FormControl error={!!error}>
            <FormControlLabel
              label={t("label_add_divergent_assessment")}
              control={
                <Switch
                  checked={field.value}
                  onChange={(event, checked) => field.onChange(checked)}
                />
              }
            />
            {error?.message && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />

      {/* Assessment Slider */}
      {currentDiverge && (
        <>
          <Alert severity="info">
            <FormatTranslation
              i18nKey={
                feedbackType ? `slider_info_box_text_${feedbackType}` : "slider_info_box_text"
              }
              t={t}
              values={{ topicTitle: categoryName }}
            />
          </Alert>
          <SliderContainer>
            <FormLabel
              sx={{
                minWidth: MIN_WIDTH_SLIDER_LABEL,
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {t("label_assessment")}
              {/* Make tooltip display line breaks */}
              {feedbackType && (
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {t(`explanation_${feedbackType}_assessment`)}
                    </div>
                  }
                >
                  <SvgIcon fontSize="small" color="action" sx={{ mt: 0.5 }}>
                    <InfoIcon />
                  </SvgIcon>
                </Tooltip>
              )}
            </FormLabel>
            <Controller
              control={control}
              name="assessment"
              render={({ field }) => (
                <DMASlider
                  translationNS="stakeholder_feedback_dialog"
                  value={field.value}
                  onChange={(_, value) => field.onChange(value as number)}
                  aria-label={t("label_assessment")}
                  disabled={disabled}
                />
              )}
            />
          </SliderContainer>
        </>
      )}
    </>
  );
};
