import { TreeUtilities } from "@netcero/netcero-common";
import { IDataEntryObject } from "@netcero/netcero-core-api-client";

export class OrganizationStructureNavigationUtilities {
  /**
   * Get path of DEOs from root to specified child.
   * @param tree Root of the tree to start from
   * @param childId Id of the child
   * @returns Path of DEOs from root to specified child
   */
  public static getDEOsPathForTreeToChild(
    tree: IDataEntryObject,
    childId: string,
  ): IDataEntryObject[] | null {
    return TreeUtilities.getPathToTreeChild(
      tree,
      (deo) => deo.children,
      (deo) => deo.id === childId,
    );
  }
}
