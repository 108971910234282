import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecordingPeriodsQuery } from "../../modules/recording-periods/recording-periods.queries";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { ILocalRecordingPeriod } from "../../modules/recording-periods/recording-periods.utilities";
import { RecordingPeriodSelect } from "../../modules/common/inputs/recording-period-select.component";
import { DeoPicker } from "./deo-picker.component";
import { useUpdateUserDataEntryObjectsAccessMutation } from "../../modules/user/user.mutations";
import { BasicSnackbarApiActionType } from "../../modules/app-snackbar/app-snackbar.interfaces";
import { useAppSnackbar } from "../../modules/app-snackbar/app-snackbar.hook";
import { useGetUserDataEntryObjectsAccessQuery } from "../../modules/user/users.queries";
import { useTranslation } from "react-i18next";
import { EditDialogWrapper } from "../../modules/common/dialogs/variants/edit-dialog.wrapper";
import { Controller, useForm } from "react-hook-form";

type DeoAssignmentDialogProps = {
  organizationId: string;
  onClose: () => void;
  open: boolean;
  targetUserId: string;
};

export const UpdateDeoAccessDialog: FC<DeoAssignmentDialogProps> = ({
  organizationId,
  onClose,
  targetUserId,
  open,
}) => {
  const { t } = useTranslation("update_deo_access_dialog");
  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId);
  const [selectedRecordingPeriod, setSelectedRecordingPeriod] =
    useState<ILocalRecordingPeriod | null>(null);

  const {
    control,
    formState: { isDirty },
    getValues,
    reset,
  } = useForm<{
    selectedDeoIds: string[];
  }>({
    defaultValues: {
      selectedDeoIds: [],
    },
  });

  const getUserDataEntryObjectsAccessQuery = useGetUserDataEntryObjectsAccessQuery(
    organizationId,
    targetUserId,
    selectedRecordingPeriod?.id ?? "",
    { enabled: !!targetUserId },
  );

  useEffect(() => {
    reset({
      selectedDeoIds: getUserDataEntryObjectsAccessQuery.data?.dataEntryObjectIds ?? [],
    });
  }, [getUserDataEntryObjectsAccessQuery.data, reset]);

  useEffect(() => {
    if (
      open &&
      recordingPeriodsQuery.data &&
      recordingPeriodsQuery.data.recordingPeriods.length > 0
    ) {
      setSelectedRecordingPeriod(recordingPeriodsQuery.data.recordingPeriods[0]);
    }
  }, [open, recordingPeriodsQuery.data]);

  const updateUserDataEntryObjectsAccessMutation = useUpdateUserDataEntryObjectsAccessMutation();
  const { wrapApiPromise } = useAppSnackbar();
  const handleSave = async () => {
    if (!selectedRecordingPeriod) {
      return; // should not happen because the button is disabled.
    }

    await wrapApiPromise(
      updateUserDataEntryObjectsAccessMutation.mutateAsync({
        organizationId,
        recordingPeriodId: selectedRecordingPeriod.id,
        userId: targetUserId,
        dataEntryObjectIds: getValues("selectedDeoIds"),
      }),
      {
        type: BasicSnackbarApiActionType.UPDATE_USER_DATA_ENTRY_OBJECTS_ACCESS,
      },
    );

    handleClose();
  };

  const handleClose = () => {
    setSelectedRecordingPeriod(null);
    reset();
    onClose();
  };

  return (
    <EditDialogWrapper
      open={open}
      title={t("title")}
      mode="edit"
      loading={recordingPeriodsQuery.isLoading}
      hasChanges={isDirty}
      onCancel={handleClose}
      onSave={handleSave}
      error={updateUserDataEntryObjectsAccessMutation.error}
      dialogProps={{
        fullWidth: true,
        maxWidth: "md",
      }}
    >
      <Box mt={1}>
        <QueryWrapper
          query={recordingPeriodsQuery}
          build={({ recordingPeriods }) => (
            <Controller
              name="selectedDeoIds"
              control={control}
              render={({ field }) => (
                <>
                  <RecordingPeriodSelect
                    recordingPeriodOptions={recordingPeriods}
                    selectedRecordingPeriod={selectedRecordingPeriod}
                    onChangeRecordingPeriod={(recordingPeriod) => {
                      setSelectedRecordingPeriod(recordingPeriod);
                      field.onChange([]);
                    }}
                  />
                  {selectedRecordingPeriod && (
                    <>
                      <Typography variant="body1" mt={4}>
                        {t("deo_picker_label")}
                      </Typography>
                      <DeoPicker
                        organizationId={organizationId}
                        recordingPeriod={selectedRecordingPeriod}
                        selectedDeoIds={field.value}
                        onChange={field.onChange}
                      />
                    </>
                  )}
                </>
              )}
            />
          )}
        />
      </Box>
    </EditDialogWrapper>
  );
};
