import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IStakeholderFeedback,
  IStakeholderFeedbackType,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { ErrorTextComponent } from "../../../common/components/error-text.component";
import { QueryWrapper } from "../../../common/components/query-wrapper.component";
import { OpenInNewTabIcon } from "../../../common/constants/tabler-icon.constants";
import { useStakeholdersQuery } from "../../../stakeholder/stakeholder.queries";
import { DMACategoryFeedbackUtilities } from "../../common/dma-category-feedback.utilities";
import {
  useRequestCategoryFeedbackMutation,
  useRevokeCategoryFeedbackRequestMutation,
} from "../../mutations/dma-category.mutations";
import { DMACategoryUtilities, IFeedbackState } from "./dma-category.utilities";
import { useResetMutationsOnOpen } from "../../../common/hooks/use-reset-mutations-on-open.hook";
import { DialogCloseButton } from "../../../common/dialogs/dialog-button.components";
import { NavItemUrlUtilities } from "../../../common/utilities/nav-item-url.utilities";

interface IDMACategoryFeedbackRequestDialogProps {
  open: boolean;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  esrsTopicId: string;
  onClose: () => void;
  feedback?: IStakeholderFeedback[];
}

export const DMACategoryFeedbackRequestDialog: FC<IDMACategoryFeedbackRequestDialogProps> = ({
  open,
  dmaCategory,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  esrsTopicId,
  onClose,
  feedback,
}) => {
  const { t } = useTranslation("dma_category_stakeholder_feedback_request_dialog");

  const stakeholdersQuery = useStakeholdersQuery(organizationId);

  const groupedStakeholderFeedbacks = useMemo(() => {
    return DMACategoryFeedbackUtilities.getOrderedStakeholderFeedbackEntriesArray(
      stakeholdersQuery.data?.stakeholders ?? [],
      feedback ?? [],
      true,
    ).filter((entry) => !!entry.stakeholder);
  }, [stakeholdersQuery.data, feedback]);

  // Request Feedback Mutation

  const requestFeedbackMutation = useRequestCategoryFeedbackMutation();

  const handleRequestFeedback = async (stakeholderId: string, type: IStakeholderFeedbackType) => {
    await requestFeedbackMutation.mutateAsync({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId,
      dmaCategoryId: dmaCategory?.id ?? "",
      payload: {
        stakeholderId,
        feedbackType: type,
      },
    });
  };

  const revokeFeedbackRequestMutation = useRevokeCategoryFeedbackRequestMutation();

  const handleRevokeFeedbackRequest = async (
    stakeholderId: string,
    type: IStakeholderFeedbackType,
  ) => {
    await revokeFeedbackRequestMutation.mutateAsync({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      esrsTopicId,
      dmaCategoryId: dmaCategory?.id ?? "",
      payload: {
        stakeholderId,
        feedbackType: type,
      },
    });
  };

  // Change Handler

  const handleChangeRequest = async (
    stakeholderId: string,
    type: IStakeholderFeedbackType,
    checked: boolean,
  ) => {
    if (checked) {
      await handleRequestFeedback(stakeholderId, type);
    } else {
      await handleRevokeFeedbackRequest(stakeholderId, type);
    }
  };

  // Render Component

  const canRequestMaterialFeedback = dmaCategory?.materialState !== IDMACategoryState.Verified;
  const canRequestFinancialFeedback = dmaCategory?.financialState !== IDMACategoryState.Verified;

  const isLoading = requestFeedbackMutation.isPending || revokeFeedbackRequestMutation.isPending;
  const error = requestFeedbackMutation.isError
    ? requestFeedbackMutation.error
    : revokeFeedbackRequestMutation.isError
    ? revokeFeedbackRequestMutation.error
    : null;

  useResetMutationsOnOpen(open, requestFeedbackMutation, revokeFeedbackRequestMutation);

  return (
    <Dialog open={open} onClose={!isLoading ? onClose : undefined} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box display="flex">
          {t("title")}
          <Button
            component={RouterLink}
            to={NavItemUrlUtilities.getConfigurationStakeholderPageUrl(organizationId)}
            target="_blank"
            variant="contained"
            startIcon={<OpenInNewTabIcon stroke={2} />}
            disabled={isLoading}
            sx={{ ml: "auto" }}
          >
            {t("button_manage_stakeholders")}
          </Button>
        </Box>
      </DialogTitle>
      {isLoading && <LinearProgress />}
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          {error && <ErrorTextComponent error={error} />}

          {!canRequestMaterialFeedback && (
            <Alert severity="info">{t("no_request_due_to_verified_material")}</Alert>
          )}

          {!canRequestFinancialFeedback && (
            <Alert severity="info">{t("no_request_due_to_verified_financial")}</Alert>
          )}

          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t("header_stakeholder", { ns: "stakeholder_feedback_common" })}
                </TableCell>
                <TableCell>
                  {t("header_organization", { ns: "stakeholder_feedback_common" })}
                </TableCell>
                <TableCell align="center">
                  {t("header_material", { ns: "stakeholder_feedback_common" })}
                </TableCell>
                <TableCell align="center">
                  {t("header_financial", { ns: "stakeholder_feedback_common" })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <QueryWrapper
                query={stakeholdersQuery}
                loadingOverride={() => (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                build={() =>
                  groupedStakeholderFeedbacks.map((stakeholderFeedbackEntry) => (
                    <TableRow key={stakeholderFeedbackEntry.stakeholderId}>
                      <TableCell>{stakeholderFeedbackEntry.stakeholder?.name}</TableCell>
                      <TableCell>
                        {stakeholderFeedbackEntry.stakeholder?.organization ?? "-"}
                      </TableCell>
                      {/* Feedback Indicators/Request Buttons */}
                      <TableCell align="center">
                        <FeedbackCheckbox
                          feedback={stakeholderFeedbackEntry.materialFeedback}
                          onChange={(checked) =>
                            handleChangeRequest(
                              stakeholderFeedbackEntry.stakeholderId,
                              IStakeholderFeedbackType.Material,
                              checked,
                            )
                          }
                          disabled={isLoading || !canRequestMaterialFeedback}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <FeedbackCheckbox
                          feedback={stakeholderFeedbackEntry.financialFeedback}
                          onChange={(checked) =>
                            handleChangeRequest(
                              stakeholderFeedbackEntry.stakeholderId,
                              IStakeholderFeedbackType.Financial,
                              checked,
                            )
                          }
                          disabled={isLoading || !canRequestFinancialFeedback}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                }
              />
            </TableBody>
          </Table>
          {groupedStakeholderFeedbacks.length < 1 && (
            <DialogContentText textAlign="center" p={2}>
              {t("no_stakeholders_notice")}
            </DialogContentText>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCloseButton onClick={onClose} disabled={isLoading}>
          {t("close", { ns: "buttons" })}
        </DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};

interface IFeedbackCheckboxProps {
  feedback: IStakeholderFeedback | null;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

const FeedbackCheckbox: FC<IFeedbackCheckboxProps> = ({ feedback, disabled, onChange }) => {
  const { t } = useTranslation("dma_category_stakeholder_feedback_request_dialog");

  const hasFeedbackBeenRequested = feedback !== null;

  const hasFeedbackBeenDelivered =
    hasFeedbackBeenRequested &&
    DMACategoryUtilities.getFeedbackState(feedback) !== IFeedbackState.Pending;

  return (
    <Tooltip
      title={
        !hasFeedbackBeenRequested
          ? t("tooltip_request_feedback")
          : hasFeedbackBeenDelivered
          ? t("tooltip_feedback_delivered")
          : t("tooltip_revoke_feedback_request")
      }
    >
      <span>
        <Checkbox
          size="small"
          checked={hasFeedbackBeenRequested}
          onChange={(_, checked) => onChange(checked)}
          disabled={disabled || hasFeedbackBeenDelivered}
        />
      </span>
    </Tooltip>
  );
};
