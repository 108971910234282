import { FieldValues } from "react-hook-form";
import { IMDRNumberInput } from "../../mdr-input.types";
import { useCallback, useMemo } from "react";
import { Box, InputAdornment, TextField, Tooltip } from "@mui/material";
import { IMDRControlledInputProps, NON_FULL_WIDTH_WIDTH } from "./mdr-input-component.factory";
import {
  NUMBER_DEFAULT_PRECISION,
  NumberUtilities,
  PERCENTAGE_DEFAULT_PRECISION,
} from "@netcero/netcero-common";

export function NumberInput<T extends FieldValues>({
  valueMetaData,
  label,
  isConditional,
  field,
  fieldState: { error },
}: IMDRControlledInputProps<T, IMDRNumberInput>) {
  const precision =
    valueMetaData.numberType === "percentage"
      ? PERCENTAGE_DEFAULT_PRECISION
      : NUMBER_DEFAULT_PRECISION;

  const numberProps = useMemo(
    () => ({
      type: "number",
      inputProps: {
        step: NumberUtilities.moveDecimalPlaceRounded(1, precision),
        min: valueMetaData.min,
        max: valueMetaData.max,
      },
    }),
    [precision, valueMetaData.min, valueMetaData.max],
  );

  const endAdornment = useMemo(() => {
    switch (valueMetaData.numberType) {
      case "percentage":
        return <InputAdornment position="end">%</InputAdornment>;
      default:
        return null;
    }
  }, [valueMetaData.numberType]);

  const handleChange = useCallback(
    (value: string) => {
      // Emit empty
      if (value === "") {
        field.onChange(null);
        return;
      }
      const number = +NumberUtilities.extractNumberFromStringWithPrecision(value, precision)!;
      // Handle Percentage
      if (valueMetaData.numberType === "percentage") {
        // Do NOT forward events with invalid (out of range) values
        if (number > 100 || number < 0) {
          field.onChange(field.value);
          return;
        }
      }

      // Check for max and min integer size
      if (number > Number.MAX_SAFE_INTEGER || number < Number.MIN_SAFE_INTEGER) {
        field.onChange(field.value);
        return;
      }
      // Normal
      field.onChange(number);
    },
    [field, precision, valueMetaData.numberType],
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        pl={valueMetaData.isChild || isConditional ? 6 : 0}
      >
        <TextField
          {...field}
          value={field.value ?? ""}
          onChange={(evt) => handleChange(evt.currentTarget.value)}
          label={
            <Tooltip title={label}>
              <span style={{ fontWeight: valueMetaData.isParent ? "bold" : undefined }}>
                {label}
              </span>
            </Tooltip>
          }
          variant="outlined"
          error={!!error}
          helperText={error ? error.message : null}
          {...numberProps}
          InputProps={{ endAdornment }}
          sx={{ maxWidth: NON_FULL_WIDTH_WIDTH }}
        />
      </Box>
      {valueMetaData.isLastChild && <Box />}
    </>
  );
}
