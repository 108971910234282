import { FC, useCallback, useState } from "react";
import { UnderlineTextIcon } from "../../../common/constants/tabler-icon.constants";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isRangeSelection, BaseSelection, FORMAT_TEXT_COMMAND } from "lexical";
import { useListenToSelection } from "../../hooks/listen-to-selection.hook";
import { TextEditorToggleButton } from "../../components/styled.components";

interface IUnderlineButtonProps {
  disabled?: boolean;
}

export const UnderlineButton: FC<IUnderlineButtonProps> = ({ disabled }) => {
  const [editor] = useLexicalComposerContext();

  const [isUnderline, setIsUnderline] = useState(false);

  const handleSelectionChange = useCallback((selection: BaseSelection | null) => {
    if ($isRangeSelection(selection)) {
      setIsUnderline(selection.hasFormat("underline"));
    }
  }, []);

  useListenToSelection(handleSelectionChange);

  return (
    <TextEditorToggleButton
      size="small"
      value="underline"
      selected={isUnderline}
      onChange={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
      disabled={disabled}
    >
      <UnderlineTextIcon />
    </TextEditorToggleButton>
  );
};
