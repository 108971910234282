import { FC } from "react";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { CommentsIcon } from "../../common/constants/tabler-icon.constants";
import { useCommentsCountQuery } from "../comments.queries";
import { ICommentRelations } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";

interface ICommentsIconButtonPropsCommon {
  onClick: VoidFunction;
}

interface ICommentsIconButtonProps extends ICommentsIconButtonPropsCommon {
  commentsCount?: number | null;
}

export const CommentsIconButton: FC<ICommentsIconButtonProps> = ({ onClick, commentsCount }) => {
  const { t } = useTranslation("comments");
  return (
    <Tooltip title={t("button.label")}>
      <IconButton onClick={onClick}>
        <Badge badgeContent={commentsCount} color="primary">
          <CommentsIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

interface ICommentsIconButtonWithQueryProps extends ICommentsIconButtonPropsCommon {
  organizationId: string;
  relations: ICommentRelations;
}

export const CommentsIconButtonWithQuery: FC<ICommentsIconButtonWithQueryProps> = ({
  organizationId,
  relations,
  ...commonProps
}) => {
  const commentsCountQuery = useCommentsCountQuery(organizationId, relations);

  return <CommentsIconButton commentsCount={commentsCountQuery.data} {...commonProps} />;
};
