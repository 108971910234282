import { FC, useMemo } from "react";
import { Box, Divider, Grid, IconButton, Paper, Slide, SxProps, Theme } from "@mui/material";
import { CommentsList } from "../../comments/components/comments-list.component";
import { ICommentLinkMetadata, ICommentRelations } from "@netcero/netcero-core-api-client";
import { CloseIcon } from "../../common/constants/tabler-icon.constants";
import { CommentsButtonWithQuery } from "../../comments/components/comments-button.component";
import { AuditLogsButton } from "../../audit-logging/components/audit-logs-button.component";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { IInputParameterWithDisclosureRequirementType } from "../context/deo-comments-and-audit-log.context";
import { useTranslateOptionalContent } from "../../content-translation/hooks/translate-content.hook";
import { useIsFeatureFlagEnabled } from "../../common/hooks/is-feature-flag-enabled.hook";
import { FeatureFlag } from "../../common/constants/feature-flags.constants";
import { TOP_BAR_HEIGHT } from "../../common/components/top-bar.component";

export const COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH = 450;

export function getContentSxPropsForDeoCommentsAndChangelogPaper(open: boolean): SxProps<Theme> {
  return {
    width: "100%",
    pr: open ? `${COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH}px` : undefined,
    transition: "margin-right 250ms ease, padding-right 300ms ease !important", // Override the transition property otherwise it won't work
  };
}

interface IDeoIpCommentsAndAuditLogPaperProps {
  organizationId: string;
  relations: ICommentRelations;
  linkMetadata: ICommentLinkMetadata;
  disclosureRequirementInputParameter: IInputParameterWithDisclosureRequirementType | null;
  open: boolean;
  onClose: VoidFunction;
}

export const DeoIpCommentsAndChangelogPaper: FC<IDeoIpCommentsAndAuditLogPaperProps> = ({
  organizationId,
  relations,
  linkMetadata,
  disclosureRequirementInputParameter,
  open,
  onClose,
}) => {
  const translateOptionalContent = useTranslateOptionalContent();
  const { disclosureRequirementName, inputParameterName } = useMemo(() => {
    if (disclosureRequirementInputParameter) {
      return {
        disclosureRequirementName: translateOptionalContent(
          disclosureRequirementInputParameter.disclosureRequirement?.name ?? undefined,
        ),
        inputParameterName: translateOptionalContent(
          disclosureRequirementInputParameter.inputParameter?.name ?? undefined,
        ),
      };
    }
    return { disclosureRequirementName: null, inputParameterName: null };
  }, [disclosureRequirementInputParameter, translateOptionalContent]);

  // TODO: NC-XYZ Clean up - only return V2
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);
  const intercomIconHeight = (isFeatureFlagEnabled ? TOP_BAR_HEIGHT : 0) + 96;

  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit timeout={300}>
      <Grid
        item
        onClick={(event) => event.stopPropagation()}
        sx={{
          position: "fixed",
          top: (isFeatureFlagEnabled ? TOP_BAR_HEIGHT : 0) + 10,
          right: 0,
          height: "100vh",
          width: `${COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH}px`,
        }}
      >
        <Paper
          variant="elevation"
          sx={{
            width: `${COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH}px`,
            height: `calc(100dvh - ${intercomIconHeight}px)`,
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.05);",
          }}
        >
          <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
            {/* Top Bar of Sidebar */}
            <Box display="flex">
              <Box sx={{ m: 1.5 }}>
                {disclosureRequirementName && (
                  <LineClampTypographyWithTooltip
                    variant="subtitle2"
                    flexGrow={1}
                    maxLines={1}
                    color="grey"
                  >
                    {disclosureRequirementName}
                  </LineClampTypographyWithTooltip>
                )}
                <LineClampTypographyWithTooltip variant="subtitle2" flexGrow={1} maxLines={1}>
                  {inputParameterName}
                </LineClampTypographyWithTooltip>
              </Box>
              <Box ml="auto" my="auto">
                <IconButton onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            {/* Buttons (for Tab Switching) */}
            <Box display="flex" gap={1} p={1}>
              <CommentsButtonWithQuery
                organizationId={organizationId}
                relations={relations}
                onClick={() => {
                  // Will later be used for tab switching (between comments and audit logs)
                }}
              />
              {/* Audit Logs */}
              <AuditLogsButton organizationId={organizationId} active={false} />
            </Box>
            {/* Sidebar Content (only comments now) */}
            <CommentsList
              organizationId={organizationId}
              relations={relations}
              linkMetadata={linkMetadata}
            />
          </Box>
        </Paper>
      </Grid>
    </Slide>
  );
};
