import { Box, CircularProgress, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorTextComponent } from "../../modules/common/components/error-text.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { useInputParameterRecordingStructureDetailsQuery } from "../../modules/input-parameter-recording-structures/input-parameter-recording-structures.queries";
import { useEvaluationForRecordingPeriodQuery } from "../../modules/evaluation/evaluation.queries";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { ScopeKeys } from "@netcero/netcero-common";
import { EmissionsEvaluationOverviewComponent } from "../../modules/evaluation/emissions/emissions-evaluation-overview.component";
import { EmissionsEvaluationErrorComponent } from "../../modules/evaluation/emissions/emissions-evaluation-error.component";
import { ILocalInputParameterRecordingStructureTHG } from "../../modules/input-parameter-recording-structures/local-input-parameter-recording-structure.interfaces";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";

interface IDataEntryObjectEmissionEvaluationPage {}

export const DataEntryObjectEmissionEvaluationPage: FC<
  IDataEntryObjectEmissionEvaluationPage
> = () => {
  const { t } = useTranslation("emissions_evaluation_page");

  const { organizationId, recordingPeriodId, recordingStructureId, dataEntryObjectId } =
    useParams();

  const navigate = useNavigate();

  const currentOrganization = useCurrentOrganization();

  // Setup Header & Sidebar
  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization!.id,
            onChange: (organizationId) => {
              navigate(`/organizations/${organizationId}`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${currentOrganization?.id}`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, currentOrganization, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization!.id,
            onChange: (organizationId) => {
              navigate(`/organizations/${organizationId}`);
            },
          }
        : undefined,
      recordingPeriodId: recordingPeriodId,
      dataEntryObjectSelect: {
        organizationId: currentOrganization!.id,
        recordingPeriodId: recordingPeriodId!,
        dataEntryObjectId: dataEntryObjectId!,
        onChange: (dataEntryObjectId) => {
          const newPath =
            `/organizations/${currentOrganization!.id}` +
            `/recording-periods/${recordingPeriodId!}` +
            `/evaluations/emissions/recording-structures/${recordingStructureId!}` +
            `/data-entry-objects/${dataEntryObjectId}`;
          navigate({
            pathname: newPath,
            search: window.location.search,
          });
        },
      },
    }),
    [currentOrganization, recordingPeriodId, recordingStructureId, dataEntryObjectId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  // Organization Structure
  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  // Recording Structures
  const recordingStructureQuery = useInputParameterRecordingStructureDetailsQuery(
    organizationId!,
    recordingPeriodId!,
    recordingStructureId!,
  );

  // Evaluation
  const evaluationQuery = useEvaluationForRecordingPeriodQuery(
    organizationId!,
    recordingPeriodId!,
    [ScopeKeys.Scope1, ScopeKeys.Scope2, ScopeKeys.Scope3],
    recordingStructureId!,
  );

  const isLoading = useMemo(
    () =>
      organizationStructureQuery.isLoading ||
      recordingStructureQuery.isLoading ||
      evaluationQuery.isLoading,
    [
      organizationStructureQuery.isLoading,
      recordingStructureQuery.isLoading,
      evaluationQuery.isLoading,
    ],
  );

  const isError = useMemo(
    () =>
      organizationStructureQuery.isError ||
      recordingStructureQuery.isError ||
      evaluationQuery.isError,
    [organizationStructureQuery.isError, recordingStructureQuery.isError, evaluationQuery.isError],
  );

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      ) : isError ? (
        <ErrorTextComponent
          error={
            (recordingStructureQuery.error ||
              organizationStructureQuery.error ||
              evaluationQuery.error)!
          }
        />
      ) : (
        <Box maxWidth="100%" width="100%" mx="auto">
          <Typography variant="h1">{t("title")}</Typography>
          <Box>
            <OrganizationStructureDraftWrapper
              organizationId={organizationId!}
              recordingPeriodId={recordingPeriodId!}
              organizationStructure={organizationStructureQuery.data!}
              build={(structure) =>
                // check whether execution was successful
                evaluationQuery.data!.status === "success" ? (
                  <EmissionsEvaluationOverviewComponent
                    organizationId={organizationId!}
                    recordingPeriodId={recordingPeriodId!}
                    dataEntryObjectId={dataEntryObjectId!}
                    organizationStructure={structure}
                    // Evaluation can only be executed for THG structures anyways (everything else --> backend returns error)
                    recordingStructure={
                      recordingStructureQuery.data! as ILocalInputParameterRecordingStructureTHG
                    }
                    evaluationResult={evaluationQuery.data!.result}
                  />
                ) : (
                  <EmissionsEvaluationErrorComponent error={evaluationQuery.data!} />
                )
              }
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
