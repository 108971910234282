import React, { Fragment, useState } from "react";
import { Checkbox, List, ListItem, ListItemText, Collapse, Box } from "@mui/material";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import { TreeUtilities } from "@netcero/netcero-common";

export type TreeNodeData = {
  id: string;
  label: string;
  children: TreeNodeData[];
};

type TreePickerProps = {
  treeNodeData: TreeNodeData;
  selectedNodeIds?: string[];
  onChange?: (selectedNodes: string[]) => void;
  selectableNodeIds?: string[]; // Nodes that are not descendants of any of these nodes will be disabled
};

const TreePicker: React.FC<TreePickerProps> = ({
  treeNodeData,
  selectedNodeIds: controlledSelectedNodeIds,
  onChange,
  selectableNodeIds,
}) => {
  const [internalSelectedNodeIds, setInternalSelectedNodeIds] = useState<string[]>([]);
  const selectedNodeIds = controlledSelectedNodeIds ?? internalSelectedNodeIds;

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  const handleNodeToggle = (nodeId: string) => {
    const nodeAndAncestors = TreeUtilities.getPathToTreeChild(
      treeNodeData,
      (node) => node.children,
      (node) => node.id === nodeId,
    );
    const nodeAndAncestorIds = nodeAndAncestors?.map((node) => node.id) ?? [];
    const nodeAndDescendants = TreeUtilities.getNodeAndDescendants(
      treeNodeData,
      (node) => node.children,
      (node) => node.id === nodeId,
    );
    const nodeAndDescendantIds = nodeAndDescendants.map((node) => node.id);

    const isNodeSelected = selectedNodeIds.includes(nodeId);
    const newSelectedNodesSet: Set<string> = new Set(selectedNodeIds);
    if (isNodeSelected) {
      // Deselect node and all related nodes
      const relatedNodes = new Set([...nodeAndAncestorIds, ...nodeAndDescendantIds]);
      relatedNodes.forEach((id) => newSelectedNodesSet.delete(id));
    } else {
      // Select node and all descendants
      nodeAndDescendantIds.forEach((id) => newSelectedNodesSet.add(id));
    }
    const newSelectedNodes = Array.from(newSelectedNodesSet).sort();

    if (onChange) {
      onChange(newSelectedNodes);
    } else {
      setInternalSelectedNodeIds(newSelectedNodes);
    }
  };
  const isNodeEnabled = (nodeId: string) => {
    if (!selectableNodeIds) {
      return true;
    }

    const nodeAndAncestors = TreeUtilities.getPathToTreeChild(
      treeNodeData,
      (node) => node.children,
      (node) => node.id === nodeId,
    );

    if (!nodeAndAncestors) {
      return true;
    }

    for (const node of nodeAndAncestors) {
      if (selectableNodeIds.includes(node.id)) {
        return true;
      }
    }
    return false;
  };

  const handleNodeExpand = (nodeId: string) => {
    setExpandedNodes((prev) =>
      prev.includes(nodeId) ? prev.filter((id) => id !== nodeId) : [...prev, nodeId],
    );
  };

  const renderTreeNode = (node: TreeNodeData, depth = 0) => (
    <Fragment key={node.id}>
      <ListItem
        onClick={() => handleNodeExpand(node.id)}
        sx={{
          pl: depth * 2,
          cursor: "pointer",
          "&:hover": { backgroundColor: "action.hover" },
          borderRadius: 2,
        }}
      >
        <Checkbox
          checked={selectedNodeIds.includes(node.id)}
          onClick={(event) => event.stopPropagation()}
          onChange={() => handleNodeToggle(node.id)}
          disabled={!isNodeEnabled(node.id)}
        />
        <ListItemText
          primary={node.label}
          sx={{ color: isNodeEnabled(node.id) ? "text.primary" : "text.disabled" }}
        />
        {node.children.length > 0 && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {expandedNodes.includes(node.id) ? <ExpandMore /> : <ChevronRight />}
          </Box>
        )}
      </ListItem>

      {node.children.length > 0 && (
        <Collapse in={expandedNodes.includes(node.id)}>
          <List component="div" disablePadding>
            {node.children.map((childNode) => renderTreeNode(childNode, depth + 1))}
          </List>
        </Collapse>
      )}
    </Fragment>
  );

  return <List>{renderTreeNode(treeNodeData)}</List>;
};

export default TreePicker;
