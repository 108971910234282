import { Box, CardContent, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useNavigate, useParams } from "react-router-dom";
import { UserTable } from "./user-table.component";
import { UserTableFilters } from "./user-table-filters.component";
import { FormProvider, useForm } from "react-hook-form";
import { StyledCard } from "../../modules/common/components/styled-card.component";
import { useTranslation } from "react-i18next";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import {
  AppBreadCrumbs,
  IBreadCrumb,
} from "../../modules/common/components/app-bread-crumbs.component";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";

export const UsersPage: FC = () => {
  const { t } = useTranslation("users_page");
  const { t: tNavItems } = useTranslation("side_bar_nav_items");
  const { organizationId } = useParams();

  const breadCrumbItems: IBreadCrumb[] = [
    {
      label: tNavItems("configuration"),
      to: NavItemUrlUtilities.getConfigurationPageUrl(organizationId!),
    },
    { label: tNavItems("configuration_users") },
  ];

  const navigate = useNavigate();
  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/users`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration/users`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/users`);
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const methods = useForm<UserTableFilters>({
    defaultValues: { searchValue: "" },
  });

  if (!organizationId) {
    return null;
  }

  return (
    <Box>
      <AppBreadCrumbs items={breadCrumbItems} />
      <Typography variant="h1" mt={1}>
        {t("title")}
      </Typography>
      <FormProvider {...methods}>
        <Box mt={2.5}>
          <StyledCard>
            <CardContent sx={{ p: 2.5 }}>
              <UserTableFilters />
            </CardContent>
          </StyledCard>
        </Box>
        <Box mt={4}>
          <StyledCard>
            <CardContent sx={{ p: 2.5 }}>
              <UserTable organizationId={organizationId} />
            </CardContent>
          </StyledCard>
        </Box>
      </FormProvider>
    </Box>
  );
};
