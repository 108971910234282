import { Dispatch, FC } from "react";
import { IPolicy } from "@netcero/netcero-core-api-client";
import { useDialogStateWithoutData } from "../../../common/dialogs/dialog-state.hook";
import {
  IMDRSelectDisplayItem,
  MDRSelectDialog,
} from "../../../minimum-disclosure-requirements-common/components/mdr-select.dialog";
import { MDRMultipleSelectChip } from "../../../minimum-disclosure-requirements-common/components/mdr-select-chip.component";

interface IPoliciesSelectInputProps {
  label: string;
  policies: IPolicy[];
  policiesIds: string[];
  onChange: Dispatch<string[]>;
}

const toDisplayItem = (policy: IPolicy): IMDRSelectDisplayItem => ({
  title: policy.general.name,
  associatedTopics: policy.associatedTopics,
  id: policy.id,
});

export const PoliciesInputSelect: FC<IPoliciesSelectInputProps> = ({
  label,
  policies,
  policiesIds,
  onChange,
}) => {
  const {
    isOpen: showSelectDialog,
    openDialog: openSelectDialog,
    closeDialog: closeSelectDialog,
  } = useDialogStateWithoutData();

  return (
    <>
      {/* Dialog */}
      <MDRSelectDialog
        open={showSelectDialog}
        title={label}
        onClose={closeSelectDialog}
        items={policies}
        selectedItemIds={policiesIds}
        onChange={onChange}
        toDisplayItem={toDisplayItem}
        selectionMode="multiple"
      />

      {/* Chip Select */}
      <MDRMultipleSelectChip
        label={label}
        items={policies}
        selectedItemIds={policiesIds}
        onSelectClick={openSelectDialog}
      />
    </>
  );
};
