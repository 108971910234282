import { Box, MenuItem, Select } from "@mui/material";
import { FC, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon, IconSize } from "../constants/tabler-icon.constants";
import { FormatUtilities } from "../utilities/format.utilities";
import { useOrganizations } from "../../organizations/organizations.hook";
import { TOP_BAR_HEIGHT, TOP_BAR_Y_PADDING } from "../components/top-bar.component";

interface IOrganizationSelectTopBarSectionProps {
  organizationId?: string;
  onChange: (organizationId: string) => void;
}

export const OrganizationSelectTopBarSection: FC<IOrganizationSelectTopBarSectionProps> = ({
  organizationId,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const organizations = useOrganizations();

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        height: TOP_BAR_HEIGHT - 2 * TOP_BAR_Y_PADDING,
        backgroundColor: "common.white",
        pr: 1,
        ml: 0.5,
        borderRadius: 4,
        "&:hover": { backgroundColor: "grey.50" },
        transition: "background-color 200ms",
      }}
    >
      <Select
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        value={organizationId}
        onChange={(event) => onChange(event.target.value)}
        renderValue={(value) => {
          const organization = organizations.find((organization) => organization.id === value);

          return (
            <Box overflow="hidden" maxWidth="190px">
              {/* cutTextAfterN still makes sense here since LineClampTypography exhibits unexpected behaviour */}
              {/* eslint-disable-next-line deprecation/deprecation */}
              {FormatUtilities.cutTextAfterN(organization?.name ?? "", 25)}
            </Box>
          );
        }}
        fullWidth
        IconComponent={() => (
          <Box display="flex">
            {organizations.length > 1 &&
              (isOpen ? (
                <ChevronUpIcon size={IconSize.Small} />
              ) : (
                <ChevronDownIcon size={IconSize.Small} />
              ))}
          </Box>
        )}
        MenuProps={{
          slotProps: {
            paper: {
              sx: {
                py: 0,
                mt: `${TOP_BAR_Y_PADDING}px`,
                boxShadow: "0px 8px 12px 0px rgba(0, 0, 0, 0.15)",
                border: "1px solid",
                borderColor: "grey.100",
              },
            },
          },
        }}
        sx={{
          pr: 0,
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        }}
      >
        {organizations.map((organization) => (
          <MenuItem key={organization.id} value={organization.id}>
            {organization.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
