export class ObjectUtilities {
  public static getDeepValue(path: string, value: object) {
    return path.split(".").reduce((acc, curr) => {
      if (acc === undefined || acc === null) {
        return acc;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (acc as any)[curr];
    }, value);
  }

  public static objectToRecord<
    V,
    T extends {
      [key: string]: V | undefined;
    } = { [key: string]: V | undefined },
  >(object: T): Record<string, V> {
    return object as Record<string, V>;
  }

  /**
   * This method determines whether a given value is present and also narrows the type.
   * More specifically, this checks whether a value is:
   * * Not `null` **and**
   * * Not `undefined`
   * @param value The value to test
   */
  public static isValuePresent<T>(value: T): value is Exclude<T, null | undefined> {
    return value !== undefined && value !== null;
  }
}
