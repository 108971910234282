import { FormControl } from "@mui/material";
import { IInputParameterValueMetaDataDate } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { BaseDateComponent } from "./base-date.component";
import {
  DataEntryObjectInputParameterValueDefinitionForDate,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";

/** TODO: When touching this again - try to use ICommonInputProps */
interface IDateInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForDate>;
  label?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string;
  metaData: IInputParameterValueMetaDataDate;
  disableMaxWidth?: boolean;
  required?: boolean;
}

export const DateInputComponent: FC<IDateInputComponentProps> = ({
  variant,
  value,
  onChange,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  label,
  required,
}) => {
  const variantProps = useVariantFormProps(variant);

  return (
    <FormControl
      {...variantProps}
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined }}
    >
      <BaseDateComponent
        variant={variant}
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
        min={metaData.valueLimits.min}
        max={metaData.valueLimits.max}
        label={label}
        required={required}
      />
    </FormControl>
  );
};
