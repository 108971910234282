import { IDrValueEditProps } from "./dr-value-editor.component";
import { type FC, useMemo } from "react";
import { DataEntryObjectValuesInputTable } from "../../input-components/table/data-entry-object-value-input-fields-table.component";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AddRowIcon } from "../../../common/constants/tabler-icon.constants";
import { useManageDataEntryObjectTableValue } from "../../input-components/table/manage-data-entry-object-table-value.hook";
import { CommentsButtonWithQuery } from "../../../comments/components/comments-button.component";
import { useDeoCommentsAndAuditLogContext } from "../../../comments-and-audit-log/context/deo-comments-and-audit-log.context";
import { AuditLogsButton } from "../../../audit-logging/components/audit-logs-button.component";
import { IDataEntryObjectValueInputComponentAssociationData } from "../../input-components/data-entry-object-value-input.component";

export const DrValueEditorTable: FC<IDrValueEditProps> = ({
  deoInputParameter,
  organizationId,
  recordingPeriod,
  rootDataEntryObjectId,
  dataEntryObjectId,
  // availableDistributionCriteria,
  // onCreate,
  // onUpdate,
  // onDelete,
  onTableUpdate,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_esrs_component");

  const {
    selectedInputParameterId,
    setSelectedInputParameterId,
    toggleCommentsAndAuditLogSideSection,
    openCommentsAndAuditLogSideSection,
  } = useDeoCommentsAndAuditLogContext();

  const {
    state: { isEditing, values, rowsErrors, duplicateValuesErrors },
    formState: { isStaticTable, staticIdentifierColumnValueDefinition, hasErrors, hasChanges },
    handlers: {
      handleAddRow,
      handleDeleteRow,
      handleValueChange,
      handleReset,
      handleSubmit,
      handleStartEditing,
    },
  } = useManageDataEntryObjectTableValue({
    inputParameter: deoInputParameter.inputParameter,
    recordedValues: deoInputParameter.recordedValues,
    subscribeToRecordedValues: true,
  });

  const associationData: IDataEntryObjectValueInputComponentAssociationData = useMemo(
    () => ({
      organizationId,
      recordingPeriodId: recordingPeriod.id,
      rootDataEntryObjectId,
      dataEntryObjectId,
    }),
    [organizationId, recordingPeriod, rootDataEntryObjectId, dataEntryObjectId],
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <DataEntryObjectValuesInputTable
        inputParameter={deoInputParameter.inputParameter}
        associationData={associationData}
        staticTableIdentifierColumnValueDefinition={staticIdentifierColumnValueDefinition}
        values={values}
        disabled={disabled ?? false}
        onValueChange={handleValueChange}
        onDeleteRow={handleDeleteRow}
        rowErrors={rowsErrors}
        duplicateValuesErrors={duplicateValuesErrors}
        editMode={isEditing}
        onStartEditing={handleStartEditing}
      />
      {/* Actions Bar */}
      <Box>
        {/* Add Row Button */}
        {!isStaticTable && (
          <Button variant="text" color="inherit" onClick={handleAddRow} sx={{ gap: 1 }}>
            <AddRowIcon />
            {t("button_add_table_row")}
          </Button>
        )}
      </Box>
      <Box display="flex" gap={2}>
        <Box display="flex" gap={1}>
          {/* Comments */}
          <CommentsButtonWithQuery
            organizationId={organizationId}
            relations={{
              entity: "data-entry-object-input-parameter",
              dataEntryObjectId: dataEntryObjectId,
              inputParameterId: deoInputParameter.inputParameter.id ?? "",
            }}
            onClick={() => {
              setSelectedInputParameterId(deoInputParameter.inputParameter.id);
              if (selectedInputParameterId === deoInputParameter.inputParameter.id) {
                toggleCommentsAndAuditLogSideSection();
              } else {
                openCommentsAndAuditLogSideSection();
              }
            }}
          />
          {/* Audit Logs */}
          <AuditLogsButton organizationId={organizationId} active={false} />
        </Box>
        {/* Spacer */}
        <Box flex={1} />
        {/* Save & Cancel Buttons */}
        <Button
          color="inherit"
          onClick={handleReset}
          disabled={disabled || (!hasChanges && !isEditing)}
        >
          {t("cancel", { ns: "buttons" })}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onTableUpdate)}
          disabled={disabled || hasErrors || !hasChanges}
        >
          {t("save", { ns: "buttons" })}
        </Button>
      </Box>
    </Box>
  );
};
