import { OrgRole } from "@netcero/netcero-common";
import { TreeUtilities } from "@netcero/netcero-common";
import { useRecordingPeriodOrganizationStructureQuery } from "../../organization-structures/organization-structures.queries";
import { useGetDataEntryObjectsWithAssignedInputParametersQuery } from "../../recording-structures/recording-structures.queries";
import { useUserContext } from "../user.context";
import { useGetUserDataEntryObjectsAccessQuery } from "../users.queries";
import { useAuthenticatedUserOrganizationRole } from "./authenticated-user-org-role.hook";
import { useMemo } from "react";

export function useGetAccessibleDataObjectEntryIds({
  organizationId,
  recordingPeriodId,
  recordingStructureId,
}: {
  organizationId: string;
  recordingPeriodId: string;
  recordingStructureId?: string;
}) {
  const { user } = useUserContext();
  const authenticatedUserOrgRole = useAuthenticatedUserOrganizationRole();

  // Get all DEOs in the organization structure
  const { data: organizationStructure } = useRecordingPeriodOrganizationStructureQuery(
    organizationId,
    recordingPeriodId,
  );

  // Get DEOs from assigned data entry objects
  const { data: dataEntryObjectsAccess } = useGetUserDataEntryObjectsAccessQuery(
    organizationId,
    user?.jwtPayload.sub ?? "",
    recordingPeriodId,
    {
      enabled: !!user?.jwtPayload.sub && authenticatedUserOrgRole === OrgRole.OBJECT_ADMIN,
    },
  );

  // Get DEOs from assigned input parameters
  const { data: dataEntryObjectsWithAssignedInputParametersData } =
    useGetDataEntryObjectsWithAssignedInputParametersQuery(
      organizationId,
      recordingPeriodId,
      recordingStructureId!,
      {
        enabled: authenticatedUserOrgRole !== OrgRole.ORGANIZATION_ADMIN && !!recordingStructureId,
      },
    );

  return useMemo(() => {
    if (
      !organizationStructure ||
      organizationStructure.isDraft ||
      !("structure" in organizationStructure)
    ) {
      return null;
    }

    // If user is an organization admin, all DEOs are accessible
    if (authenticatedUserOrgRole === OrgRole.ORGANIZATION_ADMIN) {
      return TreeUtilities.flatMap(
        organizationStructure.structure,
        (node) => node.children,
        (node) => node.id,
      );
    }

    // If user is object admin, all assigned DEOs and their descendants are accessible
    const assignedDataEntryObjectIds = dataEntryObjectsAccess?.dataEntryObjectIds ?? [];
    const assignedDeoIdsIncludingDescendants = assignedDataEntryObjectIds.flatMap(
      (dataEntryObjectId) =>
        TreeUtilities.getNodeAndDescendants(
          organizationStructure.structure,
          (node) => node.children,
          (node) => node.id === dataEntryObjectId,
        ).map((node) => node.id),
    );

    // Also DEOs with assigned input parameters are accessible (assigned as contributor or responsible person)
    const dataEntryObjectIdsWithAssignedInputParameters =
      dataEntryObjectsWithAssignedInputParametersData?.dataEntryObjectIds ?? [];

    // Merge DeoIds and remove duplicates
    const selectableNodeIds = Array.from(
      new Set([
        ...dataEntryObjectIdsWithAssignedInputParameters,
        ...assignedDeoIdsIncludingDescendants,
      ]),
    );

    return selectableNodeIds;
  }, [
    authenticatedUserOrgRole,
    dataEntryObjectsAccess?.dataEntryObjectIds,
    dataEntryObjectsWithAssignedInputParametersData?.dataEntryObjectIds,
    organizationStructure,
  ]);
}
