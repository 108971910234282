import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDMACategoryWithEffectsAndChildren,
  IDMAConfiguration,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CancelIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  HideIcon,
  IconSize,
  ShowIcon,
} from "../../common/constants/tabler-icon.constants";
import { useRenderDMACategoryName } from "../../double-materiality-assessment/hooks/render-dma-category-name.hook";
import { useRenderESRSTopicName } from "../../double-materiality-assessment/hooks/render-esrs-topic-name.hook";
import { IGroupedESRSTopics } from "../dma-dashboard.utilities";
import {
  DMAOverviewTableUtilities,
  IDMAOverviewTableItemData,
  IDMAOverviewTableItemMaterialityDegreeData,
} from "./dma-overview-table.utilities";
import { IroExportButton } from "../../double-materiality-assessment/common/iro-export-button.component";
import { DmaConfigurationUtilities, IBaseCalculatorContext } from "@netcero/netcero-dma";
import { DmaFormatUtilities } from "../../double-materiality-assessment/utilities/dma-format.utilities";
import { MaterialityStatusChip } from "../../double-materiality-assessment/common/materiality-status-chip.component";
import { useDeoDmaDashboardContext } from "../deo-dma-dashboard.context";

const useDoesValueExceedThreshold = (context: IBaseCalculatorContext) => {
  return useCallback(
    (value: number | null) => {
      if (value === null) {
        return false;
      }
      return value >= context.materialityThreshold;
    },
    [context.materialityThreshold],
  );
};

const useGetColorForValue = (context: IBaseCalculatorContext) => {
  const doesValueExceedThreshold = useDoesValueExceedThreshold(context);
  return useCallback(
    (value: number | null) => (doesValueExceedThreshold(value) ? "success.main" : "error.main"),
    [doesValueExceedThreshold],
  );
};

const TableCellBorderRight = styled(TableCell)(({ theme }) => ({
  borderRight: `solid 1px ${theme.palette.divider}`,
}));

interface ITableDisplaySettings {
  showImpacts: boolean;
  showEffects: boolean;
}

interface IDMAOverviewTableProps {
  groupedESRSTopics: IGroupedESRSTopics;
  viewedESRSTopic: IESRSTopic | null;
  viewedDMACategory: IDMACategoryWithEffectsAndChildren | null;
  dmaConfiguration: IDMAConfiguration;
}

export const DMAOverviewTable: FC<IDMAOverviewTableProps> = ({
  groupedESRSTopics,
  viewedESRSTopic,
  viewedDMACategory,
  dmaConfiguration,
}) => {
  const { t } = useTranslation("dma_dashboard");
  const { organizationId, recordingPeriodId, dataEntryObject } = useDeoDmaDashboardContext();
  const renderName = useRenderDMACategoryName();
  const renderTopic = useRenderESRSTopicName();

  const tableItems = useMemo(
    () =>
      DMAOverviewTableUtilities.convertGroupedESRSTopicsToTableData(
        groupedESRSTopics,
        {
          environmental: t("label_topic_environmental"),
          social: t("label_topic_social"),
          governance: t("label_topic_governance"),
          other: t("label_topic_other"),
        },
        dmaConfiguration,
        renderName,
        renderTopic,
      ),
    [groupedESRSTopics, t, dmaConfiguration, renderName, renderTopic],
  );

  const [expanded, setExpanded] = useState<string[]>([]);
  const [tableDisplaySettings, setTableDisplaySettings] = useState<ITableDisplaySettings>({
    showImpacts: true,
    showEffects: true,
  });

  const handleExpandAll = () => {
    setExpanded(
      DMAOverviewTableUtilities.flatMapAllItems(tableItems)
        .filter((i) => i.children.length > 0)
        .map((i) => i.id),
    );
  };

  const handleCollapseAll = () => {
    setExpanded([]);
  };

  const handleExpand = (id: string) => {
    setExpanded((prev) => [...prev, id]);
  };

  const handleCollapse = (id: string) => {
    setExpanded((prev) => prev.filter((i) => i !== id));
  };

  // Get the ID of the viewed item (first category (set only if topic set as well) then topic)
  const viewedId = viewedDMACategory?.id ?? viewedESRSTopic?.id ?? null;

  const pathIdsToSelected = useMemo(
    () =>
      viewedId
        ? DMAOverviewTableUtilities.getPathToItem(tableItems, viewedId)?.map((i) => i.id) ?? null
        : null,
    [tableItems, viewedId],
  );

  return (
    <Box display="flex" flexDirection="column" maxHeight="100vh">
      {/* Title */}
      <Typography variant="h3" component="h2" mb={1}>
        {t("title_overview_table")}
      </Typography>
      {/* Checkboxes (for display control) */}
      <Box display="flex" gap={2}>
        <FormControlLabel
          label={t("label_show_impacts")}
          control={
            <Checkbox
              icon={<ShowIcon size={IconSize.Medium} />}
              checkedIcon={<HideIcon size={IconSize.Medium} />}
              color="default"
              checked={tableDisplaySettings.showImpacts}
              onChange={(_, checked) =>
                setTableDisplaySettings((settings) => ({ ...settings, showImpacts: checked }))
              }
            />
          }
        />
        <FormControlLabel
          label={t("label_show_effects")}
          control={
            <Checkbox
              icon={<ShowIcon size={IconSize.Medium} />}
              checkedIcon={<HideIcon size={IconSize.Medium} />}
              color="default"
              checked={tableDisplaySettings.showEffects}
              onChange={(_, checked) =>
                setTableDisplaySettings((settings) => ({ ...settings, showEffects: checked }))
              }
            />
          }
        />
      </Box>
      {/* Actions */}
      <Box display="flex" justifyContent="space-between" alignItems="center" my={2} gap={1}>
        <Box display="flex" gap={1} justifyContent="flex-start" flexDirection="row">
          <Button
            variant="contained"
            color="primary"
            startIcon={<ChevronDownIcon />}
            onClick={handleExpandAll}
          >
            {t("button_expand_all")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ChevronUpIcon />}
            onClick={handleCollapseAll}
          >
            {t("button_collapse_all")}
          </Button>
        </Box>
        {/* Button for export*/}
        <IroExportButton
          organizationId={organizationId}
          recordingPeriodId={recordingPeriodId}
          dataEntryObjectId={dataEntryObject.id}
        />
      </Box>
      {/* Table */}
      <TableContainer sx={{ border: "1px solid", borderColor: "divider", borderRadius: "8px" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            {/* First Header Row (Grouping) */}
            <TableRow>
              <TableCellBorderRight colSpan={2} sx={{ borderBottom: "none" }} />
              {/* Impacts Group Heading */}
              {tableDisplaySettings.showImpacts && (
                <TableCellBorderRight
                  colSpan={4}
                  align="center"
                  sx={{
                    borderBottom: "none",
                  }}
                >
                  {t("table_header_material_impacts_group")}
                </TableCellBorderRight>
              )}
              {/* Effects Group Heading */}
              {tableDisplaySettings.showEffects && (
                <TableCellBorderRight
                  colSpan={4}
                  align="center"
                  sx={{
                    borderBottom: "none",
                  }}
                >
                  {t("table_header_financial_effects_group")}
                </TableCellBorderRight>
              )}
              <TableCell colSpan={2} sx={{ borderBottom: "none" }} />
            </TableRow>
            {/* Second Header Row (actual columns) */}
            <TableRow>
              <TableCell>{t("header_name")}</TableCell>
              <TableCellBorderRight /> {/* Empty cell for the opt out indicator */}
              {/* Impacts Group Headings */}
              {tableDisplaySettings.showImpacts && <GroupSubHeadings />}
              {tableDisplaySettings.showEffects && <GroupSubHeadings />}
              {/* Material */}
              <TableCell align="center">{t("header_max_materiality_degree")}</TableCell>
              <TableCell align="center">{t("header_material")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              // Remove Bottom border of all cells in last row (interferes with container border)
              "tr:last-child": {
                "*": { borderBottom: "none" },
              },
            }}
          >
            {tableItems.map((item) => (
              <DMAOverviewTableRow
                key={item.id}
                data={item}
                expanded={expanded}
                pathIdsToSelected={pathIdsToSelected}
                tableDisplaySettings={tableDisplaySettings}
                onExpand={handleExpand}
                onCollapse={handleCollapse}
                dmaConfiguration={dmaConfiguration}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const GroupSubHeadings: FC = () => {
  const { t } = useTranslation("dma_dashboard");

  return (
    <>
      <TableCell>{t("header_materiality_short_term")}</TableCell>
      <TableCell align="center">{t("header_materiality_medium_term")}</TableCell>
      <TableCell align="center">{t("header_materiality_long_term")}</TableCell>
      <TableCellBorderRight align="center">{t("header_materiality_max")}</TableCellBorderRight>
    </>
  );
};

// Table Item Component

interface IDMAOverviewTableRowProps {
  data: IDMAOverviewTableItemData;
  level?: number;
  expanded: string[];
  pathIdsToSelected: string[] | null;
  tableDisplaySettings: ITableDisplaySettings;
  onExpand: (id: string) => void;
  onCollapse: (id: string) => void;
  dmaConfiguration: IDMAConfiguration;
}

const DMAOverviewTableRow: FC<IDMAOverviewTableRowProps> = ({
  data,
  level = 0,
  expanded,
  pathIdsToSelected,
  tableDisplaySettings,
  onExpand,
  onCollapse,
  dmaConfiguration,
}) => {
  const { t } = useTranslation("dma_dashboard");

  const isExpanded = useMemo(() => expanded.includes(data.id), [data.id, expanded]);
  const hasChildren = useMemo(() => data.children.length > 0, [data]);
  const isHighlightedAndForceExpanded = useMemo(
    () => pathIdsToSelected?.includes(data.id) ?? false,
    [data.id, pathIdsToSelected],
  );

  const materialContext = useMemo(
    () => DmaConfigurationUtilities.materialContextFromConfig(dmaConfiguration),
    [dmaConfiguration],
  );
  const financialContext = useMemo(
    () => DmaConfigurationUtilities.financialContextFromConfig(dmaConfiguration),
    [dmaConfiguration],
  );

  return (
    <>
      {/* Table Row itself */}
      <TableRow>
        <TableCell sx={{ width: "100%" }}>
          <Box display="flex" alignItems="center" ml={level * 2}>
            <Tooltip title={!hasChildren ? t("tooltip_no_children") : ""}>
              <span>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (isExpanded) {
                      onCollapse(data.id);
                    } else {
                      onExpand(data.id);
                    }
                  }}
                  sx={{
                    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 300ms",
                  }}
                  disabled={!hasChildren || isHighlightedAndForceExpanded}
                >
                  <ChevronDownIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Box component="span" fontWeight={isHighlightedAndForceExpanded ? "bold" : undefined}>
              {data.name}
            </Box>
          </Box>
        </TableCell>
        <TableCellBorderRight>
          {data.isOptOut && (
            <Box display="flex">
              <Tooltip title={t("tooltip_opt_out")}>
                <CancelIcon />
              </Tooltip>
            </Box>
          )}
        </TableCellBorderRight>
        {/* Values */}
        {tableDisplaySettings.showImpacts && (
          <TableValueCells data={data.materialMaterialityDegree} context={materialContext} />
        )}
        {tableDisplaySettings.showEffects && (
          <TableValueCells data={data.financialMaterialityDegree} context={financialContext} />
        )}
        {/* Materiality Degree */}
        <TableCell align="center">
          <Tooltip title={data.isMaterial ? t("cells.any_value_exceeds_threshold") : null}>
            <Box color={data.isMaterial ? "success.main" : "error.main"}>
              {DmaFormatUtilities.formatMaterialityDegree(data.maxMaterialityDegree)}
            </Box>
          </Tooltip>
        </TableCell>
        {/* Material */}
        <TableCell align="center">
          <Box color={data.isMaterial ? "success.main" : "error.main"}>
            <MaterialityStatusChip isMateriality={data.isMaterial} />
          </Box>
        </TableCell>
      </TableRow>
      {(isExpanded || isHighlightedAndForceExpanded) &&
        data.children.map((child) => (
          <DMAOverviewTableRow
            key={child.id}
            data={child}
            level={level + 1}
            pathIdsToSelected={pathIdsToSelected}
            expanded={expanded}
            tableDisplaySettings={tableDisplaySettings}
            onExpand={onExpand}
            onCollapse={onCollapse}
            dmaConfiguration={dmaConfiguration}
          />
        ))}
    </>
  );
};

// Value Cells

const TableValueCells: FC<{
  data: IDMAOverviewTableItemMaterialityDegreeData;
  backgroundColor?: string;
  context: IBaseCalculatorContext;
}> = ({ data, backgroundColor, context }) => {
  const { t } = useTranslation("dma_dashboard", { keyPrefix: "cells" });

  const doesValueExceedThreshold = useDoesValueExceedThreshold(context);
  const getColorForValue = useGetColorForValue(context);

  const renderValue = useCallback(
    (value: number | null) => {
      return (
        <TableCell align="center" sx={{ bgcolor: backgroundColor }}>
          <Tooltip
            title={
              doesValueExceedThreshold(value)
                ? t("value_exceeds_threshold", { threshold: context.materialityThreshold })
                : null
            }
          >
            <Box color={getColorForValue(value)}>
              {DmaFormatUtilities.formatMaterialityDegree(value)}
            </Box>
          </Tooltip>
        </TableCell>
      );
    },
    [backgroundColor, context.materialityThreshold, doesValueExceedThreshold, getColorForValue, t],
  );

  return (
    <>
      {renderValue(data.shortTerm)}
      {renderValue(data.mediumTerm)}
      {renderValue(data.longTerm)}

      <TableCellBorderRight align="center" sx={{ bgcolor: backgroundColor }}>
        <Tooltip
          title={
            doesValueExceedThreshold(data.maxValue)
              ? t("value_exceeds_threshold", { threshold: context.materialityThreshold })
              : null
          }
        >
          <Box color={getColorForValue(data.maxValue)}>
            {DmaFormatUtilities.formatMaterialityDegree(data.maxValue)}
          </Box>
        </Tooltip>
      </TableCellBorderRight>
    </>
  );
};
