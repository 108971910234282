import { FC, ReactNode } from "react";
import { LexicalBaseEditor } from "./lexical-base-editor.component";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorContainerEditableOnClick } from "./plugins/editor-container-editable-on-click.component";
import { ILexicalEditorCommonProps } from "./lexical.types";

interface ILexicalRichTextEditorProps extends ILexicalEditorCommonProps {
  placeholder?: string;
  beforeInput?: ReactNode;
  inInnerEditor?: ReactNode;
  afterInput?: ReactNode;
  afterEditor?: ReactNode;
  disabled?: boolean;
}

export const LexicalRichTextEditor: FC<ILexicalRichTextEditorProps> = ({
  placeholder,
  beforeInput,
  inInnerEditor,
  afterInput,
  afterEditor,
  disabled,
  ...commonProps
}) => {
  const contentEditableProps = placeholder
    ? {
        "aria-placeholder": placeholder,
        placeholder: <div className="editor-placeholder">{placeholder}</div>,
      }
    : {};

  return (
    <LexicalBaseEditor {...commonProps}>
      <EditorContainerEditableOnClick disabled={disabled}>
        {beforeInput}
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="editor-input"
                {...contentEditableProps}
                onFocus={(e) => {
                  if (disabled) {
                    e.currentTarget.blur();
                  }
                }}
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          {inInnerEditor}
        </div>
        {afterInput}
      </EditorContainerEditableOnClick>
      {afterEditor}
    </LexicalBaseEditor>
  );
};
