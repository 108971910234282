import { IMDRDependency, MDRDependencies } from "./mdrs.public-types";
import { ObjectUtilities } from "../common/utilities/object.utilities";

export class MDRUtilities {
  public static createBooleanMDRDependencyFactory<T extends string>() {
    return (dependsUpon: T, toHaveValue: boolean = true): IMDRDependency<T> => {
      return {
        dependsUpon,
        activeWhenValue: {
          type: "equals-value",
          activeWhenValue: toHaveValue,
        },
      };
    };
  }

  public static shouldInputBeShown<T extends string>(
    keys: T,
    value: object,
    dependencies: MDRDependencies<T>,
  ) {
    const rule = dependencies[keys];

    // no rule defined --> should always be shown
    if (rule === undefined) {
      return true;
    }

    const targetValue = ObjectUtilities.getDeepValue(rule.dependsUpon, value) as unknown as
      | string
      | boolean
      | unknown[];

    if (rule.activeWhenValue.type === "array-not-empty" && Array.isArray(targetValue)) {
      return targetValue.length > 0;
    } else if (rule.activeWhenValue.type === "equals-value" && !Array.isArray(targetValue)) {
      return targetValue === rule.activeWhenValue.activeWhenValue;
    }

    return false;
  }
}
