import { FC } from "react";
import { Backdrop } from "@mui/material";
import { ICommentLinkMetadata, ICommentRelations } from "@netcero/netcero-core-api-client";
import { IInputParameterWithDisclosureRequirementType } from "../context/deo-comments-and-audit-log.context";
import { DeoIpCommentsAndChangelogPaper } from "./deo-ip-comments-and-audit-log-paper.component";

interface IGapAnalysisCommentsAndAuditLogPaperProps {
  organizationId: string;
  relations: ICommentRelations;
  linkMetadata: ICommentLinkMetadata;
  disclosureRequirementInputParameter: IInputParameterWithDisclosureRequirementType | null;
  open: boolean;
  onClose: VoidFunction;
}

export const GapAnalysisCommentsAndChangelogPaper: FC<
  IGapAnalysisCommentsAndAuditLogPaperProps
> = ({
  organizationId,
  relations,
  linkMetadata,
  disclosureRequirementInputParameter,
  open,
  onClose,
}) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={onClose}>
      <DeoIpCommentsAndChangelogPaper
        organizationId={organizationId}
        relations={relations}
        linkMetadata={linkMetadata}
        disclosureRequirementInputParameter={disclosureRequirementInputParameter}
        open={open}
        onClose={onClose}
      />
    </Backdrop>
  );
};
