import { FC, useMemo } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IOrganization, ITargetPath, ITargetPathState } from "@netcero/netcero-core-api-client";
import { TargetPathsEditListComponent } from "./target-paths-edit-list.component";
import { ILocalReferenceBudget } from "../../reference-budgets/interfaces/reference-budgets.interfaces";
import { NoticeComponent } from "../../common/components/notice.component";
import { useTargetPathDialogs } from "../hooks/target-path-dialogs.hook";
import { TargetPathProgressComponent } from "./target-path-progress.component";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { NavItemUrlUtilities } from "../../common/utilities/nav-item-url.utilities";

export interface ITargetPathsConfigurationComponentProps {
  organization: IOrganization;
  referenceBudgets: ILocalReferenceBudget[];
  targetPaths: ITargetPath[];
  recordingPeriods: ILocalRecordingPeriod[];
}

export const TargetPathsConfigurationComponent: FC<ITargetPathsConfigurationComponentProps> = ({
  targetPaths,
  organization,
  referenceBudgets,
  recordingPeriods,
}) => {
  const { t } = useTranslation("target_paths_configuration_component");

  const { render, handlers, state } = useTargetPathDialogs(
    organization,
    referenceBudgets,
    targetPaths,
  );

  const currentOrganizationTargetPath = useMemo(
    () => targetPaths.find((t) => t.state === ITargetPathState.Committed) ?? null,
    [targetPaths],
  );

  return referenceBudgets.length === 0 ? (
    <NoticeComponent
      text={t("notice_no_reference_budget.text")}
      buttonText={t("notice_no_reference_budget.button_text")}
      buttonLink={NavItemUrlUtilities.getReferenceBudgetsPageUrl(organization.id)}
    />
  ) : (
    <Box>
      {/* Dialogs */}
      {render.renderAllDialogs()}

      <Box display="flex" flexDirection="column" gap={2} px={2} alignItems="flex-start">
        {/* Display current organization target path */}
        {currentOrganizationTargetPath !== null && (
          <Card sx={{ width: "100%" }}>
            <CardContent>
              <Box display="flex" flexDirection="column" gap={3} px={1} py={0.5}>
                {/*  */}
                <Typography variant="h5">{t("subheaders.current_organization_path")}</Typography>

                <TargetPathsEditListComponent
                  hideUnavailableActions
                  organizationId={organization.id}
                  targetPaths={[currentOrganizationTargetPath]}
                  onEdit={(rb) => {
                    handlers.openEditDialog({ targetPath: rb });
                  }}
                  onToggleCommit={(rb) => {
                    handlers.openToggleDialog({
                      targetPath: rb,
                      // we know that the current one is the organization path
                      currentOrganizationTargetPath: null,
                    });
                  }}
                  onDelete={(rb) => {
                    handlers.openDeleteDialog({ targetPath: rb });
                  }}
                  onClone={(tp) => {
                    handlers.openCloneDialog({ targetPath: tp });
                  }}
                  disabled={state.isLoading}
                />

                <TargetPathProgressComponent
                  currentOrganizationTargetPath={currentOrganizationTargetPath}
                  allRecordingPeriods={recordingPeriods}
                  organization={organization}
                />
              </Box>
            </CardContent>
          </Card>
        )}

        {/* Display paths in progress*/}
        <Typography variant="h5" mt={2}>
          {t("subheaders.paths_in_progress")}
        </Typography>

        {/* Create Button */}
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            handlers.openCreateDialog();
          }}
          disabled={state.isLoading}
        >
          {t("buttons.create")}
        </Button>

        {/* List of target paths in progress */}
        {targetPaths.length === 0 ? (
          <Typography>{t("empty_notice")}</Typography>
        ) : (
          <TargetPathsEditListComponent
            organizationId={organization.id}
            targetPaths={targetPaths.filter((t) => t.state === ITargetPathState.Draft)}
            onEdit={(rb) => {
              handlers.openEditDialog({ targetPath: rb });
            }}
            onToggleCommit={(rb) => {
              const currentTargetPath =
                targetPaths.find((t) => t.state === ITargetPathState.Committed && t.id !== rb.id) ??
                null;
              handlers.openToggleDialog({
                targetPath: rb,
                currentOrganizationTargetPath: currentTargetPath,
              });
            }}
            onDelete={(rb) => {
              handlers.openDeleteDialog({ targetPath: rb });
            }}
            onClone={(tp) => {
              handlers.openCloneDialog({ targetPath: tp });
            }}
            disabled={state.isLoading}
          />
        )}
      </Box>
    </Box>
  );
};
