import { IMdrUnit } from "@netcero/netcero-core-api-client";
import { MdrUnitGroup } from "./mdr-unit.public-types";

export const ORDERED_MDR_UNITS_DIMENSIONLESS = [IMdrUnit.None] as const;

export const ORDERED_MDR_UNITS_MASS = [IMdrUnit.G, IMdrUnit.Kg, IMdrUnit.T] as const;

export const ORDERED_MDR_UNITS_ENERGY = [
  IMdrUnit.J,
  IMdrUnit.KJ,
  IMdrUnit.Mj,
  IMdrUnit.KWh,
  IMdrUnit.Mwh,
  IMdrUnit.Gwh,
  IMdrUnit.Twh,
] as const;

export const ORDERED_MDR_UNITS_ENERGY_PER_MONETARY = [
  IMdrUnit.JPerMmEuro,
  IMdrUnit.KJPerMmEuro,
  IMdrUnit.MjPerMmEuro,
  IMdrUnit.KWhPerMmEuro,
  IMdrUnit.MwhPerMmEuro,
  IMdrUnit.GwhPerMmEuro,
  IMdrUnit.TwhPerMmEuro,
] as const;

export const ORDERED_MDR_UNITS_GHG_EMISSIONS = [
  IMdrUnit.TCo2e,
  IMdrUnit.KtCo2e,
  IMdrUnit.MtCo2e,
  IMdrUnit.GtCo2e,
] as const;

export const ORDERED_MDR_UNITS_GHG_EMISSIONS_PER_MONETARY = [
  IMdrUnit.TCo2ePerMmEuro,
  IMdrUnit.KtCo2ePerMmEuro,
  IMdrUnit.MtCo2ePerMmEuro,
  IMdrUnit.GtCo2ePerMmEuro,
] as const;

export const ORDERED_MDR_UNITS_VOLUME = [IMdrUnit.L, IMdrUnit.M3] as const;

export const ORDERED_MDR_UNITS_VOLUME_PER_MONETARY = [
  IMdrUnit.LPerMmEuro,
  IMdrUnit.M3PerMmEuro,
] as const;

export const ORDERED_MDR_UNITS_AREA = [IMdrUnit.M2, IMdrUnit.Km2, IMdrUnit.Ha] as const;

export const ORDERED_MDR_UNITS_MONETARY = [IMdrUnit.Euro] as const;

export const DEFAULT_MDR_UNITS_BY_TYPE: Record<MdrUnitGroup, IMdrUnit> = {
  dimensionless: IMdrUnit.None,
  mass: IMdrUnit.Kg,
  energy: IMdrUnit.KWh,
  energy_per_monetary: IMdrUnit.KWhPerMmEuro,
  ghg_emissions: IMdrUnit.TCo2e,
  ghg_emissions_per_monetary: IMdrUnit.TCo2ePerMmEuro,
  volume: IMdrUnit.L,
  volume_per_monetary: IMdrUnit.LPerMmEuro,
  area: IMdrUnit.M2,
  monetary: IMdrUnit.Euro,
};

export const ORDERED_MDR_UNITS_BY_TYPE: Record<MdrUnitGroup, readonly IMdrUnit[]> = {
  dimensionless: ORDERED_MDR_UNITS_DIMENSIONLESS,
  mass: ORDERED_MDR_UNITS_MASS,
  energy: ORDERED_MDR_UNITS_ENERGY,
  energy_per_monetary: ORDERED_MDR_UNITS_ENERGY_PER_MONETARY,
  ghg_emissions: ORDERED_MDR_UNITS_GHG_EMISSIONS,
  ghg_emissions_per_monetary: ORDERED_MDR_UNITS_GHG_EMISSIONS_PER_MONETARY,
  volume: ORDERED_MDR_UNITS_VOLUME,
  volume_per_monetary: ORDERED_MDR_UNITS_VOLUME_PER_MONETARY,
  area: ORDERED_MDR_UNITS_AREA,
  monetary: ORDERED_MDR_UNITS_MONETARY,
};

export const ORDERED_MDR_UNITS_ALL = [
  ...ORDERED_MDR_UNITS_DIMENSIONLESS,
  ...ORDERED_MDR_UNITS_MASS,
  ...ORDERED_MDR_UNITS_ENERGY,
  ...ORDERED_MDR_UNITS_ENERGY_PER_MONETARY,
  ...ORDERED_MDR_UNITS_GHG_EMISSIONS,
  ...ORDERED_MDR_UNITS_GHG_EMISSIONS_PER_MONETARY,
  ...ORDERED_MDR_UNITS_VOLUME,
  ...ORDERED_MDR_UNITS_VOLUME_PER_MONETARY,
  ...ORDERED_MDR_UNITS_AREA,
  ...ORDERED_MDR_UNITS_MONETARY,
] as const;
