import { FC, useLayoutEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

interface IAutoFocusPluginProps {}

export const AutoFocusPlugin: FC<IAutoFocusPluginProps> = () => {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    // setTimeout is needed to ensure that the editor has already updated its content before focusing
    setTimeout(() => {
      editor.focus(undefined, { defaultSelection: "rootEnd" });
    }, 10);
  }, [editor]);

  return null;
};
