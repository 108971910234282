import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import { PlusIcon } from "../../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { useInitEsrsRecording } from "../../recording-model-template-groups/hooks/use-init-esrs-recording.hook";
import { InitEsrsConfirmDialog } from "../../recording-model-template-groups/components/init-esrs-confirm-dialog.component";
import { IRecordingPeriodIdentity } from "@netcero/netcero-common";

interface IGapAnalysisLoadMissingEsrsTopicsNoticeProps {
  recordingPeriodIdentity: IRecordingPeriodIdentity;
  noticeText?: string;
}

export const GapAnalysisLoadMissingEsrsTopicsNotice: FC<
  IGapAnalysisLoadMissingEsrsTopicsNoticeProps
> = ({ recordingPeriodIdentity, noticeText }) => {
  const { t } = useTranslation("gap_analysis");

  const {
    showConfirmInitEsrs,
    initEsrsRecordingMutation,
    handleInitEsrsRecording,
    setShowConfirmInitEsrs,
  } = useInitEsrsRecording(
    recordingPeriodIdentity.organizationId,
    recordingPeriodIdentity.recordingPeriodId,
  );

  return (
    <>
      {/* Confirm Start ESRS Dialog */}
      <InitEsrsConfirmDialog
        open={showConfirmInitEsrs}
        initEsrsRecordingMutation={initEsrsRecordingMutation}
        onClose={handleInitEsrsRecording}
      />
      <Box textAlign="center">
        <Typography my={4} textAlign="center">
          {noticeText ?? t("missing_esrs_topics")}
        </Typography>

        <Button
          variant="outlined"
          onClick={() => setShowConfirmInitEsrs(true)}
          startIcon={<PlusIcon />}
        >
          <Typography color="primary">{t("add_all_esrs_topics_button")}</Typography>
        </Button>
      </Box>
    </>
  );
};
