import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { ITopBarConfiguration, ITopBarProps, TopBar } from "../components/top-bar.component";

interface ITopBarContext {
  topBarConfiguration: ITopBarConfiguration;
  updateTopBarConfiguration: (topBarConfiguration: ITopBarConfiguration) => void;
}
const InternalTopBarContext = createContext<ITopBarContext>({
  topBarConfiguration: {},
  updateTopBarConfiguration: () => {},
});

interface ITopBarContextProps {
  children: ReactNode;
}
export const TopBarContext: FC<ITopBarContextProps> = ({ children }) => {
  const [topBarConfiguration, updateTopBarConfiguration] = useState<ITopBarConfiguration>({});

  return (
    <InternalTopBarContext.Provider
      value={{
        topBarConfiguration,
        updateTopBarConfiguration,
      }}
      children={children}
    />
  );
};

export const useTopBarContext = () => useContext(InternalTopBarContext);

type IContextBasedTopBarProps = Omit<ITopBarProps, "configuration">;
export const ContextBasedTopBar: FC<IContextBasedTopBarProps> = ({ ...props }) => {
  const { topBarConfiguration } = useTopBarContext();

  return <TopBar {...props} configuration={topBarConfiguration} />;
};

/**
 * Hook to update the Application TopBar configuration. This hook will also reset the TopBar configuration when the component unmounts.
 * @param topBarConfiguration Configuration for the TopBar. Should probably be wrapped by a useMemo to avoid unnecessary re-renders or re-render loops.
 */
export const useCustomTopBarContent = (topBarConfiguration: ITopBarConfiguration) => {
  const { updateTopBarConfiguration } = useTopBarContext();

  useEffect(() => {
    updateTopBarConfiguration(topBarConfiguration);
  }, [topBarConfiguration, updateTopBarConfiguration]);
};
