import {
  IDataEntryObject,
  IOrganizationStructureDetailed,
  IOrganizationStructureDetailedOneOf,
} from "@netcero/netcero-core-api-client";
import { FC, ReactNode } from "react";
import { OrganizationStructureIsDraftNotice } from "./organization-structure-is-draft-notice.components";

interface IOrganizationStructureDraftWrapperProps {
  organizationId: string;
  recordingPeriodId: string;
  organizationStructure: IOrganizationStructureDetailed;
  build: (structure: IDataEntryObject) => ReactNode;
  // If set it must NOT be undefined (use null instead)
  draftNoticeOverride?: ReactNode;
}

/**
 * Only calls the build function if the organization structure is not a draft.
 * Otherwise, displays the OrganizationStructureIsDraftNotice.
 * @param organizationId Organization the structure belongs to
 * @param recordingPeriodId Recording Period the structure belongs to
 * @param organizationStructure Organization Structure to display if not draft
 * @param build
 * @param draftNoticeOverride If set it must NOT be undefined (use null instead)
 */
export const OrganizationStructureDraftWrapper: FC<IOrganizationStructureDraftWrapperProps> = ({
  organizationId,
  recordingPeriodId,
  organizationStructure,
  build,
  draftNoticeOverride,
}) => {
  return organizationStructure.isDraft ? (
    draftNoticeOverride !== undefined ? (
      draftNoticeOverride
    ) : (
      <OrganizationStructureIsDraftNotice
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
      />
    )
  ) : (
    build((organizationStructure as IOrganizationStructureDetailedOneOf).structure)
  );
};
