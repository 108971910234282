import { FC, useMemo } from "react";
import { Box, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { UserAvatarWithQuery } from "../../user/components/user-avatar.component";
import { useGetSingleUserQueryInternal } from "../../user/users.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { UserFullName } from "../../user/components/user-full-name.component";
import { ILocalComment } from "../comments.types";
import { HumanReadableDateOrDiff } from "../../date-format/components/human-readable-date-or-diff.component";
import { LexicalRichTextRenderer } from "../../lexical/lexical-rich-text-renderer.component";
import { DeleteIcon } from "../../common/constants/tabler-icon.constants";
import { CommentUtilities } from "@netcero/netcero-common";
import {
  CopyToClipboardButton,
  CopyToClipboardButtonAnimationProps,
} from "../../common/components/copy-to-clipboard-button.component";
import { useTranslation } from "react-i18next";

const COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME = "copy-to-clipboard-button";
const BUTTON_ANIMATION_STYLES = {
  ...CopyToClipboardButtonAnimationProps(`.${COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}`),
  ...CopyToClipboardButtonAnimationProps(".comment_actions"),
};

interface ICommentProps {
  organizationId: string;
  comment: ILocalComment;
  onDelete?: (comment: ILocalComment) => void;
  isFocused?: boolean;
}

export const Comment: FC<ICommentProps> = ({ organizationId, comment, onDelete, isFocused }) => {
  const { t } = useTranslation("comments");

  const userQuery = useGetSingleUserQueryInternal(organizationId, comment.createdByUserId);

  const deepLink = useMemo(
    () =>
      comment.linkMetadata
        ? CommentUtilities.generateEntityLinkForComment(
            window.location.origin,
            comment.linkMetadata,
            comment.id,
          ).toString()
        : null,
    [comment],
  );

  const anyActionsAllowed = useMemo(() => !!onDelete, [onDelete]);

  return (
    <Box
      id={comment.id}
      display="flex"
      gap={1}
      p={0.5}
      borderRadius={2}
      sx={{
        backgroundColor: isFocused ? (theme) => theme.palette.grey[50] : undefined,
        ...BUTTON_ANIMATION_STYLES,
      }}
    >
      {/* User Avatar */}
      <UserAvatarWithQuery
        organizationId={organizationId}
        userId={comment.createdByUserId}
        avatarDiameter={30}
      />
      {/* Main Container */}
      <Box>
        {/* Username + Time/Date + Deep Link Copy */}
        <Box display="flex" alignItems="center" gap={1}>
          {/* Username */}
          <QueryWrapper
            query={userQuery}
            loadingOverride={() => <Skeleton variant="text" width="20ch" />}
            build={(user) => (
              <Typography fontSize="14px !important" fontWeight="500">
                <UserFullName user={user} />
              </Typography>
            )}
          />
          {/* Date Time */}
          <Typography variant="commentDateTime">
            <HumanReadableDateOrDiff date={comment.createdAt} />
          </Typography>
          {/* Copy Deep Link Icon */}
          {deepLink && (
            <Box className={COPY_TO_CLIPBOARD_BUTTON_CLASS_NAME}>
              <CopyToClipboardButton
                size="small"
                value={deepLink}
                tooltip={t("tooltip_copy_link", { ns: "buttons" })}
                tooltipSuccess={t("tooltip_copy_link_success", { ns: "buttons" })}
                tooltipPlacement="top"
                iconProps={{ size: 16 }}
              />
            </Box>
          )}
          {/* Actions */}
          {anyActionsAllowed && (
            <Box display="flex" className="comment_actions" ml={-1}>
              {onDelete && (
                <Tooltip title={t("tooltip_delete_button")} placement="top">
                  <IconButton size="small" onClick={() => onDelete(comment)}>
                    <DeleteIcon height={16} width={16} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </Box>
        {/* Content */}
        <Box sx={{ fontSize: "14px !important" }}>
          <LexicalRichTextRenderer variant="comment" content={comment.content} />
        </Box>
      </Box>
    </Box>
  );
};
