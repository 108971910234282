import { FC } from "react";
import { Box } from "@mui/material";
import { CUSTOM_ON_OVERLAY_COLOR } from "../../../theme/theme";

export const CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES = {
  position: "relative",
} as const;

interface IClickableIndicatorWhiteOverlayProps {
  disabled?: boolean;
}

/**
 * DEV-NOTE: Please take a look at `CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES` - you will probably need to apply it to the parent component.
 * @param disabled
 */
export const ClickableIndicatorWhiteOverlay: FC<IClickableIndicatorWhiteOverlayProps> = ({
  disabled,
}) => {
  return disabled ? null : (
    <Box
      sx={{
        cursor: "pointer",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        backgroundColor: CUSTOM_ON_OVERLAY_COLOR,
        opacity: 0,
        transition: "opacity 150ms",
        ":hover": {
          opacity: 1,
        },
      }}
    />
  );
};
