import { useResetMutationsOnOpen } from "../../common/hooks/use-reset-mutations-on-open.hook";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { useInitEsrsRecordingMutation } from "../../double-materiality-assessment/mutations/dma.mutations";
import { useCallback, useMemo, useState } from "react";
import {
  BasicSnackbarApiActionType,
  IAppSnackbarEnqueueApiMessageAction,
} from "../../app-snackbar/app-snackbar.interfaces";

const INIT_ALL_ESRS_TOPICS_ACTION: IAppSnackbarEnqueueApiMessageAction = {
  type: BasicSnackbarApiActionType.INIT_ALL_ESRS_TOPICS,
};

export const useInitEsrsRecording = (organizationId: string, recordingPeriodId: string) => {
  const { wrapApiPromise } = useAppSnackbar();
  const [showConfirmInitEsrs, setShowConfirmInitEsrs] = useState(false);
  const initEsrsRecordingMutation = useInitEsrsRecordingMutation();
  const handleInitEsrsRecording = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        await wrapApiPromise(
          initEsrsRecordingMutation.mutateAsync({
            organizationId,
            recordingPeriodId,
          }),
          INIT_ALL_ESRS_TOPICS_ACTION,
        );
      }
      setShowConfirmInitEsrs(false);
    },
    [initEsrsRecordingMutation.mutateAsync, organizationId, recordingPeriodId, wrapApiPromise],
  );
  useResetMutationsOnOpen(showConfirmInitEsrs, initEsrsRecordingMutation);

  return useMemo(() => {
    return {
      showConfirmInitEsrs,
      initEsrsRecordingMutation,
      handleInitEsrsRecording,
      setShowConfirmInitEsrs,
    };
  }, [
    showConfirmInitEsrs,
    initEsrsRecordingMutation,
    handleInitEsrsRecording,
    setShowConfirmInitEsrs,
  ]);
};
