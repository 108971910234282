import { FC } from "react";
import { Box, Typography } from "@mui/material";

interface ITopBarPickerProps {
  label: string;
  value: string;
  startIcon: React.ReactNode;
  arrowIcon: React.ReactNode;
  onClick: () => void;
}

export const TopBarPicker: FC<ITopBarPickerProps> = ({
  label,
  value,
  startIcon,
  arrowIcon,
  onClick,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={1}
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      {startIcon}
      <Box>
        <Typography
          variant="body2"
          sx={{ fontSize: "12px !important", fontWeight: 600, opacity: 0.54 }}
        >
          {label}
        </Typography>
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography variant="body2" sx={{ fontSize: "14px !important", fontWeight: 500 }}>
            {value}
          </Typography>
          {arrowIcon}
        </Box>
      </Box>
    </Box>
  );
};
