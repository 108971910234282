import { IContentLanguage } from "@netcero/netcero-core-api-client";
import { ESRS_CATEGORIES } from "./esrs-dma-categories.constant";
import { MappingUtilities, RecursiveUtilities } from "../../common";
import { ESRSTopicIdentifier } from "../../esrs-topics";

export const generateDMACategoryTranslations = (language: IContentLanguage) => {
  const flat = ESRS_CATEGORIES.flatMap((c) => c.children).flatMap((c) =>
    RecursiveUtilities.flattenRecursiveStructureDown(c),
  );
  return flat.reduce((acc, curr) => {
    const value = curr.name[language];

    if (value === undefined) {
      // eslint-disable-next-line
      console.error(`Translation for ${language} not found for DMA category ${curr.identifier}!`);
    } else {
      acc[curr.identifier] = value;
    }

    return acc;
  }, {} as Record<string, string>);
};

export const generateESRSTopicTranslations = (language: IContentLanguage) => {
  return ESRS_CATEGORIES.reduce((acc, curr) => {
    const value = curr.name[language];

    if (value === undefined) {
      // eslint-disable-next-line
      console.error(`Translation for ${language} not found for DMA category ${curr.identifier}!`);
    } else {
      acc[curr.identifier] = value;
    }

    return acc;
  }, {} as Record<string, string>);
};

export const getESRSTopicTranslationsForKey = (key: ESRSTopicIdentifier) => {
  return ESRS_CATEGORIES.find((category) => category.identifier === key)?.name;
};

export const getESRSSubTopicTranslations = (
  topicIdentifier: ESRSTopicIdentifier,
  subTopicIdentifier: string,
) => {
  const topic = ESRS_CATEGORIES.find((c) => c.identifier === topicIdentifier);
  return MappingUtilities.mapIfNotUndefined(topic, (value) => {
    const subTopic = value.children.find((category) => category.identifier === subTopicIdentifier);
    return MappingUtilities.mapIfNotUndefined(subTopic, (value) => {
      return value.name;
    });
  });
};
