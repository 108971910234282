import { FC, ReactNode } from "react";
import { IInputParameterRecordingStructureBase } from "@netcero/netcero-core-api-client";
import { GapAnalysisLoadMissingEsrsTopicsNotice } from "./gap-analysis-load-missing-esrs-topics-notice.component.ts";
import { RecordingModelTemplateGroupsUtilities } from "../../recording-model-template-groups/utilities/recording-model-template-groups.utilities";
import { IRecordingPeriodIdentity } from "@netcero/netcero-common";

interface IGapAnalysisMissingEsrsTopicsWrapperProps {
  esrsRecordingStructures: IInputParameterRecordingStructureBase[];
  recordingPeriodIdentity: IRecordingPeriodIdentity;
  build: (recordingStructures: IInputParameterRecordingStructureBase[]) => ReactNode;
}

export const GapAnalysisMissingEsrsTopicsWrapper: FC<IGapAnalysisMissingEsrsTopicsWrapperProps> = ({
  esrsRecordingStructures,
  recordingPeriodIdentity,
  build,
}) => {
  // Show notice if not all ESRS structures have been added yet
  return RecordingModelTemplateGroupsUtilities.hasMissingEsrsRecordingStructures(
    esrsRecordingStructures,
  ) ? (
    <GapAnalysisLoadMissingEsrsTopicsNotice recordingPeriodIdentity={recordingPeriodIdentity} />
  ) : (
    build(esrsRecordingStructures)
  );
};
