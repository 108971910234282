import { FC } from "react";
import { SerializedEditorState } from "lexical";
import { LexicalBaseEditor } from "./lexical-base-editor.component";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { SubscribeToContent } from "./plugins/subscribe-to-content.component";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { ILexicalEditorCommonProps } from "./lexical.types";

interface ILexicalRichTextRendererProps extends ILexicalEditorCommonProps {
  content: SerializedEditorState;
}

export const LexicalRichTextRenderer: FC<ILexicalRichTextRendererProps> = ({
  content,
  ...commonProps
}) => {
  return (
    <LexicalBaseEditor {...commonProps}>
      <div className="editor-inner readonly">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-display readonly" />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <SubscribeToContent content={content} />
      </div>
    </LexicalBaseEditor>
  );
};
