import { FormControl, FormHelperText, TextField } from "@mui/material";
import { IInputParameterValueMetaDataText } from "@netcero/netcero-core-api-client";
import { FC, FocusEvent, FocusEventHandler } from "react";
import { useTranslation } from "react-i18next";
import {
  DataEntryObjectInputParameterValueDefinitionForText,
  DataEntryObjectInputParameterValuesVerification,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { ICommonInputProps } from "../value-acquisition.types";

type ITextInputComponentProps = ICommonInputProps<
  IInputParameterValueMetaDataText,
  DataEntryObjectInputParameterValueDefinitionForText
>;

export const TextInputComponent: FC<ITextInputComponentProps> = ({
  variant,
  value,
  onChange,
  onSubmit,
  disabled,
  error,
  metaData,
  label,
  required,
  disableMaxWidth,
  autoFocus,
}) => {
  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const typedValue = event.currentTarget.value as string;
    onChange(
      DataEntryObjectInputParameterValuesVerification.sanitizeValue(typedValue, metaData, false),
    );
  };

  return (
    <SingleTextInputComponent
      variant={variant}
      value={value}
      onChange={onChange}
      onBlur={handleBlur}
      onSubmit={onSubmit}
      disabled={disabled}
      error={error}
      label={label}
      required={required}
      disableMaxWidth={disableMaxWidth}
      autoFocus={autoFocus}
    />
  );
};

// Internal Components
// TODO @marckornberger we can probably get rid of the layer of indirection now :pray:

interface ISingleTextInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForText>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForText) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onSubmit?: () => void;
  disabled?: boolean;
  error?: string;
  hideError?: boolean;
  label?: string;
  required?: boolean;
  disableMaxWidth?: boolean;
  autoFocus?: boolean;
}

const SingleTextInputComponent: FC<ISingleTextInputComponentProps> = ({
  variant,
  value,
  onChange,
  onBlur,
  onSubmit,
  disabled,
  error,
  hideError,
  label,
  required,
  disableMaxWidth,
  autoFocus,
}) => {
  const { t } = useTranslation("text_input");
  const stylesSx = useVariantSxStyles(variant);
  const inputVariantProps = useVariantFormProps(variant);

  return (
    <FormControl
      {...inputVariantProps}
      error={!!error}
      required={required}
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined }}
      fullWidth
    >
      <TextField
        required={required}
        label={label}
        {...inputVariantProps}
        placeholder={t("placeholder")}
        value={value ?? ""}
        disabled={disabled}
        error={!!error}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        autoFocus={autoFocus}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter") {
            onSubmit?.();
          }
        }}
        InputProps={{
          sx: stylesSx,
        }}
      />
      {error && !hideError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
