import { ReferenceBudgetValues } from "./reference-budget.public-types";
import { SumPerScope } from "./reference-budget.types";
import { DateTime } from "luxon";
import { IReferenceBudgetStoredValuesCalculations } from "@netcero/netcero-core-api-client";
import { ReferenceBudgetUtilities } from "./reference-budget.utilities";
import { ResultPerCalculationKey } from "../calculation";
import { ObjectUtilities } from "../common/utilities/object.utilities";

export class ReferenceBudgetCalculations {
  private static AMOUNT_OF_DAYS_IN_YEAR = 365;

  /**
   * This method aggregates all scoped values (= values prop in total of RP) across all specified recording periods.
   * @param values The recording periods to aggregate
   * @private
   * @returns A record where the key is the scope, the value is the sum of all RPs that have this scope
   */
  private static combineScopeValues(values: ReferenceBudgetValues) {
    const combinedValues: SumPerScope = {};
    for (const value of Object.values(values)) {
      this.addAllValuesOfDEOToCombined(combinedValues, value.total as ResultPerCalculationKey);
    }

    return combinedValues;
  }

  /**
   * This method adds the single values of every scope to the global result, ensuring that an entry for the given
   * key is present beforehand.
   * @param combined The global result to add the keys to
   * @param values The values to add
   * @private
   */
  private static addAllValuesOfDEOToCombined(
    combined: SumPerScope,
    values: ResultPerCalculationKey,
  ) {
    for (const [key, valueForKey] of Object.entries(
      ObjectUtilities.objectToRecord<number>(values),
    )) {
      if (combined[key] === undefined) {
        combined[key] = 0;
      }
      combined[key] += valueForKey;
    }
    return combined;
  }

  /**
   * This method calculates the total amount of days across all specified recording periods
   * @param values The recording periods to aggregate
   * @private
   */
  private static calculateTotalAmountOfDaysInRecordingPeriods(values: ReferenceBudgetValues) {
    return Object.values(values).reduce(
      (totalDays, value) =>
        totalDays +
        DateTime.fromISO(value.endDate).diff(DateTime.fromISO(value.startDate), "days").days,
      0,
    );
  }

  /**
   * This method "normalizes" the values for each scope by calculating the value for a single year for the given scope.
   * @param sumsPerScope The values to normalize
   * @param totalAmountOfDays The total amount of days across all recording periods
   * @private
   * @returns The normalized values
   */
  private static normalizeValues(
    sumsPerScope: SumPerScope,
    totalAmountOfDays: number,
  ): SumPerScope {
    const normalizedSumsPerScope: SumPerScope = {};

    for (const [key, value] of Object.entries(
      ObjectUtilities.objectToRecord<number>(sumsPerScope),
    )) {
      normalizedSumsPerScope[key] = (value / totalAmountOfDays) * this.AMOUNT_OF_DAYS_IN_YEAR;
    }

    return normalizedSumsPerScope;
  }

  /**
   * This method calculates the sum of all values of the given scope
   * @param values The values to calculate the sum for
   * @private
   */
  private static calculateSumOfValues(values: SumPerScope): number {
    return Object.values(ObjectUtilities.objectToRecord<number>(values)).reduce(
      (sum, value) => sum + value,
      0,
    );
  }

  /**
   * This method performs all calculations which are required to appropriately represent the values in the frontend. More specifically, it:
   * Determines the sum for each scope across all recording periods
   * Determines the total amount of days within all recording periods
   * Normalizes the sum for each scope using the total amount of days
   * Calculates the sum of all normalized values
   * Creates the RP dtos without the rootValuesForDEO / total
   * @param values The values to perform calculations on
   */
  public static calculateValues(
    values: ReferenceBudgetValues,
  ): IReferenceBudgetStoredValuesCalculations {
    const sumsPerScope = this.combineScopeValues(values);
    const totalAmountOfDays = this.calculateTotalAmountOfDaysInRecordingPeriods(values);
    const normalizedSumsPerScope = this.normalizeValues(sumsPerScope, totalAmountOfDays);

    return {
      sum: this.calculateSumOfValues(normalizedSumsPerScope),
      sumsPerScope: normalizedSumsPerScope,
      recordingPeriods: ReferenceBudgetUtilities.mapReferenceBudgetValuesToRecordingPeriods(values),
    };
  }
}
