import { Box, CircularProgress } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { useCurrentTargetPath } from "../../modules/target-path-goals/hooks/use-current-target-path.hook";
import { TargetPathDetailsComponent } from "../../modules/target-path-goals/components/target-path-details.component";
import { ReferenceRevenueIn } from "../../modules/target-paths/queries/target-paths.queries";
import { useReferenceBudgetsQuery } from "../../modules/reference-budgets/queries/reference-budgets.queries";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";

interface ITargetPathDetailsPageProps {}

export const TargetPathDetailsPage: FC<ITargetPathDetailsPageProps> = () => {
  const { organizationId } = useParams();

  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(NavItemUrlUtilities.getTargetPageUrl(orgId));
            },
          }
        : undefined,
      navigationItems: {
        activePath: NavItemUrlUtilities.getTargetPageUrl(organizationId!),
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(NavItemUrlUtilities.getTargetPageUrl(orgId));
            },
          }
        : undefined,
    }),
    [organizationId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  const currentOrganization = useCurrentOrganization();
  const referenceBudgetsQuery = useReferenceBudgetsQuery(organizationId!);
  const currentTargetPath = useCurrentTargetPath(ReferenceRevenueIn.Euros);

  return (
    <Box width="100%" maxWidth="100%" mx="auto">
      {currentTargetPath === null ||
      currentOrganization === null ||
      referenceBudgetsQuery.isLoading ? (
        <CircularProgress /> // TODO: Add loading skeleton
      ) : (
        // TODO: maybe add error handling for target paths that can not be found
        <TargetPathDetailsComponent
          targetPath={currentTargetPath.targetPath}
          organizationTargetPath={currentTargetPath.currentOrganizationTargetPath}
          organization={currentOrganization}
          availableReferenceBudgets={referenceBudgetsQuery.data!.referenceBudgets}
          allTargetPaths={currentTargetPath.allTargetPaths}
        />
      )}
    </Box>
  );
};
