import { TableCellProps } from "@mui/material";
import { IInputParameterValueMetaData } from "@netcero/netcero-core-api-client";

export const COLUMN_ALIGNMENTS_BY_TYPE: Record<
  IInputParameterValueMetaData["type"],
  TableCellProps["align"]
> = {
  text: "left",
  "text-area": "left",
  boolean: "center",
  number: "right",
  currency: "right",
  date: "right",
  "date-range": "right",
  year: "right",
  options: "center",
  "nested-options": "center",
  "value-with-unit": "right",
  // Actually Unsupported
  policy: "right",
  action: "right",
  target: "right",
  "referenced-target": "left",
};
