import {
  IDataEntryObjectInputParameterValueValueForKey,
  IInputParameterValueMetaData,
  IInputParameterValueMetaDataValueWithUnitGroup,
} from "@netcero/netcero-core-api-client";
import { SerializedEditorState } from "lexical";

export type DataEntryObjectInputParameterValueType = IInputParameterValueMetaData["type"];

export type DataEntryObjectInputParameterValueValuesPerKey = Record<
  string,
  IDataEntryObjectInputParameterValueValueForKey
>;

export type OptionalDefinition<R> = R | undefined;

export type OptionalDataEntryObjectInputParameterValueDefinition =
  OptionalDefinition<DataEntryObjectInputParameterValueDefinition>;

export type DataEntryObjectInputParameterValueDefinition =
  | DataEntryObjectInputParameterValueDefinitionForNumber
  | DataEntryObjectInputParameterValueDefinitionForBoolean
  | DataEntryObjectInputParameterValueDefinitionForOptions
  | DataEntryObjectInputParameterValueDefinitionForNestedOptions
  | DataEntryObjectInputParameterValueDefinitionForText
  | DataEntryObjectInputParameterValueDefinitionForDate
  | DataEntryObjectInputParameterValueDefinitionForDateRange
  | DataEntryObjectInputParameterValueDefinitionForCurrency
  | DataEntryObjectInputParameterValueDefinitionForPolicy
  | DataEntryObjectInputParameterValueDefinitionForAction
  | DataEntryObjectInputParameterValueDefinitionForTarget
  | DataEntryObjectInputParameterValueDefinitionForYear
  | DataEntryObjectInputParameterValueDefinitionForTextArea
  | DataEntryObjectInputParameterValueDefinitionForReferencedTarget
  | DataEntryObjectInputParameterValueDefinitionForValueWithUnit;

// The expected values for the respective input types
export type DataEntryObjectInputParameterValueDefinitionForNumber = string;

export type DataEntryObjectInputParameterValueDefinitionForBoolean = boolean;

export type DataEntryObjectInputParameterValueDefinitionForOptions =
  | DataEntryObjectInputParameterValueDefinitionForOptionsSingle
  | DataEntryObjectInputParameterValueDefinitionForOptionsMultiple;
export type DataEntryObjectInputParameterValueDefinitionForOptionsSingle = string;
export type DataEntryObjectInputParameterValueDefinitionForOptionsMultiple = string[];

export type DataEntryObjectInputParameterValueDefinitionForNestedOptions =
  | DataEntryObjectInputParameterValueDefinitionForNestedOptionsSingle
  | DataEntryObjectInputParameterValueDefinitionForNestedOptionsMultiple;
export type DataEntryObjectInputParameterValueDefinitionForNestedOptionsSingle = string;
export type DataEntryObjectInputParameterValueDefinitionForNestedOptionsMultiple = string[];

export type DataEntryObjectInputParameterValueDefinitionForText = string;

export type DataEntryObjectInputParameterValueDefinitionForDate = string;

export type DataEntryObjectInputParameterValueDefinitionForDateRange = [string, string];

export type DataEntryObjectInputParameterValueDefinitionForCurrency = string;

export type DataEntryObjectInputParameterValueDefinitionForPolicy = boolean;
export type DataEntryObjectInputParameterValueDefinitionForAction = boolean;
export type DataEntryObjectInputParameterValueDefinitionForTarget = boolean;

export type DataEntryObjectInputParameterValueDefinitionForYear = number;

export type DataEntryObjectInputParameterValueDefinitionForTextArea = SerializedEditorState;

export type DataEntryObjectInputParameterValueDefinitionForReferencedTarget = string[];

export enum DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit {
  // Values for mass group
  g = "g",
  kg = "kg",
  t = "t",
  // Values for energy group
  J = "J",
  kJ = "kJ",
  MJ = "MJ",
  kWh = "kWh", // default
  MWh = "MWh",
  GWh = "GWh",
  TWh = "TWh",
  // Values for GHG group
  tCO2e = "tCO2e", // default
  ktCO2e = "ktCO2e",
  MtCO2e = "MtCO2e",
  GtCO2e = "GtCO2e",
  // Values for volume group
  l = "l",
  m3 = "m³", // default
  // Values for area group
  sqm = "m²", // default
  sqkm = "km²",
  ha = "ha",
}

export const DataEntryObjectInputParameterValueDefinitionForValueWithUnitDefaultUnits: Record<
  IInputParameterValueMetaDataValueWithUnitGroup,
  DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit
> = {
  [IInputParameterValueMetaDataValueWithUnitGroup.Mass]:
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.kg,
  [IInputParameterValueMetaDataValueWithUnitGroup.Energy]:
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.kWh,
  [IInputParameterValueMetaDataValueWithUnitGroup.Ghg]:
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.tCO2e,
  [IInputParameterValueMetaDataValueWithUnitGroup.Volume]:
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.m3,
  [IInputParameterValueMetaDataValueWithUnitGroup.Area]:
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.sqm,
};

export const DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnitGroups: Record<
  IInputParameterValueMetaDataValueWithUnitGroup,
  DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit[]
> = {
  [IInputParameterValueMetaDataValueWithUnitGroup.Mass]: [
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.g,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.kg,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.t,
  ],
  [IInputParameterValueMetaDataValueWithUnitGroup.Energy]: [
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.J,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.kJ,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.MJ,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.kWh,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.MWh,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.GWh,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.TWh,
  ],
  [IInputParameterValueMetaDataValueWithUnitGroup.Ghg]: [
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.tCO2e,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.ktCO2e,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.MtCO2e,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.GtCO2e,
  ],
  [IInputParameterValueMetaDataValueWithUnitGroup.Volume]: [
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.l,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.m3,
  ],
  [IInputParameterValueMetaDataValueWithUnitGroup.Area]: [
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.sqm,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.sqkm,
    DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit.ha,
  ],
};

export const DataEntryObjectInputParameterValueDefinitionForValueWithUnitGroupForUnit: Record<
  DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit,
  IInputParameterValueMetaDataValueWithUnitGroup
> = Object.entries(
  DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnitGroups,
).reduce((acc, [key, values]) => {
  const typedKey = key as IInputParameterValueMetaDataValueWithUnitGroup;

  values.forEach((v) => (acc[v] = typedKey));
  return acc;
}, {} as Record<DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit, IInputParameterValueMetaDataValueWithUnitGroup>);

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface DataEntryObjectInputParameterValueDefinitionForValueWithUnit {
  value: number;
  unit: DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit;
}
