import { useParams } from "react-router-dom";
import { useUserContext } from "../user.context";
import { AuthenticationUtilities } from "@netcero/netcero-common";

/**
 * The current organizationId is taken from the URL params (`useParams` hook) and user from the UserContext.
 * @returns The authenticated user's "highest" role in the organization.
 */
export function useAuthenticatedUserOrganizationRole() {
  const { user } = useUserContext();
  const { organizationId } = useParams();

  if (!user || !organizationId) {
    return null;
  }

  return AuthenticationUtilities.getOrgRoleOfUser(user.jwtPayload, organizationId);
}
