import { Dispatch, FC } from "react";
import { IAction } from "@netcero/netcero-core-api-client";
import { useDialogStateWithoutData } from "../../../common/dialogs/dialog-state.hook";
import {
  IMDRSelectDisplayItem,
  MDRSelectDialog,
} from "../../../minimum-disclosure-requirements-common/components/mdr-select.dialog";
import { MDRMultipleSelectChip } from "../../../minimum-disclosure-requirements-common/components/mdr-select-chip.component";

interface IActionsSelectInputProps {
  label: string;
  actions: IAction[];
  actionsIds: string[];
  onChange: Dispatch<string[]>;
}

const toDisplayItem = (action: IAction): IMDRSelectDisplayItem => ({
  title: action.general.name,
  associatedTopics: action.associatedTopics,
  id: action.id,
});

export const ActionsInputSelect: FC<IActionsSelectInputProps> = ({
  label,
  actions,
  actionsIds,
  onChange,
}) => {
  const {
    isOpen: showSelectDialog,
    openDialog: openSelectDialog,
    closeDialog: closeSelectDialog,
  } = useDialogStateWithoutData();

  return (
    <>
      {/* Dialog */}
      <MDRSelectDialog
        open={showSelectDialog}
        title={label}
        onClose={closeSelectDialog}
        items={actions}
        selectedItemIds={actionsIds}
        onChange={onChange}
        toDisplayItem={toDisplayItem}
        selectionMode="multiple"
      />

      {/* Chip Select */}
      <MDRMultipleSelectChip
        label={label}
        items={actions}
        selectedItemIds={actionsIds}
        onSelectClick={openSelectDialog}
      />
    </>
  );
};
