import { OrgRole } from "@netcero/netcero-common";
import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { useAuthenticatedUserOrganizationRole } from "../../modules/user/hooks/authenticated-user-org-role.hook";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const RequireOrganizationAdminOrgRoleLayout: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation("require_organization_role");
  const authenticatedUserOrgRole = useAuthenticatedUserOrganizationRole();
  const isOrganizationAdmin = authenticatedUserOrgRole === OrgRole.ORGANIZATION_ADMIN;

  if (isOrganizationAdmin) {
    return children ? children : <Outlet />;
  }

  return (
    <Typography variant="h3" textAlign="center" mt={10}>
      {t("access_denied")}
    </Typography>
  );
};
