import { FC } from "react";
import { Box, Collapse, Divider, Fade, IconButton, Paper, SxProps, Theme } from "@mui/material";
import { useObserveSizeAsState } from "../../common/hooks/use-observe-size-state.hook";
import { CommentsList } from "../../comments/components/comments-list.component";
import { ICommentLinkMetadata, ICommentRelations } from "@netcero/netcero-core-api-client";
import { CloseIcon } from "../../common/constants/tabler-icon.constants";
import { CommentsButtonWithQuery } from "../../comments/components/comments-button.component";
import { AuditLogsButton } from "../../audit-logging/components/audit-logs-button.component";
import { FeatureFlag } from "../../common/constants/feature-flags.constants";
import { useIsFeatureFlagEnabled } from "../../common/hooks/is-feature-flag-enabled.hook";

export const EDIT_DIALOG_COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH = 450;

export function getDialogSxPropsForEditDialogCommentsAndChangelogPaper(
  open: boolean,
): SxProps<Theme> {
  return {
    pr: open ? `${EDIT_DIALOG_COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH}px` : undefined,
    transition: "margin-right 250ms ease, padding-right 300ms ease !important", // Override the transition property otherwise it won't work
    zIndex: (theme) => theme.zIndex.modal - 1, // Dialog Z-Index - 1
  };
}

interface IEditDialogCommentsAndAuditLogPaperProps {
  open: boolean;
  dialogOpen: boolean;
  organizationId: string;
  relations: ICommentRelations;
  linkMetadata: ICommentLinkMetadata;
  dialogElement: HTMLDivElement | null;
  onClose: VoidFunction;
}

export const EditDialogCommentsAndChangelogPaper: FC<IEditDialogCommentsAndAuditLogPaperProps> = ({
  open,
  dialogOpen,
  organizationId,
  relations,
  linkMetadata,
  dialogElement,
  onClose,
}) => {
  const { width: dialogWidth } = useObserveSizeAsState(dialogElement);
  //TODO: NC-XYZ Clean up
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  return (
    <Box
      sx={{
        position: "absolute",
        top: (theme) => theme.spacing(isFeatureFlagEnabled ? -3.5 : 4),
        left: (theme) =>
          `calc(50% + ${
            dialogWidth / 2 - EDIT_DIALOG_COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH / 2
          }px + ${theme.spacing(isFeatureFlagEnabled ? -2.5 : 2)})`,
        // Dialog Z-Index
        zIndex: (theme) => theme.zIndex.modal,
        pl: `${open ? 0 : EDIT_DIALOG_COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH / 2}px`,
        transition: "padding 300ms ease !important",
      }}
    >
      {/* Normal Collapse Animation when opening and closing sidebar */}
      <Collapse orientation="horizontal" in={open} unmountOnExit timeout={200}>
        {/* Fade out together with dialog */}
        <Fade in={dialogOpen} unmountOnExit>
          <Paper
            variant="elevation"
            sx={{
              height: (theme) => `calc(100dvh - ${theme.spacing(8)})`,
              width: EDIT_DIALOG_COMMENTS_AND_CHANGELOG_SIDE_SECTION_WIDTH,
              borderRadius: 4, // Same as Dialog
            }}
          >
            <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
              {/* Top Bar of Sidebar */}
              <Box display="flex">
                <IconButton onClick={() => onClose()} sx={{ ml: "auto" }}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider />
              {/* Buttons (for Tab Switching) */}
              <Box display="flex" gap={1} p={1}>
                <CommentsButtonWithQuery
                  organizationId={organizationId}
                  relations={relations}
                  onClick={() => {
                    // Will later be used for tab switching (between comments and audit logs)
                  }}
                />
                {/* Audit Logs */}
                <AuditLogsButton organizationId={organizationId} active={false} />
              </Box>
              {/* Sidebar Content (only comments now) */}
              <CommentsList
                organizationId={organizationId}
                relations={relations}
                linkMetadata={linkMetadata}
              />
            </Box>
          </Paper>
        </Fade>
      </Collapse>
    </Box>
  );
};
