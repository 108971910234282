import { FC, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { CAN_UNDO_COMMAND, UNDO_COMMAND } from "lexical";
import { LexicalPriority } from "../../lexical.constants";
import { UndoIcon } from "../../../common/constants/tabler-icon.constants";

interface IUndoButtonProps {
  disabled?: boolean;
}

export const UndoButton: FC<IUndoButtonProps> = ({ disabled }) => {
  const [editor] = useLexicalComposerContext();

  const [canUndo, setCanUndo] = useState(false);

  useEffect(() => {
    return editor.registerCommand(
      CAN_UNDO_COMMAND,
      (payload) => {
        setCanUndo(payload);
        return false;
      },
      LexicalPriority.LOW,
    );
  }, [editor]);

  return (
    <IconButton
      size="small"
      onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
      disabled={!canUndo || disabled}
    >
      <UndoIcon />
    </IconButton>
  );
};
