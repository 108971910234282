import { FC } from "react";
import { Box, Divider } from "@mui/material";

interface IDividerProps {}

export const ToolbarDivider: FC<IDividerProps> = () => {
  return (
    <Box display="flex" justifyContent="center" width={16} alignSelf="stretch">
      <Divider flexItem orientation="vertical" />
    </Box>
  );
};
