import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IRecordingPeriodIdentity } from "@netcero/netcero-common";

export const getTargetsQueryKey = (organizationId: string, recordingPeriodId: string) => [
  "targets",
  organizationId,
  recordingPeriodId,
];

export const useTargetsQuery = ({
  organizationId,
  recordingPeriodId,
}: IRecordingPeriodIdentity) => {
  return useQuery({
    queryKey: getTargetsQueryKey(organizationId, recordingPeriodId),
    queryFn: () =>
      CoreApiService.TargetsApi.getTargets(organizationId, recordingPeriodId)
        .then((req) => req())
        .then((res) => res.data.targets),
  });
};
