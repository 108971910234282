import { FC, useMemo } from "react";
import { IAction, IPolicy } from "@netcero/netcero-core-api-client";
import MultipleSelectChip from "../../common/components/multiple-chip.component";

interface IMDRMultipleSelectChipProps {
  label?: string;
  onSelectClick: VoidFunction;
  items: IAction[] | IPolicy[];
  selectedItemIds: string[];
}

export const MDRMultipleSelectChip: FC<IMDRMultipleSelectChipProps> = ({
  label,
  onSelectClick,
  items,
  selectedItemIds,
}) => {
  const selectedNames = useMemo(
    () =>
      items.filter((item) => selectedItemIds.includes(item.id)).map((item) => item.general.name),
    [items, selectedItemIds],
  );

  return (
    <MultipleSelectChip
      label={label}
      onSelectClick={onSelectClick}
      chipNames={selectedNames}
      fullWidth
      noMargin
    />
  );
};
