import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { FC, ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { IUser } from "./user.interface";
import { IJwtPayload } from "@netcero/netcero-common";
import { EnvironmentUtilities } from "../common/utilities/environment.utilities";

export interface IUserContext {
  user: IUser | null;
  login: (user: IUser) => void;
  logout: () => void;
}

const InternalUserContext = createContext<IUserContext>({
  user: null,
  login: () => {},
  logout: () => {},
});

interface IUserContextProps {
  children: ReactNode;
  onUserChange?: (user: IUser) => void;
  onTokenUpdate?: (token: string) => void;
  onLogout?: () => void;
}

export const UserContext: FC<IUserContextProps> = ({
  children,
  onUserChange,
  onTokenUpdate,
  onLogout,
}) => {
  const { keycloak, initialized } = useKeycloak();
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const fetchUserInfo = async () => {
      const userInfo = await keycloak.loadUserProfile();

      // TODO: Replace once fully implemented
      const deoCountDefault = EnvironmentUtilities.IS_PRODUCTION ? 10 : 100;
      const jwtPayload = keycloak.tokenParsed! as IJwtPayload;
      // Set value to default if not present
      Object.values(jwtPayload.organization_mapping).forEach((orgData) => {
        orgData.attributes.deo_count_max = orgData.attributes.deo_count_max ?? deoCountDefault;
      });

      setUser({
        userProfile: userInfo,
        jwtPayload,
      });
    };

    if (keycloak.authenticated) {
      void fetchUserInfo();
    }

    keycloak.onTokenExpired = () => {
      // TODO: If access token validity differs from 300s, this should probably made configurable via env variable
      void keycloak.updateToken(180);
    };
  }, [keycloak, initialized]);

  const login = useCallback(
    (user: IUser) => {
      setUser(user);
    },
    [setUser],
  );

  const logout = useCallback(() => {
    setUser(null);
    onLogout?.();
    keycloak.clearToken();
  }, [keycloak, onLogout]);

  // Listen for user changes and emit events
  useEffect(() => {
    if (user) {
      onUserChange?.(user);
    } else {
      onLogout?.();
    }
  }, [user, onUserChange, onLogout]);

  // Logout User on 401 Error
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(undefined, (error) => {
      if (error.response?.status === 401) {
        logout();
      }
      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [logout]);

  // Emit onTokenUpdate event
  useEffect(() => {
    if (keycloak.authenticated) {
      onTokenUpdate?.(keycloak.token!);
    }
  }, [keycloak.authenticated, keycloak.token, onTokenUpdate]);

  return (
    <InternalUserContext.Provider
      value={{
        user,
        login,
        logout,
      }}
      children={children}
    />
  );
};

/**
 * User context hook. Context contains user information and login/logout functions.
 * User is null when not logged in.
 * @returns The user context.
 */
export const useUserContext = () => {
  return useContext(InternalUserContext);
};
