import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IGetAuditLogs200Response } from "@netcero/netcero-core-api-client";
import { IUsePaginationStateResult } from "../../pagination/hooks/pagination-state.hook";
import { AuditLoggingEditList } from "./audit-logging-edit-list.component";
import { IUserRepresentation } from "@netcero/phase-two-api-client";

interface IAuditLoggingConfigurationProps {
  auditLogs: IGetAuditLogs200Response;
  users: IUserRepresentation[];
  pagination: IUsePaginationStateResult;
}

export const AuditLoggingConfiguration: FC<IAuditLoggingConfigurationProps> = ({
  auditLogs,
  users,
  pagination,
}) => {
  const { t } = useTranslation("audit_logging_configuration");

  return (
    <>
      {/* Content */}
      <Box maxWidth="100%" mx="auto">
        <Box display="flex" gap={1} mb={2} alignItems="center">
          <Typography variant="h1">{t("title")}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          {auditLogs.total === 0 ? (
            <Typography>{t("empty_notice")}</Typography>
          ) : (
            // Logs are present --> render list of them
            <AuditLoggingEditList auditLogs={auditLogs} users={users} pagination={pagination} />
          )}
        </Box>
      </Box>
    </>
  );
};
