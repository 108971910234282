import { Box, Collapse, Link, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { SIDE_BAR_TRANSITION_DURATION_V1 } from "../components/side-bar-v1.component";

// Updated some types to work for V1 and V2 app shell version
export interface ISideBarNavigationItem {
  id: string;
  name?: string;
  path?: string;
  icon?: FC;
}

export interface ISideBarNavigationItemTopLevelV1 extends ISideBarNavigationItem {
  children?: ISideBarNavigationItem[];
}

export interface INavigationItemsSidebarSectionProps {
  activePath: string;
  items: ISideBarNavigationItemTopLevelV1[];
  expanded: boolean;
}

/**
 * This component renders a list of navigation items in the side bar.
 */
// TODO: NC-XYZ Clean up - delete file
export const NavigationItemsSideBarSectionV1: FC<INavigationItemsSidebarSectionProps> = ({
  activePath,
  items,
  expanded,
}) => {
  const bestMatchItem = useMemo(
    () =>
      items
        .flatMap((item) => [item, ...(expanded ? item.children ?? [] : [])])
        .filter((item) => activePath.startsWith(item.path ?? ""))
        .reduce(
          (prev, curr) =>
            prev === null || (curr.path && prev.path && curr.path.length > prev.path.length)
              ? curr
              : prev,
          null as ISideBarNavigationItem | null,
        ),
    [activePath, items, expanded],
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {items.map((topLevelItem) => (
        <Box key={topLevelItem.id}>
          <Link
            to={topLevelItem.path ?? ""}
            underline="none"
            color="inherit"
            component={RouterLink}
          >
            <Box
              key={topLevelItem.id}
              display="flex"
              alignItems="center"
              borderRadius={2}
              p={1}
              pl={1.5}
              sx={{
                backgroundColor: bestMatchItem?.id === topLevelItem.id ? "primary.main" : undefined,
                transition: "background-color 200ms",
                transitionTimingFunction: "ease-in-out",
                ":hover": {
                  transition: "background-color 200ms",
                  transitionTimingFunction: "ease-in-out",
                  backgroundColor: "primary.main",
                },
              }}
            >
              {topLevelItem.icon && (
                <SvgIcon
                  sx={{
                    height: "2rem",
                  }}
                >
                  <topLevelItem.icon />
                </SvgIcon>
              )}
              <Typography
                variant="body1"
                color="inherit"
                overflow="hidden"
                whiteSpace="nowrap"
                width={`${expanded ? 200 : 0}px`}
                sx={{
                  opacity: expanded ? 1 : 0,
                  transition: `width ${SIDE_BAR_TRANSITION_DURATION_V1}ms, opacity ${SIDE_BAR_TRANSITION_DURATION_V1}ms`,
                }}
                pl={1}
              >
                {topLevelItem.name}
              </Typography>
            </Box>
          </Link>
          {!!topLevelItem.children?.length && (
            <Collapse in={expanded}>
              <Box
                display="flex"
                flexDirection="column"
                pl={2}
                pt={0.5}
                width={240}
                sx={{
                  opacity: expanded ? 1 : 0,
                  transition: `opacity ${SIDE_BAR_TRANSITION_DURATION_V1}ms`,
                }}
                gap={0.5}
              >
                {topLevelItem.children?.map((childItem) => (
                  <Link
                    key={childItem.id}
                    to={childItem.path ?? ""}
                    underline="none"
                    color="inherit"
                    component={RouterLink}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      borderRadius={2}
                      p={1}
                      pl={2}
                      sx={{
                        backgroundColor:
                          bestMatchItem?.id === childItem.id ? "primary.main" : undefined,
                        transition: "background-color 200ms",
                        transitionTimingFunction: "ease-in-out",
                        ":hover": {
                          transition: "background-color 200ms",
                          transitionTimingFunction: "ease-in-out",
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      {childItem.icon && (
                        <SvgIcon
                          sx={{
                            height: "1.75rem",
                          }}
                        >
                          <childItem.icon />
                        </SvgIcon>
                      )}
                      <Typography variant="body2" color="inherit" whiteSpace="nowrap">
                        {childItem.name}
                      </Typography>
                    </Box>
                  </Link>
                ))}
              </Box>
            </Collapse>
          )}
        </Box>
      ))}
    </Box>
  );
};
