import { FC, useCallback, useState } from "react";
import { ItalicTextIcon } from "../../../common/constants/tabler-icon.constants";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isRangeSelection, BaseSelection, FORMAT_TEXT_COMMAND } from "lexical";
import { useListenToSelection } from "../../hooks/listen-to-selection.hook";
import { TextEditorToggleButton } from "../../components/styled.components";

interface IItalicButtonProps {
  disabled?: boolean;
}

export const ItalicButton: FC<IItalicButtonProps> = ({ disabled }) => {
  const [editor] = useLexicalComposerContext();

  const [isItalic, setIsItalic] = useState(false);

  const handleSelectionChange = useCallback((selection: BaseSelection | null) => {
    if ($isRangeSelection(selection)) {
      setIsItalic(selection.hasFormat("italic"));
    }
  }, []);

  useListenToSelection(handleSelectionChange);

  return (
    <TextEditorToggleButton
      size="small"
      value="italic"
      selected={isItalic}
      onChange={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
      disabled={disabled}
    >
      <ItalicTextIcon />
    </TextEditorToggleButton>
  );
};
