/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IEmissionFactorType = {
  MarketBasedEnergy: "market_based_energy",
  ActivityBased: "activity_based",
  LocationBasedEnergy: "location_based_energy",
  SpendingBased: "spending_based",
  Other: "other",
} as const;

export type IEmissionFactorType = (typeof IEmissionFactorType)[keyof typeof IEmissionFactorType];
