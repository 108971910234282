import { Box, Skeleton } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar-v1.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { RecordingPeriodRecordingStructuresCardsList } from "../../modules/recording-model-template-groups/components/recording-period-recording-structures-cards-list.component";
import { RecordingPeriodActionsDashboardSection } from "../../modules/recording-periods/recording-period-actions-dashboard-section.component";
import { useRecordingPeriodsQuery } from "../../modules/recording-periods/recording-periods.queries";
import { TypographySelectComponent } from "../../modules/common/components/typography-select.component";
import { ITopBarConfiguration } from "../../modules/common/components/top-bar.component";
import { useCustomTopBarContent } from "../../modules/common/contexts/top-bar-context";
import { useIsFeatureFlagEnabled } from "../../modules/common/hooks/is-feature-flag-enabled.hook";
import { FeatureFlag } from "../../modules/common/constants/feature-flags.constants";
import { NavItemUrlUtilities } from "../../modules/common/utilities/nav-item-url.utilities";

export const RecordingPeriodDashboardPage: FC = () => {
  const { organizationId, recordingPeriodId, recordingStructureType } = useParams();

  const currentOrganization = useCurrentOrganization();

  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId!);

  const navigate = useNavigate();

  // TODO: NC-XYZ Clean up - only return V2
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(FeatureFlag.APP_SHELL_V2);

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization.id,
            onChange: (organizationId) => {
              navigate(`/organizations/${organizationId}`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: NavItemUrlUtilities.getFilteredRecordingPeriodDashboardPageUrl(
          organizationId!,
          recordingPeriodId!,
          recordingStructureType!,
        ),
        items: sideBarOrganizationNavItems,
      },
    }),
    [
      sideBarOrganizationNavItems,
      organizationId,
      currentOrganization,
      navigate,
      recordingPeriodId,
      recordingStructureType,
    ],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const topBarConfiguration = useMemo<ITopBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization.id,
            onChange: (organizationId) => {
              navigate(`/organizations/${organizationId}`);
            },
          }
        : undefined,
      recordingPeriodId: recordingPeriodId,
    }),
    [currentOrganization, recordingPeriodId, navigate],
  );
  useCustomTopBarContent(topBarConfiguration);

  // Change Recording Period

  const handleSelectRecordingPeriod = (recordingPeriodId: string) => {
    navigate(
      NavItemUrlUtilities.getRecordingPeriodDashboardPageUrl(
        organizationId ?? "unknown",
        recordingPeriodId,
      ),
    );
  };

  const dropdownOptions = useMemo(
    () =>
      (recordingPeriodsQuery.data?.recordingPeriods ?? []).map((rp) => ({
        value: rp.id,
        display: rp.name,
      })),
    [recordingPeriodsQuery.data?.recordingPeriods],
  );

  return (
    <Box width="100%" maxWidth="100%" mx="auto">
      {!isFeatureFlagEnabled && (
        <Box display="flex" alignItems="center" gap={2}>
          {recordingPeriodsQuery.isSuccess ? (
            <TypographySelectComponent
              onValueChange={(value) => handleSelectRecordingPeriod(value)}
              options={dropdownOptions}
              typographyVariant="h1"
              value={recordingPeriodId}
            />
          ) : (
            <Skeleton variant="text" height="56px" width="40rem" />
          )}
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap={2} pb={2}>
        {/* Display card for "Execute DMA" suggestion */}
        <RecordingPeriodActionsDashboardSection
          organizationId={organizationId!}
          recordingPeriodId={recordingPeriodId!}
        />
        {/* Display recording structures */}
        <RecordingPeriodRecordingStructuresCardsList
          organizationId={organizationId!}
          recordingPeriodId={recordingPeriodId!}
          recordingStructureType={recordingStructureType}
        />
      </Box>
    </Box>
  );
};
