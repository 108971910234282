import { FC } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { AuditLogIcon } from "../../common/constants/tabler-icon.constants";
import { NavItemUrlUtilities } from "../../common/utilities/nav-item-url.utilities";

interface IAuditLogsButtonProps {
  organizationId: string;
  disabled?: boolean;
  active?: boolean;
}

export const AuditLogsButton: FC<IAuditLogsButtonProps> = ({
  organizationId,
  disabled,
  active = true,
}) => {
  const { t } = useTranslation("audit_logs");

  return (
    <Button
      variant="gray"
      component={RouterLink}
      to={NavItemUrlUtilities.getAuditLoggingConfigurationPageUrl(organizationId)}
      target="_blank"
      disabled={disabled}
      startIcon={<AuditLogIcon />}
      sx={{ backgroundColor: !active ? "unset" : undefined }}
    >
      {t("button.label")}
    </Button>
  );
};
