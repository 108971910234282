export class DeferredPromise<T> {
  private readonly _promise: Promise<T>;
  private _resolve!: (value: T | PromiseLike<T>) => void;
  private _reject!: (reason?: unknown) => void;

  constructor() {
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public resolve(value: T | PromiseLike<T>): void {
    this._resolve(value);
  }

  public reject(reason?: unknown): void {
    this._reject(reason);
  }

  public get promise(): Promise<T> {
    return this._promise;
  }
}
