import { OrgRole } from "./authentication.public-types";

export const ORG_BASE_PERMISSIONS = ["view-roles"] as const;

export const ORG_ROLES_ASCENDING = [
  OrgRole.VIEW_MEMBERS,
  OrgRole.OBJECT_ADMIN,
  OrgRole.ORGANIZATION_ADMIN,
];
export const ORG_ROLES_DESCENDING = [...ORG_ROLES_ASCENDING].reverse();

export const ROLES_ALLOWED_TO_MODIFY_DEO_ACCESS = [
  OrgRole.ORGANIZATION_ADMIN,
  OrgRole.OBJECT_ADMIN,
];
