import {
  IInputParameterValueMetaDataReferencedTarget,
  ITarget,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DataEntryObjectInputParameterValueDefinitionForReferencedTarget,
  IRecordingPeriodIdentity,
  LookupUtilities,
} from "@netcero/netcero-common";
import {
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { ICommonInputProps } from "../value-acquisition.types";
import MultipleSelectChip from "../../common/components/multiple-chip.component";
import { useTargetsQuery } from "../../targets/targets.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { useDeduceSelectionModeFromCount } from "../../selection-mode/selection-mode.hook";
import {
  IMDRSelectDisplayItem,
  MDRSelectDialog,
} from "../../minimum-disclosure-requirements-common/components/mdr-select.dialog";

type IReferencedTargetInputComponentProps = ICommonInputProps<
  IInputParameterValueMetaDataReferencedTarget,
  DataEntryObjectInputParameterValueDefinitionForReferencedTarget
> & {
  recordingPeriod: IRecordingPeriodIdentity;
};

const toDisplayItem = (target: ITarget): IMDRSelectDisplayItem => ({
  title: target.general.name,
  associatedTopics: target.associatedTopics,
  id: target.id,
});

export const ReferencedTargetInputComponent: FC<IReferencedTargetInputComponentProps> = ({
  recordingPeriod,
  ...props
}) => {
  const targetsQuery = useTargetsQuery(recordingPeriod);

  return (
    <QueryWrapper
      query={targetsQuery}
      build={(targets) => <ReferencedTargetInputComponentInternal {...props} targets={targets} />}
    />
  );
};

type IReferencedTargetInputComponentInternalProps = ICommonInputProps<
  IInputParameterValueMetaDataReferencedTarget,
  DataEntryObjectInputParameterValueDefinitionForReferencedTarget
> & {
  targets: ITarget[];
};

const ReferencedTargetInputComponentInternal: FC<IReferencedTargetInputComponentInternalProps> = ({
  variant,
  label,
  value,
  onChange,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  required,
  targets,
}) => {
  const { t } = useTranslation("referenced_target_input");

  const stylesSx = useVariantSxStyles(variant);
  const inputVariantProps = useVariantFormProps(variant);
  const selectionMode = useDeduceSelectionModeFromCount(metaData.maxNumberOfTargets);

  const alwaysPresentValue = useMemo(() => value ?? [], [value]);

  // Target availability is determined as follows:
  // * No filter is set --> All targets are available
  // * A filter is set --> Target either confirms to the filter or previously conformed to it (target is contained in value)
  const availableTargets = useMemo(
    () =>
      metaData.filterESRSTopic === undefined
        ? targets
        : targets.filter((t) => {
            return (
              t.associatedTopics.includes(metaData.filterESRSTopic!) ||
              alwaysPresentValue.includes(t.id)
            );
          }),
    [targets, metaData.filterESRSTopic, alwaysPresentValue],
  );

  const chipNames = useMemo(
    // target has to exist since foreign key would otherwise be violated
    () => {
      const targetsLookup = LookupUtilities.generateEntityLookUp(availableTargets);
      return alwaysPresentValue.map((targetId) => targetsLookup[targetId]!.general.name);
    },
    [alwaysPresentValue, availableTargets],
  );

  const handleValueChange = useCallback(
    (newValue: DataEntryObjectInputParameterValueDefinitionForReferencedTarget) => {
      // be sure to convert [] to undefined (as that is what the validation expects)
      onChange(newValue.length > 0 ? newValue : undefined);
    },
    [onChange],
  );

  const { openDialog, closeDialog, isOpen } = useDialogStateWithoutData();

  return (
    <>
      {/* Shows the selected targets */}
      <MultipleSelectChip
        {...inputVariantProps}
        onSelectClick={openDialog}
        chipNames={chipNames}
        required={required}
        label={label}
        error={error}
        disabled={disabled}
        fullWidth
        formControlSx={{
          maxWidth: !disableMaxWidth ? 260 : undefined,
          minWidth: 250,
        }}
        inputSx={stylesSx}
        placeholder={t("placeholder", {
          count: metaData.maxNumberOfTargets,
        })}
        noMargin
      />

      {/* Allows the user to pick a target */}
      <MDRSelectDialog
        open={isOpen}
        title={t("dialog.title", { count: metaData.maxNumberOfTargets })}
        onClose={() => closeDialog()}
        items={availableTargets}
        selectedItemIds={alwaysPresentValue}
        onChange={handleValueChange}
        toDisplayItem={toDisplayItem}
        selectionMode={selectionMode}
      />
    </>
  );
};
