import { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { AuditLogIcon } from "../../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { NavItemUrlUtilities } from "../../common/utilities/nav-item-url.utilities";

interface IAuditLogsIconButtonProps {
  organizationId: string;
  disabled?: boolean;
}

export const AuditLogsIconButton: FC<IAuditLogsIconButtonProps> = ({
  organizationId,
  disabled,
}) => {
  const { t } = useTranslation("audit_logs");

  return (
    <Tooltip title={t("button.label")}>
      <IconButton
        size="small"
        component={RouterLink}
        to={NavItemUrlUtilities.getAuditLoggingConfigurationPageUrl(organizationId)}
        target="_blank"
        disabled={disabled}
      >
        <AuditLogIcon />
      </IconButton>
    </Tooltip>
  );
};
