import * as React from "react";
import { FC, useCallback, useMemo, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalBlockType } from "../../lexical.types";
import { Box, MenuItem, Select } from "@mui/material";
import { LEXICAL_BLOCK_TYPES_ICONS, ORDERED_LEXICAL_BLOCK_TYPES } from "../../lexical.constants";
import { LexicalFormattingUtilities } from "../../lexical-formatting.utilities";
import { $isRangeSelection, $isRootOrShadowRoot, BaseSelection } from "lexical";
import { $isListNode, ListNode } from "@lexical/list";
import { $isHeadingNode } from "@lexical/rich-text";
import { $findMatchingParent, $getNearestNodeOfType } from "@lexical/utils";
import { useListenToSelection } from "../../hooks/listen-to-selection.hook";
import { useTranslation } from "react-i18next";

interface IHeadingButtonProps {
  supportedBlockTypes: LexicalBlockType[];
  disabled?: boolean;
}

export const BlockTypeDropDown: FC<IHeadingButtonProps> = ({ supportedBlockTypes, disabled }) => {
  const { t } = useTranslation("lexical");
  const [editor] = useLexicalComposerContext();

  const [selectedBlockType, setSelectedBlockType] = useState<LexicalBlockType>("paragraph");

  // Update the selected block type when the selection changes
  const handleEditorSelectionUpdate = useCallback(
    (selection: BaseSelection | null) => {
      // null check
      if (!selection) {
        return;
      }
      // Check if the selection is a range selection
      if ($isRangeSelection(selection)) {
        const anchorNode = selection.anchor.getNode();
        let element =
          anchorNode.getKey() === "root"
            ? anchorNode
            : $findMatchingParent(anchorNode, (e) => {
                const parent = e.getParent();
                return parent !== null && $isRootOrShadowRoot(parent);
              });

        if (element === null) {
          element = anchorNode.getTopLevelElementOrThrow();
        }

        const elementKey = element.getKey();
        const elementDOM = editor.getElementByKey(elementKey);

        if (elementDOM !== null) {
          if ($isListNode(element)) {
            const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
            const type = parentList ? parentList.getListType() : element.getListType();

            setSelectedBlockType(type);
          } else {
            const type = $isHeadingNode(element)
              ? element.getTag()
              : (element.getType() as LexicalBlockType);
            if (ORDERED_LEXICAL_BLOCK_TYPES.includes(type)) {
              setSelectedBlockType(type);
            }
          }
        }
      }
    },
    [editor],
  );
  useListenToSelection(handleEditorSelectionUpdate);

  const shownBlockTypes = useMemo(
    () => ORDERED_LEXICAL_BLOCK_TYPES.filter((t) => supportedBlockTypes.includes(t)),
    [supportedBlockTypes],
  );

  const handleBlockTypeClick: Record<LexicalBlockType, VoidFunction> = useMemo(
    () => ({
      paragraph: () => LexicalFormattingUtilities.formatParagraph(editor),
      h1: () => LexicalFormattingUtilities.formatHeading(editor, selectedBlockType, "h1"),
      h2: () => LexicalFormattingUtilities.formatHeading(editor, selectedBlockType, "h2"),
      h3: () => LexicalFormattingUtilities.formatHeading(editor, selectedBlockType, "h3"),
      h4: () => LexicalFormattingUtilities.formatHeading(editor, selectedBlockType, "h4"),
      h5: () => LexicalFormattingUtilities.formatHeading(editor, selectedBlockType, "h5"),
      h6: () => LexicalFormattingUtilities.formatHeading(editor, selectedBlockType, "h6"),
      bullet: () => LexicalFormattingUtilities.formatBulletList(editor, selectedBlockType),
      number: () => LexicalFormattingUtilities.formatNumberedList(editor, selectedBlockType),
      check: () => LexicalFormattingUtilities.formatCheckList(editor, selectedBlockType),
      quote: () => LexicalFormattingUtilities.formatQuote(editor, selectedBlockType),
      code: () => LexicalFormattingUtilities.formatCode(editor, selectedBlockType),
    }),
    [selectedBlockType, editor],
  );

  return (
    <Select
      variant="standard"
      size="small"
      disableUnderline
      value={selectedBlockType}
      disabled={disabled}
      sx={{ minWidth: 200 }}
      renderValue={(value) => {
        const Icon = LEXICAL_BLOCK_TYPES_ICONS[value];
        return (
          <Box display="flex" alignItems="center" gap={1} px={1} mt={0.5}>
            <Icon />
            {t(`block_types.${value}`)}
          </Box>
        );
      }}
    >
      {shownBlockTypes.map((blockType) => {
        const Icon = LEXICAL_BLOCK_TYPES_ICONS[blockType];
        return (
          <MenuItem
            key={blockType}
            value={blockType}
            onClick={handleBlockTypeClick[blockType]}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <Icon />
            {t(`block_types.${blockType}`)}
          </MenuItem>
        );
      })}
    </Select>
  );
};
