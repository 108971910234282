import { FC } from "react";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { useInitEsrsRecordingMutation } from "../../double-materiality-assessment/mutations/dma.mutations";
import { useTranslation } from "react-i18next";

interface IInitEsrsConfirmDialogProps {
  open: boolean;
  initEsrsRecordingMutation: ReturnType<typeof useInitEsrsRecordingMutation>;
  onClose: (confirm: boolean) => void;
}

export const InitEsrsConfirmDialog: FC<IInitEsrsConfirmDialogProps> = ({
  open,
  initEsrsRecordingMutation,
  onClose,
}) => {
  const { t } = useTranslation("init_esrs_confirm_dialog");
  return (
    <ConfirmDialogTextBody
      content={t("content")}
      title={t("title")}
      open={open}
      loading={initEsrsRecordingMutation.isPending}
      error={initEsrsRecordingMutation.error}
      disabled={initEsrsRecordingMutation.isPending}
      onClose={onClose}
    />
  );
};
