import {
  useApproveDataEntryObjectInputParameterMutation,
  useRejectDataEntryObjectInputParameterMutation,
  useResetDataEntryObjectInputParameterMutation,
  useSubmitDataEntryObjectInputParameterMutation,
  useUpdateInputParameterConfigurationMutation,
} from "../../data-entry-objects-input-parameters/data-entry-object-input-parameters.mutations";
import { useCallback, useMemo } from "react";
import {
  ILocalDataEntryObjectInputParameter,
  ILocalDataEntryObjectInputParameterValue,
  ILocalDataEntryObjectInputParameterValueData,
} from "../interfaces/local-data-entry-object-values.interfaces";
import {
  IDataEntryObject,
  IDataEntryObjectInputParameterContext,
  IInputParameter,
  IInputParameterRecordingStructure,
  IOrganization,
} from "@netcero/netcero-core-api-client";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import {
  useCreateDataEntryObjectInputParameterValueMutation,
  useDeleteDataEntryObjectInputParameterValueMutation,
  useUpdateDataEntryObjectInputParameterValueMutation,
} from "../mutations/data-entry-object-input-parameter.mutations";
import {
  APPROVE_DR_ACTION,
  APPROVE_IP_ACTION,
  REJECT_DR_ACTION,
  REJECT_IP_ACTION,
  RESET_DR_ACTION,
  RESET_IP_ACTION,
  SUBMIT_DR_ACTION,
  SUBMIT_IP_ACTION,
  UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USER_IDS_ACTION,
  UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER_ID_ACTION,
  UPDATE_DR_VALUE_ACTION,
  UPDATE_INPUT_PARAMETER_CONTRIBUTING_USER_IDS_ACTION,
  UPDATE_INPUT_PARAMETER_RESPONSIBLE_USER_ID_ACTION,
  UPDATE_IP_VALUE_ACTION,
} from "./esrs-api-actions.constants";

type HandlerDiscriminator = "disclosureRequirement" | "inputParameter";

export const useDeoEsrsApiActions = (
  organization: IOrganization,
  recordingPeriod: ILocalRecordingPeriod,
  recordingStructure: IInputParameterRecordingStructure,
  dataEntryObject: IDataEntryObject,
) => {
  const { wrapApiPromise } = useAppSnackbar();

  const baseMutationsData = useMemo(
    () => ({
      organizationId: organization.id,
      recordingPeriodId: recordingPeriod.id,
      dataEntryObjectId: dataEntryObject.id,
      recordingStructureId: recordingStructure.id,
    }),
    [dataEntryObject.id, organization.id, recordingPeriod.id, recordingStructure.id],
  );

  // Value Recording

  const updateInputParameterConfigurationMutation = useUpdateInputParameterConfigurationMutation();
  const handleUpdateInputParameterContributingUserIds = useCallback(
    (type: HandlerDiscriminator) => {
      const action =
        type === "disclosureRequirement"
          ? UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USER_IDS_ACTION
          : UPDATE_INPUT_PARAMETER_CONTRIBUTING_USER_IDS_ACTION;
      return async (inputParameter: ILocalDataEntryObjectInputParameter, values: string[]) => {
        return wrapApiPromise(
          updateInputParameterConfigurationMutation.mutateAsync({
            ...baseMutationsData,
            updates: {
              ...inputParameter,
              contributingUserIds: values,
            },
          }),
          action,
        );
      };
    },
    [wrapApiPromise, updateInputParameterConfigurationMutation, baseMutationsData],
  );

  const handleUpdateInputParameterResponsibleUserId = useCallback(
    (type: HandlerDiscriminator) => {
      const action =
        type === "disclosureRequirement"
          ? UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER_ID_ACTION
          : UPDATE_INPUT_PARAMETER_RESPONSIBLE_USER_ID_ACTION;
      return async (inputParameter: ILocalDataEntryObjectInputParameter, value: string | null) => {
        return wrapApiPromise(
          updateInputParameterConfigurationMutation.mutateAsync({
            ...baseMutationsData,
            updates: {
              ...inputParameter,
              responsibleUserId: value ?? undefined,
            },
          }),
          action,
        );
      };
    },
    [wrapApiPromise, updateInputParameterConfigurationMutation, baseMutationsData],
  );

  // IP Values Mutations

  const createDeoIPValueMutation = useCreateDataEntryObjectInputParameterValueMutation();
  const updateDeoIPValueMutation = useUpdateDataEntryObjectInputParameterValueMutation();
  const deleteDeoIPValueMutation = useDeleteDataEntryObjectInputParameterValueMutation();

  // Dr Values

  const handleCreateInputParameterValue = useCallback(
    (type: HandlerDiscriminator) => {
      const action =
        type === "disclosureRequirement" ? UPDATE_DR_VALUE_ACTION : UPDATE_IP_VALUE_ACTION;
      return async (
        inputParameter: IInputParameter,
        data: ILocalDataEntryObjectInputParameterValueData,
      ) => {
        await wrapApiPromise(
          createDeoIPValueMutation.mutateAsync({
            ...baseMutationsData,
            inputParameter,
            valueData: data,
          }),
          action,
        );
      };
    },
    [baseMutationsData, createDeoIPValueMutation, wrapApiPromise],
  );

  const handleUpdateIPValue = useCallback(
    (type: HandlerDiscriminator) => {
      const action =
        type === "disclosureRequirement" ? UPDATE_DR_VALUE_ACTION : UPDATE_IP_VALUE_ACTION;
      return async (
        inputParameter: IInputParameter,
        valueId: string,
        data: ILocalDataEntryObjectInputParameterValueData,
      ) => {
        await wrapApiPromise(
          updateDeoIPValueMutation.mutateAsync({
            ...baseMutationsData,
            inputParameter,
            valueId: valueId,
            valueData: data,
          }),
          action,
        );
      };
    },
    [wrapApiPromise, updateDeoIPValueMutation, baseMutationsData],
  );

  const handleDeleteIPValue = useCallback(
    (type: HandlerDiscriminator) => {
      const action =
        type === "disclosureRequirement" ? UPDATE_DR_VALUE_ACTION : UPDATE_IP_VALUE_ACTION;
      return async (inputParameter: IInputParameter, valueId: string) => {
        await wrapApiPromise(
          deleteDeoIPValueMutation.mutateAsync({
            ...baseMutationsData,
            inputParameter,
            valueId,
          }),
          action,
        );
      };
    },
    [wrapApiPromise, deleteDeoIPValueMutation, baseMutationsData],
  );

  const handleUpdateTableValues = useCallback(
    async (
      inputParameter: IInputParameter,
      createdRows: ILocalDataEntryObjectInputParameterValueData[],
      updatedRows: ILocalDataEntryObjectInputParameterValue[],
      deletedRows: ILocalDataEntryObjectInputParameterValue[],
    ) => {
      return wrapApiPromise(async () => {
        await Promise.all(
          deletedRows.map((rowData) =>
            deleteDeoIPValueMutation.mutateAsync({
              ...baseMutationsData,
              inputParameter,
              valueId: rowData.id,
            }),
          ),
        );
        await Promise.all(
          createdRows.map((rowData) =>
            createDeoIPValueMutation.mutateAsync({
              ...baseMutationsData,
              inputParameter,
              valueData: rowData,
            }),
          ),
        );
        await Promise.all(
          updatedRows.map((rowData) =>
            updateDeoIPValueMutation.mutateAsync({
              ...baseMutationsData,
              inputParameter,
              valueId: rowData.id,
              valueData: {
                ...rowData,
                id: undefined,
                dataEntryObjectId: undefined,
              } as ILocalDataEntryObjectInputParameterValueData,
            }),
          ),
        );
      }, UPDATE_IP_VALUE_ACTION);
    },
    [
      wrapApiPromise,
      createDeoIPValueMutation,
      baseMutationsData,
      updateDeoIPValueMutation,
      deleteDeoIPValueMutation,
    ],
  );

  // Submit, Approve, Reject

  const resetInputParameterMutation = useResetDataEntryObjectInputParameterMutation();
  const handleResetInputParameter = useCallback(
    (type: HandlerDiscriminator) => {
      const action = type === "disclosureRequirement" ? RESET_DR_ACTION : RESET_IP_ACTION;
      return async (inputParameter: IInputParameter) => {
        await wrapApiPromise(
          resetInputParameterMutation.mutateAsync({
            organizationId: organization.id,
            recordingPeriodId: recordingPeriod.id,
            dataEntryObjectId: dataEntryObject.id,
            recordingStructureId: recordingStructure.id,
            inputParameterId: inputParameter.id,
          }),
          action,
        );
      };
    },
    [
      dataEntryObject.id,
      organization.id,
      recordingPeriod.id,
      recordingStructure.id,
      resetInputParameterMutation,
      wrapApiPromise,
    ],
  );

  const submitInputParameterMutation = useSubmitDataEntryObjectInputParameterMutation();
  const handleSubmitInputParameter = useCallback(
    (type: HandlerDiscriminator) => {
      const action = type === "disclosureRequirement" ? SUBMIT_DR_ACTION : SUBMIT_IP_ACTION;
      return async (
        inputParameter: IInputParameter,
        context: IDataEntryObjectInputParameterContext,
      ) => {
        await wrapApiPromise(
          submitInputParameterMutation.mutateAsync({
            organizationId: organization.id,
            recordingPeriodId: recordingPeriod.id,
            dataEntryObjectId: dataEntryObject.id,
            recordingStructureId: recordingStructure.id,
            inputParameterId: inputParameter.id,
            context,
          }),
          action,
        );
      };
    },
    [
      dataEntryObject.id,
      organization.id,
      recordingPeriod.id,
      recordingStructure.id,
      submitInputParameterMutation,
      wrapApiPromise,
    ],
  );

  const approveInputParameterMutation = useApproveDataEntryObjectInputParameterMutation();
  const handleApproveInputParameter = useCallback(
    (type: HandlerDiscriminator) => {
      const action = type === "disclosureRequirement" ? APPROVE_DR_ACTION : APPROVE_IP_ACTION;
      return async (inputParameter: IInputParameter) => {
        await wrapApiPromise(
          approveInputParameterMutation.mutateAsync({
            organizationId: organization.id,
            recordingPeriodId: recordingPeriod.id,
            dataEntryObjectId: dataEntryObject.id,
            recordingStructureId: recordingStructure.id,
            inputParameterId: inputParameter.id,
          }),
          action,
        );
      };
    },
    [
      wrapApiPromise,
      approveInputParameterMutation,
      organization.id,
      recordingPeriod.id,
      dataEntryObject.id,
      recordingStructure.id,
    ],
  );

  const rejectInputParameterMutation = useRejectDataEntryObjectInputParameterMutation();
  const handleRejectInputParameter = useCallback(
    (type: HandlerDiscriminator) => {
      const action = type === "disclosureRequirement" ? REJECT_DR_ACTION : REJECT_IP_ACTION;
      return async (
        inputParameter: IInputParameter,
        context: IDataEntryObjectInputParameterContext,
      ) => {
        await wrapApiPromise(
          rejectInputParameterMutation.mutateAsync({
            organizationId: organization.id,
            recordingPeriodId: recordingPeriod.id,
            dataEntryObjectId: dataEntryObject.id,
            recordingStructureId: recordingStructure.id,
            inputParameterId: inputParameter.id,
            context,
          }),
          action,
        );
      };
    },
    [
      dataEntryObject.id,
      organization.id,
      recordingPeriod.id,
      recordingStructure.id,
      rejectInputParameterMutation,
      wrapApiPromise,
    ],
  );

  // Total State

  const isLoading = useMemo(
    () =>
      updateInputParameterConfigurationMutation.isPending ||
      createDeoIPValueMutation.isPending ||
      updateDeoIPValueMutation.isPending ||
      deleteDeoIPValueMutation.isPending ||
      resetInputParameterMutation.isPending ||
      submitInputParameterMutation.isPending ||
      approveInputParameterMutation.isPending ||
      rejectInputParameterMutation.isPending,
    [
      updateInputParameterConfigurationMutation.isPending,
      createDeoIPValueMutation.isPending,
      updateDeoIPValueMutation.isPending,
      deleteDeoIPValueMutation.isPending,
      resetInputParameterMutation.isPending,
      submitInputParameterMutation.isPending,
      approveInputParameterMutation.isPending,
      rejectInputParameterMutation.isPending,
    ],
  );
  const isError = useMemo(
    () =>
      updateInputParameterConfigurationMutation.isError ||
      createDeoIPValueMutation.isError ||
      updateDeoIPValueMutation.isError ||
      deleteDeoIPValueMutation.isError ||
      resetInputParameterMutation.isError ||
      submitInputParameterMutation.isError ||
      approveInputParameterMutation.isError ||
      rejectInputParameterMutation.isError,
    [
      updateInputParameterConfigurationMutation.isError,
      createDeoIPValueMutation.isError,
      updateDeoIPValueMutation.isError,
      deleteDeoIPValueMutation.isError,
      resetInputParameterMutation.isError,
      submitInputParameterMutation.isError,
      approveInputParameterMutation.isError,
      rejectInputParameterMutation.isError,
    ],
  );

  // Memoized Handler
  return useMemo(
    () => ({
      // Mutations
      updateInputParameterConfigurationMutation,
      createDeoIPValueMutation,
      updateDeoIPValueMutation,
      deleteDeoIPValueMutation,
      // Actions
      handleUpdateDisclosureRequirementContributingUserIds:
        handleUpdateInputParameterContributingUserIds("disclosureRequirement"),
      handleUpdateInputParameterContributingUserIds:
        handleUpdateInputParameterContributingUserIds("inputParameter"),
      handleUpdateDisclosureRequirementResponsibleUserId:
        handleUpdateInputParameterResponsibleUserId("disclosureRequirement"),
      handleUpdateInputParameterResponsibleUserId:
        handleUpdateInputParameterResponsibleUserId("inputParameter"),
      handleCreateDisclosureRequirementValue:
        handleCreateInputParameterValue("disclosureRequirement"),
      handleUpdateDisclosureRequirementValue: handleUpdateIPValue("disclosureRequirement"),
      handleDeleteDisclosureRequirementValue: handleDeleteIPValue("disclosureRequirement"),
      handleCreateInputParameterValue: handleCreateInputParameterValue("inputParameter"),
      handleUpdateInputParameterValue: handleUpdateIPValue("inputParameter"),
      handleDeleteInputParameterValue: handleDeleteIPValue("inputParameter"),
      handleUpdateTableValue: handleUpdateTableValues,
      // Submit, Approve, Reject
      handleResetDisclosureRequirement: handleResetInputParameter("disclosureRequirement"),
      handleSubmitDisclosureRequirement: handleSubmitInputParameter("disclosureRequirement"),
      handleApproveDisclosureRequirement: handleApproveInputParameter("disclosureRequirement"),
      handleRejectDisclosureRequirement: handleRejectInputParameter("disclosureRequirement"),
      handleResetInputParameter: handleResetInputParameter("inputParameter"),
      handleSubmitInputParameter: handleSubmitInputParameter("inputParameter"),
      handleApproveInputParameter: handleApproveInputParameter("inputParameter"),
      handleRejectInputParameter: handleRejectInputParameter("inputParameter"),
      // State
      isLoading,
      isError,
    }),
    [
      updateInputParameterConfigurationMutation,
      createDeoIPValueMutation,
      updateDeoIPValueMutation,
      deleteDeoIPValueMutation,
      handleUpdateInputParameterContributingUserIds,
      handleUpdateInputParameterResponsibleUserId,
      handleCreateInputParameterValue,
      handleUpdateIPValue,
      handleDeleteIPValue,
      handleUpdateTableValues,
      handleResetInputParameter,
      handleSubmitInputParameter,
      handleApproveInputParameter,
      handleRejectInputParameter,
      isLoading,
      isError,
    ],
  );
};
