import { FC, PropsWithChildren, useMemo } from "react";
import {
  InitialConfigType,
  InitialEditorStateType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { LEXICAL_THEME } from "./lexical.constants";
import { COMMON_LEXICAL_NODES } from "@netcero/netcero-common";
import * as Sentry from "@sentry/react";
import { ILexicalEditorCommonProps } from "./lexical.types";
import "./variants/common-lexical.styles.css";
import "./variants/comments-lexical.styles.css";
import "./variants/esrs-lexical.styles.css";
import "./variants/table-lexical.styles.css";

const handleError = (error: Error) => {
  console.error("Lexical error", error);
  Sentry.captureException(error, {
    data: {
      netceroAppFeature: "LexicalEditor",
    },
  });
};

interface ILexicalBaseEditorProps extends ILexicalEditorCommonProps {
  initialEditorState?: InitialEditorStateType;
}

/**
 * Base editor for the Lexical editor. Initializes default settings (theme, error handling).
 * Provide Plugins as needed and as you would with the default LexicalComposer.
 * @param variant - editor variant
 * @param initiallyEditable - whether the editor is initially editable
 * @param initialEditorState - initial editor state
 * @param error - error state (adds error class to the editor)
 * @param children
 * @constructor
 */
export const LexicalBaseEditor: FC<PropsWithChildren<ILexicalBaseEditorProps>> = ({
  variant,
  initiallyEditable = false,
  initialEditorState,
  error,
  children,
}) => {
  const initialConfig: InitialConfigType = useMemo(
    () => ({
      namespace: "NetCeroEditor",
      theme: LEXICAL_THEME,
      onError: handleError,
      editable: initiallyEditable,
      editorState: initialEditorState,
      nodes: [...COMMON_LEXICAL_NODES],
    }),
    [initialEditorState, initiallyEditable],
  );

  return (
    <span className={`${variant} ${error ? "error" : ""}`}>
      <LexicalComposer initialConfig={initialConfig}>{children}</LexicalComposer>
    </span>
  );
};
